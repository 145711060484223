/* eslint-disable import/no-cycle */
import store from '@/store';

import AppClient from '../AppClient';

import { searchStoreFiles } from '../Util/searchStoreFiles';

export default class VideoService {
  private basePath = process.env.VUE_APP_BOOK_API;

  private Client = new AppClient({ url: this.basePath });

  async getVideo(idVideo: number) {
    const URI = '/videos';

    const response = await this.Client.get(URI, {
      idVideo: [idVideo],
    });

    store.commit('setFile', response?.data[0]);

    return response?.data[0];
  }

  async getVideos(listVideoIds: Array<number>) {
    const filterVideos = searchStoreFiles(listVideoIds, 'filesVideos', 'idVideo');

    if (filterVideos.idsFiles && filterVideos.idsFiles.length) {
      const URI = '/videos';

      const response = await this.Client.get(URI, {
        idVideo: filterVideos.idsFiles,
      });

      if (response?.data) {
        const files = [...filterVideos.files, ...response?.data];

        store.commit('setFilesVideos', files);

        return files;
      }

      return response?.data;
    }

    return filterVideos.files;
  }
}
