export const LIST_MONTHS = [
  {
    title: 'Janeiro',
    value: '01',
  },
  {
    title: 'Fevereiro',
    value: '02',
  },
  {
    title: 'Março',
    value: '03',
  },
  {
    title: 'Abril',
    value: '04',
  },
  {
    title: 'Maio',
    value: '05',
  },
  {
    title: 'Junho',
    value: '06',
  },
  {
    title: 'Julho',
    value: '07',
  },
  {
    title: 'Agosto',
    value: '08',
  },
  {
    title: 'Setembro',
    value: '09',
  },
  {
    title: 'Outubro',
    value: '10',
  },
  {
    title: 'Novembro',
    value: '11',
  },
  {
    title: 'Dezembro',
    value: '12',
  },
];
