export function showScriptSurvio() {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = 'https://cdn.survio.com/static/widgets/web-widget.js';
  script.defer = true;
  script.dataset.backgroundColor = '#ffc700';
  script.dataset.color = '#000';
  script.dataset.text = 'Queremos a sua opinião';
  script.dataset.url = 'https://www.survio.com/survey/o/I3O4K7Q1O3S1B9V5X%22%3E';

  document.body.appendChild(script);

  const customStyle = `
    <style>
      .handlerStyle {
        background-color: #ffc700 !important;
      }
    </style>
  `;

  document.head.insertAdjacentHTML('beforeend', customStyle);
}

export function removeScriptSurvio() {
  document.querySelectorAll('.survio-widget').forEach((element) => element.remove());

  const scriptToRemove: any = document.querySelector('script[src="https://cdn.survio.com/static/widgets/web-widget.js"]');

  if (scriptToRemove) scriptToRemove.parentNode.removeChild(scriptToRemove);

}
