import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export class Mouse extends Vue {
  @Prop() protected mySuperProp!: string;

  private static instance: Mouse;
  public event: MouseEvent;

  constructor() {
    super();
    this.event = new MouseEvent('click');
  }

  created() {
    window.addEventListener('click', this.mouseClick);
  }

  mouseClick(event: MouseEvent) {
    this.event = event;
  }

  public static getInstance(): Mouse {
    if (!Mouse.instance) {
      Mouse.instance = new Mouse();
    }
    return Mouse.instance;
  }
}

export default Mouse;
