












import {
  Component, Mixins,
} from 'vue-property-decorator';

import FloatingHelpButton from '@/components/FloatingHelpButton/FloatingHelpButton.vue';
import FloatingWhatsAppButton from '@/components/FloatingWhatsAppButton/FloatingWhatsAppButton.vue';

import SetTutorial from '@/mixins/SetTutorial';

const WHATSAPP_ACTIVE_BUTTON = '1';

@Component({
  components: {
    FloatingHelpButton,
    FloatingWhatsAppButton,
  },
})
export default class FloatingButtons extends Mixins(SetTutorial) {
  get showFloatingWhatsApp() {
    return process.env.VUE_APP_SHOW_WHATSAPP === WHATSAPP_ACTIVE_BUTTON;
  }
}
