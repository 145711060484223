export const LIGHT_MODE = {
  'primarycolor-pure': '#A505F0',
  'primarycolor-dark': '#6E04C0',
  'primarycolor-medium': '#9205E0',
  'primarycolor-light': '#C04FF5',
  'primarycolor-lightest': '#ECCBFC',

  'neutralcolor-low-pure': '#212529',
  'neutralcolor-low-dark': '#1A1E21',
  'neutralcolor-low-medium': '#45494C',
  'neutralcolor-low-light': '#9D9FA1',

  'neutralcolor-high-pure': '#FBFBFB',
  'neutralcolor-high-dark': '#B9B9BA',
  'neutralcolor-high-medium': '#E7E7E8',
  'neutralcolor-high-light': '#EFF0F0',

  'feedbackcolor-success-pure': '#2F9E44',
  'feedbackcolor-success-dark': '#267E2C',
  'feedbackcolor-success-medium': '#59B259',
  'feedbackcolor-success-light': '#BDD8AC',

  'feedbackcolor-info-pure': '#3B5BDB',
  'feedbackcolor-info-dark': '#2F58AF',
  'feedbackcolor-info-medium': '#6294E2',
  'feedbackcolor-info-light': '#89C1EA',

  'feedbackcolor-warning-pure': '#EDA81F',
  'feedbackcolor-warning-dark': '#CD841B',
  'feedbackcolor-warning-medium': '#F0B43E',
  'feedbackcolor-warning-light': '#F7D99C',

  'feedbackcolor-error-pure': '#E03131',
  'feedbackcolor-error-dark': '#B32738',
  'feedbackcolor-error-medium': '#D12E34',
  'feedbackcolor-error-light': '#ED8383',
};

export const DARK_MODE = {
  'primarycolor-pure': '#C04FF5',
  'primarycolor-dark': '#ECCBFC',
  'primarycolor-medium': '#A505F0',
  'primarycolor-light': '#9205E0',
  'primarycolor-lightest': '#6E04C0',

  'neutralcolor-low-pure': '#FBFBFB',
  'neutralcolor-low-dark': '#B9B9BA',
  'neutralcolor-low-medium': '#E7E7E8',
  'neutralcolor-low-light': '#EFF0F0',

  'neutralcolor-high-pure': '#212529',
  'neutralcolor-high-dark': '#1A1E21',
  'neutralcolor-high-medium': '#45494C',
  'neutralcolor-high-light': '#9D9FA1',

  'feedbackcolor-success-pure': '#8ED147',
  'feedbackcolor-success-dark': '#71B32B',
  'feedbackcolor-success-medium': '#95CC5A',
  'feedbackcolor-success-light': '#BAFF70',

  'feedbackcolor-info-pure': '#24ABFF',
  'feedbackcolor-info-dark': '#418CFC',
  'feedbackcolor-info-medium': '#8FAECC',
  'feedbackcolor-info-light': '#B3DAFE',

  'feedbackcolor-warning-pure': '#FFB521',
  'feedbackcolor-warning-dark': '#D2951C',
  'feedbackcolor-warning-medium': '#CCBB85',
  'feedbackcolor-warning-light': '#FFEAA6',

  'feedbackcolor-error-pure': '#F94343',
  'feedbackcolor-error-dark': '#B32121',
  'feedbackcolor-error-medium': '#CC9191',
  'feedbackcolor-error-light': '#FEB4B4',
};

export const THEME: Record<string, any> = {
  theme_light: LIGHT_MODE,
  theme_dark: DARK_MODE,
};

export const COLORS = {
  'primarycolor-pure': 'var(--primarycolor-pure)',
  'primarycolor-dark': 'var(--primarycolor-dark)',
  'primarycolor-medium': 'var(--primarycolor-medium)',
  'primarycolor-light': 'var(--primarycolor-light)',
  'primarycolor-lightest': 'var(--primarycolor-lightest)',

  'neutralcolor-low-pure': 'var(--neutralcolor-low-pure)',
  'neutralcolor-low-dark': 'var(--neutralcolor-low-dark)',
  'neutralcolor-low-medium': 'var(--neutralcolor-low-medium)',
  'neutralcolor-low-light': 'var(--neutralcolor-low-light)',

  'neutralcolor-high-pure': 'var(--neutralcolor-high-pure)',
  'neutralcolor-high-dark': 'var(--neutralcolor-high-dark)',
  'neutralcolor-high-medium': 'var(--neutralcolor-high-medium)',
  'neutralcolor-high-light': 'var(--neutralcolor-high-light)',

  'feedbackcolor-success-pure': 'var(--feedbackcolor-success-pure)',
  'feedbackcolor-success-dark': 'var(--feedbackcolor-success-dark)',
  'feedbackcolor-success-medium': 'var(--feedbackcolor-success-medium)',
  'feedbackcolor-success-light': 'var(--feedbackcolor-success-light)',

  'feedbackcolor-info-pure': 'var(--feedbackcolor-info-pure)',
  'feedbackcolor-info-dark': 'var(--feedbackcolor-info-dark)',
  'feedbackcolor-info-medium': 'var(--feedbackcolor-info-medium)',
  'feedbackcolor-info-light': 'var(--feedbackcolor-info-light)',

  'feedbackcolor-warning-pure': 'var(--feedbackcolor-warning-pure)',
  'feedbackcolor-warning-dark': 'var(--feedbackcolor-warning-dark)',
  'feedbackcolor-warning-medium': 'var(--feedbackcolor-warning-medium)',
  'feedbackcolor-warning-light': 'var(--feedbackcolor-warning-light)',

  'feedbackcolor-error-pure': 'var(--feedbackcolor-error-pure)',
  'feedbackcolor-error-dark': 'var(--feedbackcolor-error-dark)',
  'feedbackcolor-error-medium': 'var(--feedbackcolor-error-medium)',
  'feedbackcolor-error-light': 'var(--feedbackcolor-error-light)',

  'primarycolor-pure-fix': 'var(--primarycolor-pure-fix)',
  'primarycolor-dark-fix': 'var(--primarycolor-dark-fix)',
  'primarycolor-medium-fix': 'var(--primarycolor-medium-fix)',
  'primarycolor-light-fix': 'var(--primarycolor-light-fix)',
  'primarycolor-lightest-fix': 'var(--primarycolor-lightest-fix)',

  'neutralcolor-low-pure-fix': 'var(--neutralcolor-low-pure-fix)',
  'neutralcolor-low-dark-fix': 'var(--neutralcolor-low-dark-fix)',
  'neutralcolor-low-medium-fix': 'var(--neutralcolor-low-medium-fix)',
  'neutralcolor-low-light-fix': 'var(--neutralcolor-low-light-fix)',

  'neutralcolor-high-pure-fix': 'var(--neutralcolor-high-pure-fix)',
  'neutralcolor-high-dark-fix': 'var(--neutralcolor-high-dark-fix)',
  'neutralcolor-high-medium-fix': 'var(--neutralcolor-high-medium-fix)',
  'neutralcolor-high-light-fix': 'var(--neutralcolor-high-light-fix)',

  'feedbackcolor-success-pure-fix': 'var(--feedbackcolor-success-pure-fix)',
  'feedbackcolor-success-dark-fix': 'var(--feedbackcolor-success-dark-fix)',
  'feedbackcolor-success-medium-fix': 'var(--feedbackcolor-success-medium-fix)',
  'feedbackcolor-success-light-fix': 'var(--feedbackcolor-success-light-fix)',

  'feedbackcolor-info-pure-fix': 'var(--feedbackcolor-info-pure-fix)',
  'feedbackcolor-info-dark-fix': 'var(--feedbackcolor-info-dark-fix)',
  'feedbackcolor-info-medium-fix': 'var(--feedbackcolor-info-medium-fix)',
  'feedbackcolor-info-light-fix': 'var(--feedbackcolor-info-light-fix)',

  'feedbackcolor-warning-pure-fix': 'var(--feedbackcolor-warning-pure-fix)',
  'feedbackcolor-warning-dark-fix': 'var(--feedbackcolor-warning-dark-fix)',
  'feedbackcolor-warning-medium-fix': 'var(--feedbackcolor-warning-medium-fix)',
  'feedbackcolor-warning-light-fix': 'var(--feedbackcolor-warning-light-fix)',

  'feedbackcolor-error-pure-fix': 'var(--feedbackcolor-error-pure-fix)',
  'feedbackcolor-error-dark-fix': 'var(--feedbackcolor-error-dark-fix)',
  'feedbackcolor-error-medium-fix': 'var(--feedbackcolor-error-medium-fix)',
  'feedbackcolor-error-light-fix': 'var(--feedbackcolor-error-light-fix)',

  'primarycolor-premium-pure': ' var(--primarycolor-premium-pure)',
};

export const DARK_MODE_THEME = 'theme_dark';
export const LIGHT_MODE_THEME = 'theme_light';
