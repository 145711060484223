





























import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class BoxContainer extends Vue {
  @Prop() title!: string;
  @Prop() fluid!: boolean;
  @Prop() padding!: boolean;
  @Prop() backgroundColor!: string;
  @Prop() htmlContent!: string;

  get isFluid() {
    return this.fluid !== undefined;
  }
}
