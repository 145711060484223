const FilterVideos = {
  state: {
    filterVideoList: [],
    filterTeachersVideo: [],
  },
  mutations: {
    setFilterVideoList(state: Record<string, any>, payload: Array<Record<string, any>>) {
      state.filterVideoList = payload;
    },
    setSelectedFilter(state: Record<string, any>) {
      let filterTeachers: any = state.filterTeachersVideo.map((teacher: Record<string, any>) => teacher.name);

      const teachersForEach = state.filterTeachersVideo?.forEach((teacher: any) => {
        if (!teacher.checked) {
          filterTeachers = filterTeachers.filter((name: string) => name !== teacher.name);
        }
      });

      state.filterVideoList = state.filterVideoList.map((item: any) => {
        const video = item;

        if (filterTeachers?.length === 0) {
          video.hidden = true;
          return video;
        }

        video.hidden = false;

        const listTeachers = video.teachers;
        listTeachers.forEach((teacher: any) => {
          if (!filterTeachers.includes(teacher.name)) {
            video.hidden = true;
          }
        });

        return video;
      });
    },
    setFilterTeachersVideo(state: Record<string, any>, payload: Array<Record<string, any>>) {
      state.filterTeachersVideo = payload;
      FilterVideos.mutations.setSelectedFilter(state);
    },
  },
  getters: {
    filterVideoList: (state: Record<string, any>) => state.filterVideoList,
    filterTeachersVideo: (state: Record<string, any>) => state.filterTeachersVideo,
  },
};

export default FilterVideos;
