


















































import { Component, Vue, Prop } from 'vue-property-decorator';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import ExLoading from '@/globalComponents/ExLoading/index.vue';

@Component({
  components: {
    BoxContainer,
    ExLoading,
  },
})
export default class ListAccordionLoading extends Vue {
  @Prop({ default: 4 }) quantityLoading!: number;
}
