function convertHex(hexa: string, opacity: number) {
  const color = hexa.replace('#', '');
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);
  return `rgba(${r},${g},${b},${opacity})`;//  'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
}

function convertHexToHSL(hexColor: string) {
  let hslColor = '';

  if (!hexColor.match(/^#[0-9a-fA-F]{6}$/)) {
    hslColor = '';
    return hslColor;
  }

  const r = parseInt(hexColor.slice(1, 3), 16) / 255;
  const g = parseInt(hexColor.slice(3, 5), 16) / 255;
  const b = parseInt(hexColor.slice(5, 7), 16) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let l = (max + min) / 2;

  let h;
  let s;

  if (max === min) {
    h = 0;
    s = 0;
  } else {
    const d = max - min;

    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      default:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6;
  }

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  hslColor = `hsl(${h}, ${s}%, ${l}%)`;

  return hslColor;
}

function modifyHslLuminosity(hslColor: string, luminosityValues: number[]) {
  const hslRegex = /hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/;
  const match = hslColor.match(hslRegex);

  if (match) {
    const hue = parseInt(match[1], 10);
    const saturation = parseInt(match[2], 10);
    const originalLuminosity = parseInt(match[3], 10);

    const modifiedColors = luminosityValues.map((luminosity, index) => {
      if (index === 2 || index === 3) {
        return `hsl(${hue}, ${saturation}%, ${originalLuminosity}%)`;
      }

      return `hsl(${hue}, ${saturation}%, ${luminosity}%)`;
    });

    return modifiedColors;
  }

  return [];
}

export {
  convertHex,
  convertHexToHSL,
  modifyHslLuminosity,
};
