

































import {
  Vue, Component,
} from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

@Component({
  components: {
    Modal,
  },
})
export default class ModalExitSimulation extends Vue {
  goToOtherPage() {
    this.$store.commit('setClickedCloseModalExitSimulated', true);
    this.$router.push({
      path: this.$store.getters.pendingRoute,
    });
  }
}
