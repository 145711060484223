





























import {
  Component, Vue, Prop,
} from 'vue-property-decorator';

import HelpButtonIcon from '@/assets/icons/help/HelpButtonIcon.vue';
import HelpIcon from '@/assets/icons/help/HelpIcon.vue';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

const LOCAL_NAME_PATH = 'path';

const SMALL_SIZE_ICON = '30px';
const BIG_SIZE_ICON = '40px';

@Component({
  components: {
    HelpButtonIcon,
    HelpIcon,
  },
})
export default class FloatingHelpButton extends Vue {
  @Prop() nameRouteTutorial!: string;
  @Prop({ default: null }) params!: Record<string, any>;

  private openContentHelp = false;

  mounted() {
    document.addEventListener('click', this.checkHelpButton);
  }

  beforeDestroy() {
    document.removeEventListener('click', this.checkHelpButton);
  }

  get sizeIconHelp() {
    return this.$responsive.isMobile ? SMALL_SIZE_ICON : BIG_SIZE_ICON;
  }

  checkHelpButton(e: any) {
    const containerHelpIsOpen = this.$refs['container-tutorial'];
    const clickedHelpButton = e.target.localName === LOCAL_NAME_PATH;

    if (this.openContentHelp && containerHelpIsOpen && !clickedHelpButton) this.openContentHelp = false;
  }

  clickButtonHelp() {
    this.openContentHelp = true;
  }

  goToTutorial() {
    if (this.nameRouteTutorial) {
      this.setTrackAmplitude(this.nameRouteTutorial);

      if (this.params) {
        this.$router.push({
          name: this.nameRouteTutorial,
          params: this.params,
        });
      } else {
        this.$router.push({
          name: this.nameRouteTutorial,
        });
      }
    } else {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar tutorial.',
        status: 'error',
      });
    }
  }

  setTrackAmplitude(nameLocal: string) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'FloatingHelpButton',
          local: nameLocal,
        },
      },
    });
  }
}
