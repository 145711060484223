











































import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

import Lock from '@/assets/icons/Lock.vue';

import Tooltip from '../Tooltip/index.vue';

export interface TabsMenuInterface {
  title: string;
  slug?: string;
  id?: string;
  active?: boolean;
  order?: number;
  degreeID?: number;
}

@Component({
  components: {
    Tooltip,
    Lock,
  },
})
export default class Tabs extends Vue {
  @Prop() menu!: Array<TabsMenuInterface>;
  @Prop({ required: false }) permissionsTab!: Record<string, any>;
  @Prop({ required: false }) listCloseTab!: Record<string, any>;
  @Prop({ required: false }) fontSize!: string;
  @Prop({ required: false }) margin!: string;
  @Prop({ required: false }) padding!: string;
  @Prop({ default: true }) showHover!: boolean;

  get hasPadding() {
    if (this.permissionsTab) {
      const listValuesPermissions = Object.values(this.permissionsTab);

      return !listValuesPermissions.some((valuePermission: boolean) => !valuePermission);
    }

    return false;
  }

  emitTabActive(indexTabActive: number, tabMenu: Array<TabsMenuInterface>) {
    this.$emit('tab-active', indexTabActive, tabMenu);
  }

  tabClick(item: TabsMenuInterface, index: number) {
    const newTabMenu = this.menu.map((tab: TabsMenuInterface) => {
      if (tab.title === item.title) return { ...tab, active: true };
      return { ...tab, active: false };
    });

    this.emitTabActive(index, newTabMenu);
  }
}
