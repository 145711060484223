import axios from 'axios';

export default class CdnService {
  private basePath = 'https://s3.amazonaws.com/cdn.explicae.com.br';

  async getStatus() {
    const URI = '/maintenance/status.json';

    const response = await axios.get(`${this.basePath}${URI}`);

    return response?.data;
  }
}
