










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ProgressLinearAnimated extends Vue {
  @Prop({ default: 50 }) progressPercentage!: number;
  @Prop({ default: true }) isAnimation!: boolean;
}
