import { render, staticRenderFns } from "./ModalCourse.vue?vue&type=template&id=ad87baae&scoped=true&"
import script from "./ModalCourse.vue?vue&type=script&lang=ts&"
export * from "./ModalCourse.vue?vue&type=script&lang=ts&"
import style0 from "./ModalCourse.scss?vue&type=style&index=0&id=ad87baae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad87baae",
  null
  
)

export default component.exports