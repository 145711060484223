
























import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';

import IconPodium from '@/assets/icons/Podium.vue';
import IconPodiumUser from '@/assets/icons/PodiumUser.vue';
import IconShare from '@/assets/icons/Share.vue';

import ActiveModal from '@/share/Util/ActiveModal';

@Component({
  components: {
    IconPodium,
    IconPodiumUser,
    IconShare,
  },
})
export default class Actions extends Vue {
  @Prop({ required: true }) month!: string;

  private showUserPodium = true;

  private ActiveModal = new ActiveModal();

  get textButtonPodium() {
    return this.showUserPodium ? 'Ver pódio completo' : 'Ver somente minha posição';
  }

  changeActions() {
    this.showUserPodium = !this.showUserPodium;

    this.$emit('change-actions', this.showUserPodium);
  }

  showModal() {
    this.ActiveModal.activeModal('ModalSharePodium');
  }
}
