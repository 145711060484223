export const MATERIAL_CATEGORY_THEORETICAL = 1;
export const MATERIAL_CATEGORY_CONTENT_AND_QUESTION = 2;
export const MATERIAL_CATEGORY_ORIENTED_QUESTION = 3;
export const MATERIAL_CATEGORY_REVISION = 4;
export const MATERIAL_CATEGORY_QUESTIONS_ENEM = 5;
export const MATERIAL_CATEGORY_QUESTIONS_VESTIBULARES = 6;
export const MATERIAL_CATEGORY_QUESTIONS_FUVEST = 7;
export const MATERIAL_CATEGORY_QUESTIONS_MEDICINA = 8;
export const MATERIAL_CATEGORY_SUMMARY = 9;
export const MATERIAL_CATEGORY_TEST = 10;
export const MATERIAL_CATEGORY_ANSWER = 11;
