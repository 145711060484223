import { NavigationGuardNext, Route } from 'vue-router';

import Permission from './index';
import { LIST_PERMISSION } from '@/constant/ListPermission';
import { LIST_NAME_CONTEXT } from '@/constant/Context';

export function permissionPlanner(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const PermissionClass = new Permission();

  if (!PermissionClass.can(LIST_PERMISSION.PLANNER)) {
    next({
      name: 'Planner',
    });
  }

  next();
}

export function permissionPlannerContext(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const context = to.params?.context;
  const PermissionClass = new Permission();

  if (context !== LIST_NAME_CONTEXT.PLANNER) {
    next();
    return;
  }

  if (!PermissionClass.can(LIST_PERMISSION.PLANNER)) {
    next({
      name: 'Planner',
    });
  }

  next();
}
