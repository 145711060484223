import { NavigationGuardNext, Route } from 'vue-router';

import Permission from './index';

import { LIST_PERMISSION } from '@/constant/ListPermission';

export function permissionFeature(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const PermissionClass = new Permission();
  const isHighSchool = PermissionClass.can(LIST_PERMISSION.BOOKS_DEGREE_HIGH_SCHOOL);

  if (!isHighSchool) {
    next({
      name: 'Dashboard',
    });
  }

  next();
}
