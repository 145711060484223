/* eslint-disable import/no-cycle */
import store from '@/store';
import AppClient from '../AppClient';

export default class MaterialCategoryService {
  private basePath = process.env.VUE_APP_BOOK_API;
  private Client = new AppClient({ url: this.basePath });

  async getMaterialCategory() {
    const URI = '/material/categories';

    if (store.getters.materialCategory.length > 0) {
      return store.getters.materialCategory;
    }

    const response = await this.Client.get(URI);
    store.commit('setMaterialCategory', response?.data);

    return response?.data;
  }
}
