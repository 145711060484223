import { Discount } from '@/pages/Shopping/interfaces/IShopping';

const Payment = {
  state: {
    choiceIndexMenu: 0,
    finalPrice: 0,
    discountUser: null as Discount | null,
  },
  mutations: {
    setChoiceIndexMenu(state: Record<string, any>, payload: number) {
      state.choiceIndexMenu = payload;
    },
    setFinalPrice(state: Record<string, any>, payload: number) {
      state.finalPrice = payload;
    },
    setDiscountUser(state: Record<string, any>, payload: Discount | null) {
      state.discountUser = payload;
    },
  },
  getters: {
    choiceIndexMenu: (state: Record<string, any>) => state.choiceIndexMenu,
    finalPrice: (state: Record<string, any>) => state.finalPrice,
    discountUser: (state: Record<string, any>) => state.discountUser,
  },
};

export default Payment;
