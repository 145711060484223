





















































































































import { Component, Vue } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';
import PlusPlanIcon from '@/assets/icons/PlusPlan.vue';
import CheckPlanIcon from '@/assets/icons/checkPlan.vue';

import { URLS_SIMULATED } from '@/constant/FreePlanUrls';

@Component({
  components: {
    Modal,
    PlusPlanIcon,
    CheckPlanIcon,
  },
})
export default class ModalPopUpPlanFreeSimulated extends Vue {
  private URLS_SIMULATED = URLS_SIMULATED;
}
