














































































import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import Tabs, { TabsMenuInterface } from '@/components/Tabs/Tabs.vue';
import ExSlick from '@/components/Slick/index.vue';
import Books from '@/components/Book/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import ExLoading from '@/globalComponents/ExLoading/index.vue';

@Component({
  components: {
    ContainerFluid,
    Tabs,
    ExSlick,
    BoxContainer,
    Books,
    FeedbackUser,
    ExLoading,
  },
})
export default class BooksTab extends Vue {
  @Prop({ required: false }) titleBox!: string;
  @Prop() listBooksTab!: Array<any>;
  @Prop({ required: true }) tabMenu!: Array<TabsMenuInterface>;
  @Prop({ required: false }) listCloseTab!: Record<string, any>;
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({ default: false }) isLoadingTabs!: boolean;

  private updatedTabMenu: Array<TabsMenuInterface> = [];

  get showHoverInTab() {
    return this.tabMenu.length > 1;
  }

  get tabNameCurrent() {
    return this.updatedTabMenu.find((tab: TabsMenuInterface) => tab.active)?.slug || '';
  }

  @Watch('tabMenu', {
    immediate: true,
  })
  getTabMenu() {
    this.updatedTabMenu = this.tabMenu;
  }

  tabsClick(indexTabSelected: number, tabMenu: Array<TabsMenuInterface>) {
    if (tabMenu.length) {
      const tabSelected = tabMenu[indexTabSelected];
      this.$emit('change-tab', tabSelected?.slug, tabSelected?.degreeID);

      if (this.tabNameCurrent !== tabSelected?.slug && this.showHoverInTab) this.updatedTabMenu = tabMenu;
    }
  }
}
