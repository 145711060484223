const Theme = {
  state: {
    isDarkMode: false,
  },
  mutations: {
    setTheme(state: Record<string, any>, payload: boolean) {
      state.isDarkMode = payload;
    },
  },
  getters: {
    isDarkMode: (state: Record<string, any>) => state.isDarkMode,
  },

};

export default Theme;
