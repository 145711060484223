const Video = {
  state: {
    videoClicked: {},
    idsVideoWatched: [],
    isLoadingVideosWatched: true,
    recentlyViewedVideo: [],
    creditData: {},
  },
  mutations: {
    setVideoClicked(state: Record<string, any>, payload: Record<string, any>) {
      state.videoClicked = payload;
    },
    setIdsVideoWatched(state: Record<string, any>, payload: Array<Record<string, any>>) {
      state.idsVideoWatched = payload;
    },
    setIsLoadingVideosWatched(state: Record<string, any>, payload: boolean) {
      state.isLoadingVideosWatched = payload;
    },
    setRecentlyViewedVideo(state: Record<string, any>, payload: number) {
      state.recentlyViewedVideo = [...state.recentlyViewedVideo, payload];

      if (state.creditData) {
        state.creditData.send_total += 1;
        state.creditData.is_credits_video = state.creditData.send_total < state.creditData.credits;
      }
    },
    setCreditData(state: Record<string, any>, payload: Record<string, any>) {
      state.creditData = payload;
    },
  },
  getters: {
    videoClicked: (state: Record<string, any>) => state.videoClicked,
    idsVideoWatched: (state: Record<string, any>) => state.idsVideoWatched,
    isLoadingVideosWatched: (state: Record<string, any>) => state.isLoadingVideosWatched,
    recentlyViewedVideo: (state: Record<string, any>) => state.recentlyViewedVideo,
    creditData: (state: Record<string, any>) => state.creditData,
  },

};

export default Video;
