const getDefaultState = () => ({
  plannerQuestion: {
    date: {},
    timeUser: [],
    exerciseMethod: {},
    focus: {},
    series: {},
    foreignLanguage: {},
    redaction: {},
    historic: {},
    disciplinesExclude: [],
    topicsExclude: [],
  },
  plannerResponse: {
    configUser: {},
    topics: [],
    validateData: {},
    week: {},
  },
  plannerInteraction: {
    ableButtonValidate: false,
    loadingValidate: Boolean || null,
    validatePlanner: false,
  },
});

const Planner = {
  state: getDefaultState(),
  mutations: {
    resetState(state: Record<string, any>) {
      Object.assign(state, getDefaultState());
    },
    setPlannerDate(state: Record<string, any>, payload: Record<string, any>) {
      state.plannerQuestion.date = payload;
    },
    setPlannerTimeUser(state: Record<string, any>, payload: Array<Array<Record<string, any>>>) {
      state.plannerQuestion.timeUser = payload;
    },
    setPlannerExerciseMethod(state: Record<string, any>, payload: string) {
      state.plannerQuestion.exerciseMethod = payload;
    },
    setPlannerFocus(state: Record<string, any>, payload: string) {
      state.plannerQuestion.focus = payload;
    },
    setPlannerSeries(state: Record<string, any>, payload: Array<string>) {
      state.plannerQuestion.series = payload;
    },
    setPlannerForeignLanguage(state: Record<string, any>, payload: string) {
      state.plannerQuestion.foreignLanguage = payload;
    },
    setPlannerRedaction(state: Record<string, any>, payload: boolean) {
      state.plannerQuestion.redaction = payload;
    },
    setPlannerHistoric(state: Record<string, any>, payload: boolean) {
      state.plannerQuestion.historic = payload;
    },
    setPlannerDisciplinesExclude(state: Record<string, any>, payload: Array<number>) {
      state.plannerQuestion.disciplinesExclude = payload;
    },
    setPlannerTopicsExclude(state: Record<string, any>, payload: Array<number>) {
      state.plannerQuestion.topicsExclude = payload;
    },
    setPlannerConfigUser(state: Record<string, any>, payload: Record<string, any>) {
      state.plannerResponse.configUser = payload;
    },
    setPlannerTopics(state: Record<string, any>, payload: Array<Record<string, any>>) {
      state.plannerResponse.topics = payload;
    },
    setPlannerValidateData(state: Record<string, any>, payload: Record<string, any>) {
      state.plannerResponse.validateData = payload;
    },
    setPlannerWeek(state: Record<string, any>, payload: Record<string, any>) {
      state.plannerResponse.week = payload;
    },
    setPlannerAbleButtonValidate(state: Record<string, any>, payload: boolean) {
      state.plannerInteraction.ableButtonValidate = payload;
    },
    setPlannerLoadingValidate(state: Record<string, any>, payload: boolean | null) {
      state.plannerInteraction.loadingValidate = payload;
    },
    setPlannerValidatePlanner(state: Record<string, any>, payload: boolean) {
      state.plannerInteraction.validatePlanner = payload;
    },
  },
  getters: {
    plannerQuestion: (state: Record<string, any>) => state.plannerQuestion,
    plannerResponse: (state: Record<string, any>) => state.plannerResponse,
    plannerInteraction: (state: Record<string, any>) => state.plannerInteraction,
    plannerTopics: (state: Record<string, any>) => state.plannerResponse.topics,
    plannerHistoric: (state: Record<string, any>) => state.plannerQuestion.historic,
    plannerConfigUser: (state: Record<string, any>) => {
      let init = '';
      let end = '';
      const hours: Array<number> = [];

      if (Object.entries(state.plannerQuestion.date).length) {
        init = state.plannerQuestion.date?.start;
        end = state.plannerQuestion.date?.end;
      }

      if (state.plannerQuestion.timeUser.length) {
        const stateUser = state.plannerQuestion.timeUser;

        stateUser.forEach((lineTable: Array<Record<string, any>>) => {
          lineTable.forEach((item: Record<string, any>, indexItem: number) => {
            if (item?.select) {
              const value = hours[indexItem] || 0;
              hours[indexItem] = value + 1;
            } else {
              const value = hours[indexItem] || 0;
              hours[indexItem] = value;
            }
          });
        });
      }

      return {
        init,
        end,
        hours,
      };
    },
    plannerTopicsContent: (state: Record<string, any>) => {
      const storeState = state.plannerQuestion;

      const focus = storeState.focus?.value.toLowerCase();
      let series: Array<string> = [];
      const model = '';

      series = storeState.series?.value === -1 ? [1, 2, 3] : [storeState.series?.value];

      return {
        focus,
        series,
        model,
      };
    },
    plannerQuestionSidebar: (state: Record<string, any>) => {
      const storeState = state.plannerQuestion;

      const nameFocus = storeState.focus?.title;
      const methodExercise = storeState.exerciseMethod?.title;
      const language = storeState.foreignLanguage?.title;
      let withRedaction = '';
      let withHistoric = '';
      let series = '';
      const disciplinesExclude = storeState.disciplinesExclude.length;
      const topicsExclude = storeState.topicsExclude.length;

      withRedaction = storeState.redaction?.select ? storeState.redaction?.title : '';
      withHistoric = storeState.historic?.select ? storeState.historic?.title : '';
      series = storeState.series?.title;

      return {
        nameFocus,
        methodExercise,
        language,
        withRedaction,
        withHistoric,
        series,
        disciplinesExclude,
        topicsExclude,
      };
    },
    plannerValidateData: (state: Record<string, any>) => {
      const storeState = state.plannerQuestion;

      const focus = storeState.focus?.value.toLowerCase();
      let series: Array<string> = [];
      const model = '';
      const exerciceOnline = storeState.exerciseMethod?.title === 'Exercícios Online';
      const whitHistocic = storeState.historic?.title === 'Sim';
      const whitRedaction = storeState.redaction?.title === 'Sim';
      const { disciplinesExclude } = storeState;
      const { topicsExclude } = storeState;
      const config = state.plannerResponse.configUser;

      series = storeState.series?.value === -1 ? [1, 2, 3] : [storeState.series?.value];

      if (storeState.foreignLanguage?.value === 'espanhol') {
        disciplinesExclude.push(3);
      }
      if (storeState.foreignLanguage?.value === 'ingles') {
        disciplinesExclude.push(13);
      }

      return {
        focus,
        series,
        model,
        exerciceOnline,
        whitHistocic,
        whitRedaction,
        disciplinesExclude,
        topicsExclude,
        config,
      };
    },
    plannerVerifyPlanner: (state: Record<string, any>) => {
      const storeState = state.plannerResponse;

      const timeUser = storeState.configUser?.totalTime;
      const timePlanner = storeState.validateData?.time;

      return timeUser >= timePlanner;
    },
  },
};

export default Planner;
