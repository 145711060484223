import { render, staticRenderFns } from "./ModalPopUpPlanFree.vue?vue&type=template&id=993adefa&scoped=true&"
import script from "./ModalPopUpPlanFree.vue?vue&type=script&lang=ts&"
export * from "./ModalPopUpPlanFree.vue?vue&type=script&lang=ts&"
import style0 from "./ModalPopUpPlanFree.scss?vue&type=style&index=0&id=993adefa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "993adefa",
  null
  
)

export default component.exports