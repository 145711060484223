/* eslint-disable import/no-cycle */
import AppClient from '../AppClient';

import { PutUserFeature } from '@/globalInterfaces/UserFeatures';

import { ContextOptionsCredits } from '@/mixins/Credits';

import { RegisterInterface } from './AuthInterface';

export default class AuthService {
  private basePath = process.env.VUE_APP_AUTH_API;

  private Client = new AppClient({ url: this.basePath });

  async getProfile() {
    const URI = '/session';

    const response = await this.Client.get(URI);

    return response?.data;
  }

  async updateOnboarding(idOnboarding: number) {
    const URI = `/tests/${idOnboarding}`;

    const response = await this.Client.post(URI);

    return response?.data;
  }

  async saveLastFeatureAccess(feature: PutUserFeature) {
    const URI = '/feature';

    const response = await this.Client.put(URI, feature);

    return response;
  }

  async getCredits(context: ContextOptionsCredits) {
    const URI = `${context}/credits`;

    const response = await this.Client.get(URI);

    return response.data;
  }

  async register(registerData: RegisterInterface) {
    const URI = '/create';

    const response = await this.Client.post(URI, registerData, {}, true);

    return response;
  }
}
