
























import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

import VideoService from '@/services/Video/VideoService';

import { File } from '@/globalInterfaces/Video';

const PlayerVideoLoading = () => import('@/widgets/Video/PlayerVideo/PlayerVideoLoading.vue');
const PlayerVideoVimeo = () => import('@/widgets/Video/PlayerVideo/PlayerVideoVimeo.vue');
const PlayerVideoYouTube = () => import('@/widgets/Video/PlayerVideo/PlayerVideoYouTube.vue');

const COMPONENT_YOUTUBE = 'PlayerVideoYouTube';
const COMPONENT_VIMEO = 'PlayerVideoVimeo';
const VIDEO_YOUTUBE = 'youtube';

@Component({
  components: {
    Modal,
    PlayerVideoLoading,
    PlayerVideoVimeo,
    PlayerVideoYouTube,
  },
})
export default class ModalVideo extends Vue {
  @Prop({ required: true }) videoID!: number;

  private file: File | null = null;

  private loadingVideo = true;

  private VideoService = new VideoService();

  created() {
    this.getFile();
  }

  get isVimeo() {
    if (!(this.file?.detail?.vendor && this.file?.detail?.vendor === VIDEO_YOUTUBE)) {
      return COMPONENT_VIMEO;
    }

    return COMPONENT_YOUTUBE;
  }

  async getFile() {
    if (!this.videoID) {
      return;
    }
    try {
      this.loadingVideo = true;

      this.file = await this.VideoService.getVideo(Number(this.videoID));
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o vídeo.',
        status: 'error',
      });
    } finally {
      this.loadingVideo = false;
    }
  }
}
