import { RouteConfig } from 'vue-router';

import { multiGuard } from '../Middleware/MultiGuard';

import { permissionPageExam } from '../Middleware/permission/PageExam';
import { permissionFeature } from '../Middleware/permission/Feature';
import { onboardingExam } from '../Middleware/onboarding';

export const NAME_ROUTES_EXAM = {
  LIST: 'Exams',
  SHOW: 'Exam',
  TUTORIAL: 'TutorialExam',
  ExamViewer: 'ExamViewer',
};

export const Exam: Array<RouteConfig> = [
  {
    path: '/vestibular',
    name: NAME_ROUTES_EXAM.LIST,
    component: () => import(/* webpackChunkName: "question" */ '@/pages/Exams/index.vue'),
    meta: { title: 'Vestibulares' },
    beforeEnter: multiGuard([
      // permissionFeature,
      onboardingExam,
    ]),
  },
  {
    path: '/vestibular/tutorial',
    name: 'TutorialExam',
    component: () => import(/* webpackChunkName: "exam-tutorial" */ '@/pages/Exams/pages/TutorialExam/index.vue'),
    meta: { title: 'Tutorial Vestibulares' },
    // beforeEnter: permissionFeature,
  },
  {
    path: '/vestibular/:book/:idExam',
    name: 'Exam',
    component: () => import(/* webpackChunkName: "question-show" */ '@/pages/Exams/pages/Exam/index.vue'),
    meta: { title: 'Vestibular Questões' },
    beforeEnter: multiGuard([
      // permissionFeature,
      // permissionPageExam,
    ]),
  },
  {
    path: '/vestibular/pdf/:idExam/:idFile/:slug',
    name: 'ExamViewer',
    component: () => import(/* webpackChunkName: "question-show" */ '@/pages/Exams/pages/ExamViewer/index.vue'),
    meta: { title: 'Visualizador Vestibular' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionPageExam,
    ]),
  },
];
