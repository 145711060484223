export const SLUGS = {
  BOOKS_MEDIUM: 'Ensino Médio',
  BOOKS_FUNDAMENTAL_II: 'Ensino Fundamental 2',
  BOOKS_FUNDAMENTAL_I: 'Ensino Fundamental 1',
  BOOKS_SAEB: 'Saeb',
  COURSES: 'courses',
  SIMULATIONS: 'simulation',
  REDACTION: 'redaction',
};

export const TAB_MENU = [
  {
    title: 'Redação',
    slug: SLUGS.REDACTION,
    active: false,
    order: 0,
  },
  {
    title: 'Meus Cursos',
    slug: SLUGS.COURSES,
    active: false,
    order: 4,
  },
  {
    title: 'Simulados',
    slug: SLUGS.SIMULATIONS,
    active: false,
    order: 5,
  },
];

export const TAB_MENU_SALES = [
  // { title: 'Cursos', slug: 'courses', active: true },
  { title: 'Produtos', slug: 'products', active: true },
];
