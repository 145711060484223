import { Component, Vue } from 'vue-property-decorator';
import store from '@/store/index';

@Component({})
export class Sidebar extends Vue {
  private static instance: Sidebar;
  public contentInstance: any = new Vue();

  public setSidebar(contentInstance: any) {
    // https:// https://codesandbox.io/embed/4l3w20zomw
    contentInstance.$mount();
    this.contentInstance = contentInstance;
  }

  public static getInstance(): Sidebar {
    if (!Sidebar.instance) {
      Sidebar.instance = new Sidebar();
    }
    return Sidebar.instance;
  }

  public show() {
    store.commit('setSidebar', true);
  }

  public hide() {
    store.commit('setSidebar', false);
  }

  public open() {
    // console.log(' sidebar open ');
  }
  public close() {
    // console.log(' sidebar close ');
  }
}

export default Sidebar;
