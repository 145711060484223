import { LIST_PERMISSION } from './ListPermission';

export const ID_PRE_REVISION_REWINDS = 24;
export const ID_REVISION_END_YEAR = 25;
export const ID_FOCA_ENEM_EXTENSIVO = 26;
export const ID_OPERACAO_MED_EXTENSIVO = 27;
export const ID_DNA_SP_EXTENSIVO = 28;
export const ID_FOCA_ENEM_INTENSIVO = 29;
export const ID_OPERACAO_MED_INTENSIVO = 30;
export const ID_DNA_SP_INTENSIVO = 31;
export const ID_REVISION_HALF_YEAR = 32;
export const ID_COURSE_60_DAYS_FOR_THE_ENEM = 33;
export const ID_COURSE_FINAL_REVIEW = 34;
export const ID_COURSE_VEST_MAPA = 35;

export const IDS_FOR_PERMISSION_COURSE: Record<number, string> = {
  [ID_PRE_REVISION_REWINDS]: LIST_PERMISSION.COURSE_REINFORCEMENT_EXTENSIVE,
  [ID_REVISION_END_YEAR]: LIST_PERMISSION.COURSE_END_YEAR,
  [ID_FOCA_ENEM_EXTENSIVO]: LIST_PERMISSION.COURSE_ENEM_EXTENSIVE,
  [ID_DNA_SP_EXTENSIVO]: LIST_PERMISSION.COURSE_SP_EXTENSIVE,
  [ID_OPERACAO_MED_EXTENSIVO]: LIST_PERMISSION.COURSE_MED_EXTENSIVE,
  [ID_FOCA_ENEM_INTENSIVO]: LIST_PERMISSION.COURSE_ENEM_INTENSIVE,
  [ID_OPERACAO_MED_INTENSIVO]: LIST_PERMISSION.COURSE_MED_INTENSIVE,
  [ID_DNA_SP_INTENSIVO]: LIST_PERMISSION.COURSE_SP_INTENSIVE,
  [ID_REVISION_HALF_YEAR]: LIST_PERMISSION.COURSE_HALF_YEAR,
  [ID_COURSE_60_DAYS_FOR_THE_ENEM]: LIST_PERMISSION.COURSE_60_DAYS_FOR_THE_ENEM,
  [ID_COURSE_FINAL_REVIEW]: LIST_PERMISSION.COURSE_FINAL_REVIEW,
  [ID_COURSE_VEST_MAPA]: LIST_PERMISSION.COURSE_VEST_MAPA,
};
