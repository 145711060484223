








































import {
  Component,
  Vue,
} from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

import Confetti from '@/components/Confetti/index.vue';
import AvatarRanking from '@/components/AvatarRanking/index.vue';

import { NotificationData } from '@/globalInterfaces/Notifications';

import NotificationsService from '@/services/Notifications/NotificationsServices';

@Component({
  components: {
    Modal,
    Confetti,
    AvatarRanking,
  },
})
export default class ModalRankingPass extends Vue {
  private NotificationsService = new NotificationsService();

  get avatar() {
    return this.$store.getters.avatar;
  }

  get dataRankingPass(): NotificationData | null {
    const dataRanking = this.$store.getters.notificationDataModalRankingPass;

    return Object.keys(dataRanking).length
      ? dataRanking
      : null;
  }

  async updateNotifications() {
    try {
      await this.NotificationsService.updateNotifications();
      this.$store.commit('setQuantityNotifications', 0);
    } catch (error) {
      console.error(error);
    }
  }
}
