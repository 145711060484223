import { Component, Mixins } from 'vue-property-decorator';

import Permission from './permission';

import { IDS_FOR_PERMISSION_COURSE } from '@/constant/CoursesId';
import { LIST_PERMISSION } from '@/constant/ListPermission';

@Component
export default class GeneralBooks extends Mixins(Permission) {
  get permissionDownloadMaterials() {
    return this.can(LIST_PERMISSION.MATERIALS_DOWNLOAD) || this.can(LIST_PERMISSION.ALL_MATERIALS_REDACTION);
  }

  isDisableCourse(id: number) {
    return !this.can(IDS_FOR_PERMISSION_COURSE[id]);
  }
}
