















































import { Component, Mixins } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

import CreditsConsumer from '@/mixins/CreditsConsumer';

@Component({
  components: {
    Modal,
  },
})
export default class ModalConsumerCredits extends Mixins(CreditsConsumer) {
  handleRedirectPlan() {
    this.$router.push({
      name: 'Store',
    });
  }
}
