import { LIST_PERMISSION } from './ListPermission';

import {
  MATERIAL_CATEGORY_THEORETICAL,
  MATERIAL_CATEGORY_CONTENT_AND_QUESTION,
  MATERIAL_CATEGORY_ORIENTED_QUESTION,
  MATERIAL_CATEGORY_REVISION,
  MATERIAL_CATEGORY_QUESTIONS_ENEM,
  MATERIAL_CATEGORY_QUESTIONS_VESTIBULARES,
  MATERIAL_CATEGORY_QUESTIONS_FUVEST,
  MATERIAL_CATEGORY_QUESTIONS_MEDICINA,
} from './CategoryMaterialId';

export const ID_MATERIAL_CATEGORY_FOR_PERMISSION: Record<number, Array<string>> = {
  [MATERIAL_CATEGORY_THEORETICAL]: [LIST_PERMISSION.VIDEAULAS_THEORETICAL],
  [MATERIAL_CATEGORY_CONTENT_AND_QUESTION]: [LIST_PERMISSION.VIDEAULAS_THEORETICAL, LIST_PERMISSION.VIDEAULAS_GUIDEDQUESTIONS],
  [MATERIAL_CATEGORY_ORIENTED_QUESTION]: [LIST_PERMISSION.VIDEAULAS_GUIDEDQUESTIONS],
  [MATERIAL_CATEGORY_REVISION]: [LIST_PERMISSION.VIDEAULAS_REVIEWS],
  [MATERIAL_CATEGORY_QUESTIONS_ENEM]: [LIST_PERMISSION.VIDEAULAS_SOLVE_ENEM],
  [MATERIAL_CATEGORY_QUESTIONS_VESTIBULARES]: [LIST_PERMISSION.VIDEAULAS_SOLVE_VESTIBULAR],
  [MATERIAL_CATEGORY_QUESTIONS_FUVEST]: [LIST_PERMISSION.VIDEAULAS_SOLVE_FUVEST],
  [MATERIAL_CATEGORY_QUESTIONS_MEDICINA]: [LIST_PERMISSION.VIDEAULAS_SOLVE_MEDICINE],
};
