import store from '@/store';

import AppClient from '../AppClient';

import {
  GetDoubts as IGetDoubts,
  SaveReply as ISaveReply,
  SaveDoubts as ISaveDoubts,
  GetMyDoubts as IGetMyDoubts,
} from './DoubtsInterface';

export default class DoubtsService {
  private basePath = process.env.VUE_APP_DOUBTS_API;

  private Client = new AppClient({ url: this.basePath });

  async getDoubts(data: IGetDoubts) {
    const URI = '/doubts';

    const response = await this.Client.get(URI, data);

    return response?.data;
  }

  async getMyDoubts(data: IGetMyDoubts) {
    const URI = '/doubts/me';

    const response = await this.Client.get(URI, data);

    return response?.data;
  }

  async getQuantityDoubts() {
    if (store.getters.totalDoubtsMonthly !== null) {
      return {
        count: store.getters.totalDoubtsMonthly,
      };
    }

    const URI = '/doubts/user/count/month';

    const response = await this.Client.get(URI);

    return response?.data;
  }

  async getQuantityDoubtsToday() {
    const URI = '/doubts/user/count/today';

    const response = await this.Client.get(URI);

    return response?.data;
  }

  async saveDoubts(data: ISaveDoubts) {
    const URI = '/doubts';

    const response = await this.Client.post(URI, data);

    return response;
  }

  async saveReply(data: ISaveReply) {
    const URI = `doubts/${data.id}/replies`;

    const response = await this.Client.post(URI, {
      content: data.content,
      ...(data.userType && data.userType >= 0 && { userType: data.userType }),
    });

    return response;
  }

  async getRepliesDoubts(id: number) {
    const URI = `/doubts/${id}/replies`;

    const response = await this.Client.get(URI, {
      logged: true,
    });

    return response?.data;
  }
}
