const ROUTER_PAGE_RENEW_PLAN = ['RenewPlan', 'RenewPendencies', 'RenewPaymentPix', 'RenewPaymentBillet', 'RenewPaymentThankYou'];

const ROUTER_PAGE_NAME_SHOPPING = ['Store', 'Pendencies', 'Descriptive', 'Payment', 'PaymentBillet', 'PaymentPix'];

const PENDING_ROUTES = ['RenewPendencies', 'Pendencies'];

export {
  ROUTER_PAGE_RENEW_PLAN,
  ROUTER_PAGE_NAME_SHOPPING,
  PENDING_ROUTES,
};
