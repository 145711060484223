import { Component, Vue } from 'vue-property-decorator';

@Component({})
export class Breadcrumb extends Vue {
  private static instance: Breadcrumb;
  public contentInstance: any = new Vue();
  public listPaths = [];

  public set(listPaths: any) {
    this.listPaths = listPaths;
  }

  public static getInstance(): Breadcrumb {
    if (!Breadcrumb.instance) {
      Breadcrumb.instance = new Breadcrumb();
    }
    return Breadcrumb.instance;
  }
}

export default Breadcrumb;
