// eslint-disable-next-line import/no-cycle
import store from '@/store';

function searchStoreFiles(ids: Array<number>, nameStore: string, nameID: string) {
  let filesStore: Array<Record<string, any>> = [];
  const files: Array<Record<string, any>> = [];
  const idsFiles: Array<number> = [];

  filesStore = store.getters[nameStore];

  ids.forEach((id: number) => {
    const foundFiles = filesStore.find((file: Record<string, any>) => file[nameID] === id);

    if (!foundFiles) idsFiles.push(id);
    else files.push(foundFiles);
  });

  return {
    files,
    idsFiles,
  };
}

export {
  searchStoreFiles,
};
