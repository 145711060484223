import Vue from 'vue';

const directive_scroll = {
  inserted(el: any, binding: any) {
    let idTimer = 0;

    const removeEventListener = (evt: any) => {
      if (!binding.value(el, evt)) {
        clearTimeout(idTimer);
        window.removeEventListener('scroll', removeEventListener, true);
      }
    };

    idTimer = setTimeout(() => {
      window.addEventListener('scroll', removeEventListener, true);
    }, 1000);
  },
};

const directive_click_outside = {
  bind(el: any, binding: any) {
    const { bubble } = binding.modifiers;
    const click_outside_handler = (e: any) => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e);
      }
    };
    document.addEventListener('click', click_outside_handler);
  },
};

Vue.directive('scroll', directive_scroll);
Vue.directive('click-outside', directive_click_outside);
