



















import { Component, Vue } from 'vue-property-decorator';

import IBell from '@/assets/icons/Bell.vue';

@Component({
  components: {
    IBell,
  },
})
export default class WarningPaymentPendency extends Vue {
  handleClickPendency() {
    this.$emit('pendency');
  }
}
