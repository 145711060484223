const MaterialCategory = {
  state: {
    materialCategory: [],
    categoryFilterByContext: [
      {
        categories: [],
        context: 'videos',
      },
      {
        categories: [],
        context: 'exercices',
      },
      {
        categories: [],
        context: 'archives',
      },
    ],
  },
  mutations: {
    setMaterialCategory(state: Record<string, any>, payload: Array<Record<string, any>>) {
      state.materialCategory = payload;
    },
    clearFilterCategories(state: Record<string, any>) {
      state.categoryFilterByContext = state.categoryFilterByContext.map((dataContext: Record<string, any>) => ({ ...dataContext, categories: [] }));
    },
    saveFilterCategories(state: Record<string, any>, payload: Record<string, any>) {
      state.categoryFilterByContext = state.categoryFilterByContext.map((dataContext: Record<string, any>) => {
        if (dataContext.context === payload.context) {
          return {
            ...dataContext,
            categories: payload.categories,
          };
        }

        return dataContext;
      });
    },
    setCategoryFilterByContext(state: Record<string, any>, payload: Record<string, any>) {
      state.categoryFilterByContext = state.categoryFilterByContext.map((context: Record<string, any>) => {
        if (context.context === payload.context) {
          const newContext = context;

          if (!payload.idCategory) {
            newContext.categories = newContext.categories.map((category: Record<string, any>) => ({ ...category, checked: payload.checkedAll }));

            return newContext;
          }

          newContext.categories = newContext.categories.map((category: Record<string, any>) => {
            if (category.idCategory === payload.idCategory) {
              return { ...category, checked: !category.checked };
            }
            return category;
          });

          return newContext;
        }

        return context;
      });
    },
  },
  getters: {
    materialCategory: (state: Record<string, any>) => state.materialCategory,
    categoryFilterByContext: (state: Record<string, any>) => state.categoryFilterByContext,
  },
};

export default MaterialCategory;
