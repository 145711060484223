import Vue from 'vue';
import VueRouter, { NavigationGuardNext, RouteConfig, Route } from 'vue-router';
import topbar from 'topbar';

import store from '@/store/';

import Account from '@/services/Account/AccountService';

import ActiveModal from '@/share/Util/ActiveModal';
import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';

import CookieUtil from '../services/Util/cookieUtil';

import { logout } from '@/share/Util/Logout/index';
import { updateProfile } from '@/share/Util/Profile/Profile';
import { removeIsFreeQueryFromUrl } from '@/share/Util/Route';
import { assessBusinessModelForTheme } from '@/share/Util/Color/ConfigureColors';
import CdnService from '@/services/CDN/Cdn';
import { IMaintenance, MAINTENANCE_REF } from '@/pages/Maintenance/constants/Maintenance';

import { Default } from './routes/Default';
import { NotFound } from './routes/NotFound';
import { Dashboard } from './routes/Dashboard';
import { Profile } from './routes/Profile';
import { Historic } from './routes/Historic';
import { Ranking } from './routes/Ranking';
import { Planner } from './routes/Planner';
import { Book } from './routes/Book';
import { Video } from './routes/Videos';
import { Material } from './routes/Material';
import { Exercise } from './routes/Exercise';
import { Course } from './routes/Course';
import { Doubts } from './routes/Doubts';
import { Exam } from './routes/Exam';
import { Redaction } from './routes/Redaction';
import { Simulation } from './routes/Simulation';
import { Questions } from './routes/Questions';
import { Maintenance } from './routes/Maintenance';
import { Store } from './routes/Store';
import { Plan } from './routes/Plan';

topbar.config({
  barColors: {
    0: 'rgba(255, 221, 0, 1)',
    '.3': 'rgba(255, 221, 0, 1)',
    '1.0': 'rgba(255, 221, 0, 1)',
  },
});

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  ...Default,
  ...NotFound,
  ...Dashboard,
  ...Profile,
  ...Historic,
  ...Ranking,
  ...Planner,
  ...Book,
  ...Video,
  ...Material,
  ...Exercise,
  ...Course,
  ...Doubts,
  ...Exam,
  ...Redaction,
  ...Simulation,
  ...Questions,
  ...Maintenance,
  ...Store,
  ...Plan,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    const routesFromTo = { to, from };
    store.commit('RouterHistory/getRoutesFromTo', routesFromTo);
  },
});

function getPath() {
  // @ts-ignore
  return router.history.current;
}

function getQuery() {
  // @ts-ignore
  return router.history.current.query;
}

function nextPage(to: Route, from: Route, next: NavigationGuardNext) {
  const currentPath = getPath().path;
  const currentFileID = getQuery().fileID;
  if (currentPath === to.path && currentFileID === to.query?.fileID && currentPath) {
    return;
  }
  if (to.name) next();
}

function redirectToRouteWithIsFreeQuery(to: Route) {
  router.push({
    path: to.path,
    query: {
      isFree: 'true',
    },
  });
}

router.afterEach((to) => {
  document.title = `Explicaê - ${to.meta?.title}`;

  const myClass = window.document.getElementById('top-0') as HTMLElement;
  if (myClass) myClass.scrollIntoView();

  InstanceAmplitude.setPageview({
    page: window.location.href,
    title: to?.name || '',
  });
});

function setPageMaintenance() {
  router.push({
    name: 'Maintenance',
  });
}

function setPageProblem() {
  router.push({
    name: 'Problem',
  });
}

function setSidebar(nameRoute: string | null | undefined) {
  if (nameRoute !== 'PlannerConfig') {
    store.commit('setSidebar', false);
    store.commit('setSidebarExistence', false);
    store.commit('setDecreaseSidebar', false);
  }
}

function preventFromLeavingRoute(to: Route) {
  const setModal = new ActiveModal();
  setModal.activeModal('ModalExitSimulated');
  store.commit('setPendingRoute', to.path);
}

let profile: any;
let validate: any;

router.beforeEach(async (to, from, next) => {
  if (
    from.name === 'SimulatedQuestions'
    && !store.getters.clickedCloseModalExitSimulated
    && !store.getters.simulatedSent
  ) {
    preventFromLeavingRoute(to);
    return;
  }

  topbar.show();
  setSidebar(to.name);
  store.commit('setPendingRoute', to.path);
  store.commit('setClickedCloseModalExitSimulated', false);
  const environment = process.env.VUE_APP_ENVIRONMENT as string;
  const APP_REFERENCE = MAINTENANCE_REF[environment as keyof IMaintenance];
  let isMaintenance = false;

  try {
    const cdnService = new CdnService();
    const status = await cdnService.getStatus();
    const statusSite = status[APP_REFERENCE];
    isMaintenance = statusSite || false;
  } catch (error) {
    console.error('Error in get status from CDN', error);
  }

  if (isMaintenance && to.name !== 'Maintenance') {
    setPageMaintenance();

    return;
  }

  if (isMaintenance && to.name === 'Maintenance') {
    topbar.hide();
    next();
    return;
  }

  const isAnonymousPage = to.meta?.isAnonymous;

  const token = to.query?.token;
  const isFree = to.query?.isFree;

  if (token) {
    store.commit('setToken', String(token));

    if (isFree) {
      redirectToRouteWithIsFreeQuery(to);
      return;
    }

    await router.push(to.path || '/');

    return;

  } if (store.getters.token === '') {
    store.commit('initialState');
  }

  const AccountService = new Account();

  try {
    if (!validate) validate = await AccountService.validate();

  } catch (error) {
    CookieUtil.removeAccess();

    if (error?.status === STATUS_RESPONSE_API.SERVER_ERROR_INTERNAL_ERROR) {
      setPageProblem();
      return;
    }

    profile = null;
    logout({ isAnonymousPage });

    if (isAnonymousPage) {
      assessBusinessModelForTheme({});

      nextPage(to, from, next);
    }
  }

  if (to.name === null) {
    if (getPath().path !== '/404') await router.push('/404');

    topbar.hide();
    return;
  }

  if (!store.getters.appStart) {
    if (!validate) {
      profile = null;
      logout({ isAnonymousPage });
      topbar.hide();

      return;
    }

    if (!store.getters.profile) {
      profile = null;
      logout({ isAnonymousPage });
      topbar.hide();

      return;
    }

    store.commit('setAppStart', true);
  }

  if (validate) {
    if (!profile) {
      try {
        profile = await updateProfile();
      } catch (error) {
        logout({ isAnonymousPage });
        topbar.hide();

        return;
      }
      // Aplicando o indentificador do usuário na instancia do Amplitude
      InstanceAmplitude.identify({
        user: profile,
      });
    }

    topbar.hide();

    nextPage(to, from, next);
  } else {
    profile = null;
    logout({ isAnonymousPage });
  }

  removeIsFreeQueryFromUrl(to);
});

export default router;
