
























import {
  Component,
  Vue,
} from 'vue-property-decorator';

import { take, shuffle } from 'lodash';

import store from '@/store';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import AvatarRanking from '@/components/AvatarRanking/index.vue';

import RealtimeUsersLoading from './RealtimeUsersLoading.vue';

import StatsService from '@/services/Stats/StatsService';

import { UserOnline } from '@/globalInterfaces/Users';

const FEEDBACK_ERROR = 'Erro ao carregar alunos online.';
const FEEDBACK_NO_USERS_ONLINE = 'Não há usuários online.';

@Component({
  components: {
    BoxContainer,
    FeedbackUser,
    RealtimeUsersLoading,
    AvatarRanking,
  },
})
export default class RealtimeUsers extends Vue {
  private users: Array<UserOnline> = [];
  private total = 0;
  private isLoading = true;
  private requestError = false;
  private StatsService = new StatsService();

  mounted() {
    this.getUsersOnline();
  }

  beforeDestroy() {
    this.clearOnlineUsersFromStore();
  }

  get feedbackError() {
    return this.requestError ? FEEDBACK_ERROR : FEEDBACK_NO_USERS_ONLINE;
  }

  async getUsersOnline() {
    try {
      this.isLoading = true;

      const { users, total } = await this.StatsService.getRealtimeUsers();

      if (total > 0 && !users?.length) {
        throw new Error('Error getting users online');
      }

      if (users?.length) {
        this.users = this.shuffleListAndGetTopTwelve(users);
        this.total = total;
      }
    } catch (error) {
      this.requestError = true;

      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  shuffleListAndGetTopTwelve(users: Array<UserOnline>) {
    return take(shuffle(users), 11);
  }

  clearOnlineUsersFromStore() {
    store.commit('setUsersOnline', {
      users: [],
      total: 0,
    });
  }
}
