





















































import {
  Component, Prop, Watch, Mixins,
} from 'vue-property-decorator';

import FeedbackUser from '@/components/FeedbackUser/index.vue';
import PrintRedactionSheet from '@/components/Redaction/PrintRedactionSheet/PrintRedactionSheet.vue';

import ThemeWeekLoading from './ThemeWeekLoading.vue';

import RedactionService from '@/services/Redaction/RedactionService';

import openInstructionRedaction from '@/mixins/openInstructionRedaction';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';

import ActiveModal from '@/share/Util/ActiveModal';

interface Theme {
  id: number;
  title: string;
  category_id?: number;
  content?: string;
}

const MESSAGE_UNLIMITED_CREDITS = 'Participe agora, você possui créditos ilimitados.';
const NAME_CREDIT = 'crédito';
const NAME_CREDITS = 'créditos';
const TITLE_NOT_PERMISSION = 'Descubra o tema da semana sendo aluno Premium';

@Component({
  components: {
    FeedbackUser,
    PrintRedactionSheet,
    ThemeWeekLoading,
  },
})
export default class ThemeWeek extends Mixins(openInstructionRedaction) {
  @Prop({ default: 0 }) quantityCredits!: number;
  @Prop({ default: true }) isPermission!: boolean;

  private isLoadingThemeWeek = false;
  private themeWeek: Theme | null = null;

  private RedactionService = new RedactionService();

  private setModal = new ActiveModal();

  created() {
    this.getThemeWeek();
  }

  get message() {
    if (this.quantityCredits > 9999) {
      return MESSAGE_UNLIMITED_CREDITS;
    }

    if (this.quantityCredits > 0) {
      const nameCredits = this.quantityCredits === 1 ? NAME_CREDIT : NAME_CREDITS;

      return `Participe agora, você tem ${this.quantityCredits || 0} ${nameCredits}.`;
    }

    return 'Seus créditos para envio de redação acabaram, mas não se preocupe, próximo mês tem mais.';
  }

  get themeTitle() {
    return !this.isPermission ? TITLE_NOT_PERMISSION : this.themeWeek?.title;
  }

  get isPlanFree() {
    return this.$store.getters.profile?.plan?.planID === LIST_PLAN_ID.FREE;
  }

  async getThemeWeek() {
    try {
      this.isLoadingThemeWeek = true;

      const response = await this.RedactionService.getThemeWeek();

      if (response) this.themeWeek = response;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o tema da semana.',
        status: 'error',
      });
      console.error(error);
    } finally {
      this.isLoadingThemeWeek = false;
    }
  }

  @Watch('quantityCredits', {
    immediate: true,
  })
  setCanOpenProposal() {
    if (!this.quantityCredits) {
      this.$store.commit('setCanOpenProposals', false);
    }
  }

  handleRedirectRedaction(redactionId: number, componentName: string) {
    if (!this.isPermission) {
      this.setShowModal();

      return;
    }

    this.goToInstruction(redactionId, componentName);
  }

  setShowModal() {
    if (this.isPlanFree) this.showModal('modalPlanUpdate');
    else this.showModal('modalNotContentPlan');
  }

  showModal(id: string) {
    this.setModal.activeModal(id);
  }
}
