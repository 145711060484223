import { Component, Vue } from 'vue-property-decorator';

import {
  LIST_TUTORIALS_ROUTE,
  ROUTE_BOOK,
  ROUTE_LIST_COURSE,
  ROUTE_REDACTION,
} from '@/constant/PageTutorials';

@Component
export default class SetTutorial extends Vue {
  get path() {
    return this.$route.params.path;
  }

  get params() {
    if (this.nameRouteTutorial !== ROUTE_BOOK) return null;

    return {
      path: this.path,
    };
  }

  get routeName() {
    return this.$route.name;
  }

  get nameRouteTutorial() {
    const routeListThatContainsTutorial = Object.keys(LIST_TUTORIALS_ROUTE);

    if (!this.routeName) return '';

    const routeTutorialIndex = routeListThatContainsTutorial.indexOf(this.routeName);

    return Object.values(LIST_TUTORIALS_ROUTE)[routeTutorialIndex];
  }

  get showComponentHelp() {
    return !!this.nameRouteTutorial;
  }

  get hasPermission() {
    if (this.routeName === ROUTE_REDACTION) {
      return this.$store.getters.permissionRedaction;
    }

    if (this.routeName === ROUTE_LIST_COURSE) {
      return this.$store.getters.permissionListCourses;
    }

    return true;
  }
}
