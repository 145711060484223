import store from '@/store';

import AppClient from '../AppClient';

import { dataProcessingCourse } from './processingCourse';

import { ClassWeek } from '@/globalInterfaces/Course';

export default class CoursesService {
  private basePath = process.env.VUE_APP_COURSE_API;

  private Client = new AppClient({ url: this.basePath });

  async getCourses() {
    const URI = '/courses';

    const { courses } = store.getters;

    if (courses && courses?.length) return courses;

    const response = await this.Client.get(URI);

    store.commit('setCourses', response?.data);

    return response?.data;
  }

  async getCourseClasses(idCourse: number) {
    const URI = '/courses/classes/';

    const { courseClasses } = store.getters;

    if (courseClasses && courseClasses.idCourse === idCourse) return courseClasses.classes;

    const response = await this.Client.get(URI, { idCourse });

    store.commit('setCourseClasses', {
      idCourse,
      classes: response?.data,
    });

    return response?.data;
  }

  async getClassWeekCourse(courseID: string, classID: string) {
    const URI = '/courses/weeks/';

    const { coursesWeeks, idCourse, idClassCourse } = store.getters;

    if (coursesWeeks && coursesWeeks.length && idCourse === courseID && classID === idClassCourse) {
      return coursesWeeks;
    }

    const response = await this.Client.get(URI, { idCourse: courseID });

    if (response?.data.length) {
      const weeks = response?.data.map((week: ClassWeek) => dataProcessingCourse(week));

      store.commit('setCourseWeeks', weeks);
      store.commit('setIdCourse', courseID);
      store.commit('setIdClassCourse', classID);

      return weeks;
    }

    return response?.data;
  }

  async saveCourseClassPreference(courseId: number, classId: number, languageId: number | null) {
    const URI = '/courses/preference';

    const response = await this.Client.post(URI, {
      courseId,
      classId,
      languageId,
    });

    return response;
  }
}
