const Navbar = {
  state: {
    isShowNavbar: true,
  },
  mutations: {
    setIsShowNavbar(state: Record<string, any>, payload: boolean) {
      state.isShowNavbar = payload;
    },
  },
  getters: {
    isShowNavbar: (state: Record<string, any>) => state.isShowNavbar,
  },
};

export default Navbar;
