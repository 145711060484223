


























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  filters: {
    formatText(text: string) {
      return text ? text.toUpperCase() : '';
    },
  },
})
export default class VideoInformation extends Vue {
  @Prop() video!: Record<string, any>;
}

