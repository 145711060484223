import axios from 'axios';
import merge from 'lodash/merge';
// eslint-disable-next-line import/no-cycle
import store from '@/store';

import cookieUtil from './Util/cookieUtil';

interface BaseClientConfig {
  url: string;
  host?: string;
  port?: number;
  protocol?: string;
}

class BaseClient {
  private config: BaseClientConfig;
  public axiosInstance: any;

  constructor(endpointConfig: BaseClientConfig, axiosConfig = {}) {
    this.config = endpointConfig;

    const headers = {
      'Content-Type': 'application/json',
    };

    this.axiosInstance = axios.create(
      merge(
        {
          baseURL: this.getUrl(),
          headers,
        },
        axiosConfig,
      ),
    );
  }

  getUrl() {
    if (!this.config.url) {
      this.config.url = `${this.config.protocol || 'https'}://${this.config.host
      }`;

      if (this.config.port) {
        this.config.url = `${this.config.url}:${this.config.port}`;
      }
    }

    return this.config.url;
  }

  getToken(): string {
    let token = '';
    token = store.getters.token || localStorage.getItem('token') || cookieUtil.getCookie('auth_token');
    return `Bearer ${token}`;
  }

  getBaseConfig(sendExtraHeader?: boolean) {
    const headers: any = {};

    if (this.getToken()) {
      headers.Authorization = this.getToken();
    }

    if (sendExtraHeader) {
      headers['exp-full-path'] = window?.location?.href;
    }

    return { headers };
  }

  get(url: string, params = {}, customConfig = {}) {
    const config = { ...this.getBaseConfig(), ...customConfig };

    return this.axiosInstance.get(
      url,
      { params, ...config },
    );
  }

  getBuffer(url: string, params = {}, customConfig = {}) {
    return this.axiosInstance.get(
      url,
      {
        params,
        responseType: 'arraybuffer',
        ...customConfig,
      },
    );
  }

  post(url: string, data = {}, customConfig = {}, sendExtraHeader = false) {
    const config = { ...this.getBaseConfig(sendExtraHeader), ...customConfig };

    return this.axiosInstance.post(url, data, config);
  }

  delete(url: string, data = {}, customConfig = {}) {
    const config = { ...this.getBaseConfig(), ...customConfig };

    return this.axiosInstance.delete(url, { data, ...config });
  }

  put(url: string, data = {}, customConfig = {}) {
    const config = { ...this.getBaseConfig(), ...customConfig };

    return this.axiosInstance.put(url, data, config);
  }

  patch(url: string, data = {}, customConfig = {}) {
    const config = { ...this.getBaseConfig(), ...customConfig };

    return this.axiosInstance.patch(url, data, config);
  }
}

export default BaseClient;
