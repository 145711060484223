















































import { Component, Prop, Vue } from 'vue-property-decorator';

const DEFAULT_COLOR = 'var(--neutralcolor-low-pure)';

@Component
export default class Joystick extends Vue {
  @Prop({ default: DEFAULT_COLOR }) fill!: string;
  @Prop({ default: '42' }) width!: string;
  @Prop({ default: '30' }) height!: string;
}
