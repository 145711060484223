import { foundPermission, validatePermissionDate } from './Permission';
import { ID_MATERIAL_CATEGORY_FOR_PERMISSION } from '@/constant/Materials';

function hasPermissionMaterials(material: Record<string, any>) {
  const listIdPermission = ID_MATERIAL_CATEGORY_FOR_PERMISSION[material.IDMaterialCategory];

  const hasPermissionIdMaterial = listIdPermission?.some((permissionName: string) => {
    const permission = foundPermission(permissionName);

    if (!permission) return false;

    return validatePermissionDate(permission);
  });

  return hasPermissionIdMaterial;
}

export {
  hasPermissionMaterials,
};
