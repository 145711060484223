import store from '@/store';
import SimulationService from '@/services/Simulation/SimulationService';

let URL = '';
const INDEX_SIMULATED_RESULT = 'result';
const ROUTE_SIMULATED_INSTRUCTION = 'SimulatedInstruction';
const MSG_ERRO_SIMULATED = 'Erro ao carregar simulado. Tente novamente em instantes.';
const NOT_FOUND_CHILDREN_SIMULATED = -1;
const INDEX_FIRST_SIMULATED_DAY = 0; // index da ordem que vem do backend
const INDEX_SECOND_SIMULATED_DAY = 1; // index da ordem que vem do backend
const ServiceSimulation = new SimulationService();

async function verifyChildrenSimulated(id: number) {
  try {
    const response = await ServiceSimulation.getChildrenSimulated(id);
    URL = response?.url;
    if (!response.children.length) return false;
    return response.children[0]?.title.toUpperCase().indexOf('DIA') === NOT_FOUND_CHILDREN_SIMULATED;

  } catch (error) {
    store.dispatch('Toast/setToast', {
      text: MSG_ERRO_SIMULATED,
      status: 'error',
    });
    return false;
  }
}

async function beforeEnterInstruction(to: any, from: any, next: any) {
  const hasChildren = await verifyChildrenSimulated(to.params.id);

  if (hasChildren) {
    next(
      {
        name: 'OtherSimulated',
        params: { id: to.params.id, url: URL },
      },
    );
    return;
  }

  try {
    const response = await ServiceSimulation.checkSimulated(Number(to.params.id));
    const daysCompleted = response?.days_completed;

    if (daysCompleted) {
      if (!daysCompleted.day1 && !daysCompleted.day2) {
        store.commit('saveIndexSimulatedDay', INDEX_FIRST_SIMULATED_DAY);
        next();
      } else if (daysCompleted.day1 && !daysCompleted.day2) {
        store.commit('saveIndexSimulatedDay', INDEX_SECOND_SIMULATED_DAY);
        next();
      } else if (!daysCompleted.day1 && daysCompleted.day2) {
        store.commit('saveIndexSimulatedDay', INDEX_SECOND_SIMULATED_DAY);
        next();
      } else if (daysCompleted.day1 && daysCompleted.day2) {
        next(
          {
            name: 'SimulatedResult',
            params: { id: to.params.id },
          },
        );
      }
    }
  } catch (error) {
    store.dispatch('Toast/setToast', {
      text: MSG_ERRO_SIMULATED,
      status: 'error',
    });

    next({
      name: 'Dashboard',
    });
  }
}

async function beforeEnterQuestions(to: any, from: any, next: any) {
  const hasChildren = await verifyChildrenSimulated(to.params.id);

  if (hasChildren) {
    next(
      {
        name: 'OtherSimulated',
        params: { id: to.params.id, url: URL },
      },
    );
    return;
  }

  try {
    const response = await ServiceSimulation.checkSimulated(Number(to.params.id));
    const daysCompleted = response?.days_completed;

    if (daysCompleted) {
      if (!daysCompleted.day1 && !daysCompleted.day2 && from.name === ROUTE_SIMULATED_INSTRUCTION) {
        store.commit('saveIndexSimulatedDay', INDEX_FIRST_SIMULATED_DAY);
        next();
        return;
      }

      if (daysCompleted.day1 && !daysCompleted.day2 && from.name === ROUTE_SIMULATED_INSTRUCTION) {
        store.commit('saveIndexSimulatedDay', INDEX_SECOND_SIMULATED_DAY);
        next();
        return;
      }

      if (from.name !== ROUTE_SIMULATED_INSTRUCTION) {
        next(
          {
            name: ROUTE_SIMULATED_INSTRUCTION,
            params: { id: to.params.id },
          },
        );
        return;
      }

      if (daysCompleted.day1 && daysCompleted.day2) {
        next(
          {
            name: 'SimulatedResult',
            params: { id: to.params.id },
          },
        );
      }
    }

  } catch (error) {
    store.commit('setSidebarExistence', true);

    store.dispatch('Toast/setToast', {
      text: MSG_ERRO_SIMULATED,
      status: 'error',
    });

    next({
      name: ROUTE_SIMULATED_INSTRUCTION,
      params: { id: to.params.id },
    });
  }
}

async function beforeEnterResult(to: any, from: any, next: any) {
  await ServiceSimulation.getChildrenSimulated(to.params.id);

  try {
    const response = await ServiceSimulation.checkSimulated(Number(to.params.id));
    const daysCompleted = response?.days_completed;

    if (daysCompleted) {
      if (daysCompleted.day1 && daysCompleted.day2) {
        store.commit('saveIndexSimulatedDay', INDEX_SIMULATED_RESULT);
        next();
      } else {
        next(
          {
            name: ROUTE_SIMULATED_INSTRUCTION,
            params: { id: to.params.id },
          },
        );
      }
    }
  } catch (error) {
    store.dispatch('Toast/setToast', {
      text: MSG_ERRO_SIMULATED,
      status: 'error',
    });

    next({
      name: 'Dashboard',
    });
  }
}

export default { beforeEnterInstruction, beforeEnterQuestions, beforeEnterResult };
