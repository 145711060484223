




























































import { Component, Vue } from 'vue-property-decorator';
import { format, parseISO } from 'date-fns';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import ListNewsLoading from './ListNewsLoading.vue';

import NewsService from '@/services/News/NewsService';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

@Component({
  components: {
    BoxContainer,
    FeedbackUser,
    ListNewsLoading,
  },
})
export default class ListNews extends Vue {
  private listNews: Array<Record<string, any>> = [];
  private show = false;
  private isLoading = true;

  private NewsService = new NewsService();

  async created() {
    await this.getNews();
  }

  async getNews() {
    try {
      this.isLoading = true;

      const posts = await this.NewsService.news();

      this.listNews = this.treatmentNews(posts?.data);
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar as notícias.',
        status: 'error',
      });
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  treatmentNews(listNews: Array<Record<string, any>>) {
    return listNews.map((news: Record<string, any>) => {
      let media = null;

      if (news?._embedded) {
        media = news?._embedded!['wp:featuredmedia'];
      }

      return {
        id: news.id,
        date: news.date,
        link: news.link,
        title: news.title?.rendered,
        description: news.excerpt?.rendered,
        thumb: media && media.length ? media[0]?.source_url : null,
      };
    });
  }

  getStyle(url: string) {
    return `background-image: url(${url})`;
  }

  getDate(data: string) {
    const parsedDate = parseISO(data);
    const formattedDate = format(parsedDate, "dd/MM/yyyy HH'h'mm");

    return formattedDate;
  }

  setTrackAmplitude(news: Record<string, any>) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'ListNews',
          local: 'Central de notícias',
          newsDescription: news?.description,
          newsTitle: news?.title,
        },
      },
    });
  }
}
