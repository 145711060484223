















import { Component, Prop, Vue } from 'vue-property-decorator';

const DEFAULT_FILL = '#212529';

@Component
export default class CreditCard extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;
  @Prop({ default: '22' }) width!: string;
  @Prop({ default: '20' }) height!: string;
}
