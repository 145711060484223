












import { Component, Prop, Vue } from 'vue-property-decorator';

const DEFAULT_FILL = 'var(--neutralcolor-high-pure)';

@Component
export default class HelpButtonIcon extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;
  @Prop({ default: '40' }) size!: string;
}
