import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { capitalize } from 'lodash';

const currentMonth = format(new Date(), 'MMMM', {
  locale: pt,
});

export const TABS = [
  {
    title: capitalize(currentMonth),
    id: '1',
    active: true,
  },
  {
    title: 'Geral',
    id: '2',
    active: false,
  },
];

export const TAB_NAME_GENERAL = 'Geral';
