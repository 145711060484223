










import { Component, Vue, Prop } from 'vue-property-decorator';

const CLASS_DEFAULT = 'col-12';

@Component
export default class ContainerFluid extends Vue {
  @Prop({ default: CLASS_DEFAULT }) customClass!: string;
}
