import { UsersOnline } from '@/globalInterfaces/Users';

const Profile = {
  state: {
    usersOnline: {
      users: [],
      total: 0,
    },
  },
  mutations: {
    setUsersOnline(state: Record<string, any>, payload: UsersOnline) {
      state.usersOnline = payload;
    },
  },
  getters: {
    usersOnline: (state: Record<string, any>) => state.usersOnline,
  },
};

export default Profile;
