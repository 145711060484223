import SimulationService from '@/services/Simulation/SimulationService';
import store from '@/store';

export default class SimulatedFeedbackStore {

  private SimulationService = new SimulationService();

  async getListSimulationIds() {
    const simulation = await this.SimulationService.getListSimulations();
    const ids = simulation.data.map((element: { id: number }) => element.id);
    return ids;
  }

  async setSimulatedContent() {
    const PERMISSION_SAVE_GET_DATA_STORE = false;
    const { listSimulatedStatusFeedback } = store.getters;

    if (!listSimulatedStatusFeedback.length) {
      const listIdsSimulated: [] = await this.getListSimulationIds();

      listIdsSimulated.forEach(async (id: number) => {
        const simulated = await this.SimulationService.getSimulated(id, PERMISSION_SAVE_GET_DATA_STORE);
        const secondDaySimulated = simulated.results;

        store.dispatch('saveListSimulatedStatusFeedback', {
          id,
          content: {
            firstDay: simulated.days_completed.day1,
            secondDay: simulated.days_completed.day2,
            redaction: simulated.days_completed.redaction_completed,
            secondDayInitDate: secondDaySimulated.length !== 0 ? simulated.results[1].init_date : '',
            secondDayresultReleaseDate: secondDaySimulated.length !== 0 ? simulated.results[1].post_result_release : '',
          },
        });
      });
    }
  }
}
