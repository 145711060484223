












































import { Component, Vue, Prop } from 'vue-property-decorator';

import VideoIcon from '@/components/IconStatus/Icons/VideoIcon.vue';

import VideoInformation from './components/VideoInformation/index.vue';

import iClose from '@/assets/icons/Close.vue';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

@Component({
  components: {
    iClose,
    VideoIcon,
    VideoInformation,
  },
})
export default class VideoThumb extends Vue {
  @Prop({ default: false }) isMyList!: boolean;
  @Prop({ default: true }) showVideoInformation!: boolean;
  @Prop() video!: Record<string, any>;
  @Prop() setRemoveVideoList!: Function;
  @Prop({ default: 'Últimas aulas assistidas' }) local!: string;

  get thumbVideo() {
    return this.video?.object?.thumb
      ? this.video.object.thumb
      : require('@/assets/imgs/thumb/thumb-default-video.png');
  }

  get videoPercent() {
    const DEFAULT_PERCENT = '0%';
    return this.video?.percent ? `${this.video.percent}%` : DEFAULT_PERCENT;
  }

  handleClickVideo() {
    this.setTrackAmplitudeThumb();

    this.$emit('click-video', this.video);
  }

  myListVideoRemove(video: Record<string, any>) {
    this.setTrackAmplitudeRemoveVideo();

    this.setRemoveVideoList(video);
  }

  setTrackAmplitudeThumb() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'VideoThumb',
          local: this.local,
          videoTitle: this.video?.object?.title,
          videoContext: this.video?.object?.context,
          videoPath: this.video?.object?.path,
        },
      },
    });
  }

  setTrackAmplitudeRemoveVideo() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'VideoThumb',
          local: 'Minha lista',
          removed: true,
          videoTitle: this.video?.object?.title,
          videoContext: this.video?.object?.context,
          videoPath: this.video?.object?.path,
        },
      },
    });
  }
}

