import { RouteConfig } from 'vue-router';

import { multiGuard } from '../Middleware/MultiGuard';

import { permissionBookFeature } from '../Middleware/permission/Book';
import { permissionCourse } from '../Middleware/permission/Course';
import { permissionPlannerContext } from '../Middleware/permission/Planner';
import { permissionExercise } from '../Middleware/permission/Exercise';
import { onboardingExercise } from '../Middleware/onboarding';

export const NAME_ROUTES_EXERCISE = {
  PERFORMANCE: 'ExerciseResultPerformance',
  RESULT: 'ExerciseResult',
  QUESTIONS: 'ExerciseQuestions',
  EXERCISE: 'exercicio',
  TUTORIAL: 'TutorialExercise',
};

export const Exercise: Array<RouteConfig> = [
  {
    path: '/exercicios/:context/:path/:topicID/:fileID/:slugFile/resultado/desempenho',
    name: NAME_ROUTES_EXERCISE.PERFORMANCE,
    component: () => import(/* webpackChunkName: "exercise-performance" */ '@/pages/Exercise/pages/ExerciseResultPerformance/ExerciseResultPerformance.vue'),
    meta: { title: 'Desempenho' },
    beforeEnter: multiGuard([
      permissionBookFeature,
      permissionCourse,
      permissionPlannerContext,
      permissionExercise,
    ]),
  },
  {
    path: '/exercicios/:context/:path/:topicID/:fileID/:slugFile/resultado',
    name: NAME_ROUTES_EXERCISE.RESULT,
    component: () => import(/* webpackChunkName: "exercise-result" */ '@/pages/Exercise/pages/ExerciseResult/ExerciseResult.vue'),
    meta: { title: 'Resultado' },
    beforeEnter: multiGuard([
      permissionBookFeature,
      permissionCourse,
      permissionPlannerContext,
      permissionExercise,
    ]),
  },
  {
    path: '/exercicios/:context/:path/:topicID/:fileID/:slugFile/questoes',
    name: NAME_ROUTES_EXERCISE.QUESTIONS,
    component: () => import(/* webpackChunkName: "exercise-question" */ '@/pages/Exercise/pages/ExerciseQuestions/ExerciseQuestions.vue'),
    meta: { title: 'Questões' },
    beforeEnter: multiGuard([
      permissionBookFeature,
      permissionCourse,
      permissionPlannerContext,
      permissionExercise,
    ]),
  },
  {
    path: '/exercicios/:context/:path/:topicID/:fileID/:slugFile',
    name: NAME_ROUTES_EXERCISE.EXERCISE,
    component: () => import(/* webpackChunkName: "exercise" */ '@/pages/Exercise/Exercise.vue'),
    meta: { title: 'Exercícios' },
    beforeEnter: multiGuard([
      permissionBookFeature,
      permissionCourse,
      permissionPlannerContext,
      onboardingExercise,
      permissionExercise,
    ]),
  },
  {
    path: '/exercicios/:context/:path/:topicID/:fileID/:slugFile/tutorial',
    name: NAME_ROUTES_EXERCISE.TUTORIAL,
    component: () => import(/* webpackChunkName: "exercise-tutorial" */ '@/pages/Exercise/pages/TutorialExercise/index.vue'),
    meta: { title: 'Tutorial Exercícios' },
  },
];
