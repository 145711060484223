export default class Bubble {

  private static instance: Bubble;
  private eventList: Record<string, any> = [];

  public static getInstance(): Bubble {
    if (!Bubble.instance) Bubble.instance = new Bubble();
    return Bubble.instance;
  }

  public emit(event: string, object: any = null) {
    this.eventList.forEach((data: any) => {
      if (data.event === event) {
        try {
          data.method(object);
        } catch (e) {
          console.error('Error in subscribe method');
        }
      }
    });
  }

  public subscribe(eventTitle: string, method: any) {
    this.eventList.push({ event: eventTitle, method });
  }

  public unsubscribe(eventTitle: string, method: any) {
    this.eventList = this.eventList.filter((data: any) => {
      const remove = { event: eventTitle, method };
      return (data.method.toString() !== remove.method.toString()) && (data.event !== remove.event);
    });
  }
}
