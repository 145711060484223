import { Component, Vue } from 'vue-property-decorator';

import store from '@/store';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';

import HistoricService from '@/services/Historic/historicService';
import { PostCreditsConsumed } from '@/services/Historic/historicInterface';

import { Credit } from '@/globalInterfaces/CreditsConsumer';
import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';

const DEFAULT_MAX_CREDITS_CONSUMED = 3;

@Component
export default class CreditsConsumer extends Vue {
  protected isLoadingCredits = true;
  protected isLoadingConsumed = false;

  protected NOT_CREDIT_VALUE = 0;

  created() {
    this.getCreditsConsumer();
  }

  private HistoricService = new HistoricService();

  get plan() {
    return store.getters.profile?.plan;
  }

  get isPlanFree() {
    return this.plan?.planID === LIST_PLAN_ID.FREE;
  }

  get creditData(): Credit {
    return store.getters.creditsConsumer;
  }

  get hasCredits() {
    return this.creditData?.isPermissionCreditsConsumable || false;
  }

  get getTotalCreditsConsumed() {
    if (!this.creditData?.isPermissionCreditsConsumable) return 0;

    return (this.getTotalCreditsConsumables - this.creditData.consumedCredits) || 0;
  }

  get getTotalCreditsConsumables() {
    return this.creditData?.credits_total || DEFAULT_MAX_CREDITS_CONSUMED;
  }

  async getCreditsConsumer() {
    try {
      if (!this.isPlanFree) return;
      if (this.creditData?.userID) return;

      this.isLoadingCredits = true;

      const creditData = await this.HistoricService.getCreditsConsumer();

      store.commit('setCreditsConsumerData', creditData);
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os créditos consumíveis',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingCredits = false;
    }
  }

  async postCreditsConsumer({ actionID, materialTypeID, materialID }: PostCreditsConsumed) {
    try {
      this.isLoadingConsumed = true;

      const creditData = await this.HistoricService.postCreditsConsumer({
        actionID,
        materialTypeID,
        materialID,
      });

      store.commit('setCreditsConsumerData', creditData);

      return true;
    } catch (error) {
      if (error?.response?.status === STATUS_RESPONSE_API.CLIENT_ERROR_BAD_REQUEST) {
        this.$store.dispatch('Toast/setToast', {
          text: 'Infelizmente você não possui mais créditos',
          status: 'error',
        });

        console.error(error);

        return false;
      }

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao salvar o consumo do crédito',
        status: 'error',
      });

      console.error(error);

      return false;
    } finally {
      this.isLoadingConsumed = false;
    }
  }
}
