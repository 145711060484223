import store from '@/store';
import AuthService from '@/services/Auth/AuthService';

import { assessBusinessModelForTheme } from '../Color/ConfigureColors';

export async function updateProfile() {
  const auth = new AuthService();
  const profile = await auth.getProfile();

  if (profile) await store.dispatch('setProfile', profile);

  assessBusinessModelForTheme(profile);

  return profile;
}
