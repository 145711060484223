export const MAINTENANCE_REF: IMaintenance = {
  production: 'site',
  development: 'site-staging',
  staging: 'site-staging',
  test: 'site-staging',
};

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IMaintenance {
  production: string;
  development: string;
  staging: string;
  test: string;
}
