const Course = {
  state: {
    courses: [],
    coursesWeeks: [],
    idCourse: Number,
    idClassCourse: Number,
    isLoadingChangedClass: false,
    dataModalChagendClass: {
      isSuccessfulChangedClass: true,
      componentNameModal: '',
    },
    listClassCourseSelect: [],
    courseClasses: {},
  },
  mutations: {
    setCourses(state: Record<string, any>, payload: Array<Record<string, any>>) {
      state.courses = payload;
    },
    setCourseWeeks(state: Record<string, any>, payload: Array<Record<string, any>>) {
      state.coursesWeeks = payload;
    },
    setIdCourse(state: Record<string, any>, payload: number) {
      state.idCourse = payload;
    },
    setIdClassCourse(state: Record<string, any>, payload: number) {
      state.idClassCourse = payload;
    },
    setIsLoadingChangedClass(state: Record<string, any>, payload: boolean) {
      state.isLoadingChangedClass = payload;
    },
    setDataModalChagendClass(state: Record<string, any>, payload: Record<string, any>) {
      state.dataModalChagendClass = payload;
    },
    setListClassCourseSelect(state: Record<string, any>, payload: Array<Record<string, any>>) {
      state.listClassCourseSelect = payload;
    },
    setCourseClasses(state: Record<string, any>, payload: Record<string, any>) {
      state.courseClasses = payload;
    },
  },
  getters: {
    courses: (state: Record<string, any>) => state.courses,
    coursesWeeks: (state: Record<string, any>) => state.coursesWeeks,
    idCourse: (state: Record<string, any>) => state.idCourse,
    idClassCourse: (state: Record<string, any>) => state.idClassCourse,
    isLoadingChangedClass: (state: Record<string, any>) => state.isLoadingChangedClass,
    dataModalChagendClass: (state: Record<string, any>) => state.dataModalChagendClass,
    listClassCourseSelect: (state: Record<string, any>) => state.listClassCourseSelect,
    courseClasses: (state: Record<string, any>) => state.courseClasses,
  },
};

export default Course;
