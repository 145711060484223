import merge from 'lodash/merge';
// eslint-disable-next-line import/no-cycle
import BaseClient from './BaseClient';

import { InstanceSentry } from '@/share/Util/Metrics/Metrics';

interface BaseClientConfig {
  url: string;
  host?: string;
  port?: number;
  protocol?: string;
}

class AppClient extends BaseClient {
  private Clients: any = {};

  constructor(endpointConfig: BaseClientConfig, axiosConfig?: Record<string, any>) {

    super(endpointConfig, merge(
      {
        timeout: 65000,
      },
      axiosConfig,
    ));

    this.axiosInstance.interceptors.response.use(
      (response: any) => response,
      (error: any) => {
        InstanceSentry.sentryCaptureException(error);
        return Promise.reject(error);
      },
    );

    this.Clients[endpointConfig.url] = this;
  }
}

export default AppClient;
