interface ActiveDescriptiveModal {
  id: number | null;
  title: string | null;
}

const Profile = {
  state: {
    activeDescriptiveModal: {
      id: null,
      title: null,
    },
    activePlanTypes: 12,
  },
  mutations: {
    setActiveDescriptiveModal(state: Record<string, any>, payload: ActiveDescriptiveModal) {
      state.activeDescriptiveModal = payload;
    },
    setActivePlanTypes(state: Record<string, any>, payload: number) {
      state.activePlanTypes = payload;
    },
  },
  getters: {
    activeDescriptiveModal: (state: Record<string, any>) => state.activeDescriptiveModal,
    activePlanTypes: (state: Record<string, any>) => state.activePlanTypes,
  },
};

export default Profile;
