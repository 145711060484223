

































import { Component, Vue, Prop } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

@Component({
  components: {
    Modal,
  },
  filters: {
    capitalize(value: string) {
      const indexFirstLetters = value.indexOf('>') + 1;
      return value.replace(value[indexFirstLetters], value[indexFirstLetters].toUpperCase());
    },
  },
})
export default class ModalRedactionRejected extends Vue {
  @Prop() title!: string;
  @Prop() message!: string;

}
