import { NavigationGuardNext, Route } from 'vue-router';

import Permission from './index';
import { IDS_FOR_PERMISSION_COURSE } from '@/constant/CoursesId';
import { LIST_NAME_CONTEXT } from '@/constant/Context';

export function permissionCourse(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const context = to.params?.context;
  const path = to.params?.path;

  const splitPath = path.split('&');
  const slugCourse = splitPath[0];
  const idCourses = splitPath[1];
  const idClass = splitPath[2];

  const PermissionClass = new Permission();

  if (context !== LIST_NAME_CONTEXT.COURSE) {
    next();
    return;
  }

  if (!PermissionClass.can(IDS_FOR_PERMISSION_COURSE[Number(idCourses)])) {
    next({
      name: 'Courses',
      params: {
        slugCourse,
        idCourses,
        idClass,
      },
    });
  }

  next();
}

export function permissionCoursePage(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const PermissionClass = new Permission();
  const idCourses = to.params?.idCourses || to.params?.id;

  const hasPermission = idCourses
    ? PermissionClass.can(IDS_FOR_PERMISSION_COURSE[Number(idCourses)])
    : Object.values(IDS_FOR_PERMISSION_COURSE).some((permission: string) => PermissionClass.can(permission));

  if (hasPermission) {
    next();
    return;
  }

  next({
    name: 'CoursesList',
  });
}
