import { RouteConfig } from 'vue-router';

export const NAME_ROUTES_HISTORIC = {
  PAYMENT: 'Ranking',
};

export const Historic: Array<RouteConfig> = [
  {
    path: '/historico',
    name: NAME_ROUTES_HISTORIC.PAYMENT,
    component: () => import(/* webpackChunkName: "HistoricPayment" */ '@/pages/HistoricPayment/HistoricPayment.vue'),
    meta: { title: 'Histórico de pagamentos' },
  },
];
