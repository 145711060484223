import store from '@/store';

export interface OnboardingInterface {
  ID: number;
  count: number;
  end_date: string | Date;
  isActive: number;
  name: string;
  start_date: string | Date;
  user_registry_date: string | Date;
  user_updated_date: string | Date;
}

export default class Onboarding {

  onboardingsStore() {
    return store.getters.profile?.tests;
  }

  alreadyViewedOnboarding(idOnboarding: number) {
    const onboardingFound = this.findOnboarding(idOnboarding);

    return !onboardingFound ? false : !!onboardingFound.count;
  }

  findOnboarding(idOnboarding: number) {
    const onboardings = this.onboardingsStore();

    return onboardings.find((onboarding: OnboardingInterface) => onboarding.ID === idOnboarding);
  }
}
