import { RouteConfig } from 'vue-router';

import { multiGuard } from '../Middleware/MultiGuard';

import { permissionBookFeature } from '../Middleware/permission/Book';

import { permissionPlanCoursePageForContext } from '../Middleware/PlanCourse';

import { permissionArchive } from '../Middleware/permission/Material';

export const NAME_ROUTES_MATERIAL = {
  SHOW: 'materiais',
};

export const Material: Array<RouteConfig> = [
  {
    path: '/materiais/:context/:path/:topicID/:fileID/:slugFile',
    name: NAME_ROUTES_MATERIAL.SHOW,
    component: () => import(/* webpackChunkName: "materials" */ '@/pages/Materials/Materials.vue'),
    meta: { title: 'Materiais' },
    beforeEnter: multiGuard([
      permissionBookFeature,
      permissionPlanCoursePageForContext,
      permissionArchive,
    ]),
  },
];
