import amplitude from 'amplitude-js';

import {
  InitParams,
  TrackrParams,
  PageviewParams,
  IdentifyParams,
} from './ITracker';

const NAME_INSTANCE = 'aluno-dashboard';
const ENVIRONMENT = process.env.VUE_APP_ENVIRONMENT;

export default class Amplitude {

  initialize({ apiKey, options }: InitParams) {
    try {
      if (process.env.NODE_ENV === 'production') {
        amplitude.getInstance(NAME_INSTANCE).init(apiKey, undefined, options);
      } else {
        console.log({
          local: 'Not started Amplitude.',
          apiKey,
          options,
        });
      }
    } catch (error) {
      // adicionar o report do erro.
      console.error(error);
    }
  }

  setTrack({ eventName, additionalAttrs }: TrackrParams) {
    try {
      if (process.env.NODE_ENV === 'production') {
        amplitude.getInstance(NAME_INSTANCE).logEvent(
          eventName,
          {
            url: window.location.href,
            date: new Date(),
            ...additionalAttrs,
            environment: ENVIRONMENT,
          },
        );
      } else {
        console.log({
          local: 'Not setTrack Amplitude.',
          eventName,
          additionalAttrs: {
            url: window.location.href,
            date: new Date(),
            ...additionalAttrs,
            environment: ENVIRONMENT,
          },
        });
      }
    } catch (error) {
      // adicionar o report do erro.
      console.error(error);
    }
  }

  setPageview({ page, title }: PageviewParams) {
    try {
      if (process.env.NODE_ENV === 'production') {
        amplitude.getInstance(NAME_INSTANCE).logEvent('page_view', {
          page,
          title,
          environment: ENVIRONMENT,
        });
      } else {
        console.log({
          local: 'Not setPageview Amplitude.',
          page,
          title,
          environment: ENVIRONMENT,
        });
      }
    } catch (error) {
      // adicionar o report do erro.
      console.error(error);
    }
  }

  identify({ user }: IdentifyParams) {
    try {
      if (process.env.NODE_ENV === 'production') {
        amplitude.getInstance(NAME_INSTANCE).setUserId(user.idstudent);
        amplitude.getInstance(NAME_INSTANCE).setUserProperties(user);
      } else {
        console.log({
          local: 'Not identify Amplitude.',
          userID: user.idstudent,
          userProperties: user,
        });
      }
    } catch (error) {
      // adicionar o report do erro.
      console.error(error);
    }
  }
}
