import { RouteConfig } from 'vue-router';

import { multiGuard } from '../Middleware/MultiGuard';

import {
  permissionRedaction,
  permissionRedactionCredits,
  verifyOptionsSendRedaction,
  permissionRedactionFeature,
} from '../Middleware/permission/Redaction';
import { permissionFeature } from '../Middleware/permission/Feature';
import { onboardingRedaction } from '../Middleware/onboarding';

export const NAME_ROUTES_REDACTION = {
  REDACTION: 'Redaction',
  TUTORIAL: 'TutorialRedaction',
  INSTRUCTION: 'RedactionInstruction',
  MY_REDACTION: 'MyRedactions',
  CORRECTION: 'CorrectionRedaction',
  SEND: 'RedactionSend',
  THOUSAND: 'NoteAThousandRedaction',
};

export const Redaction: Array<RouteConfig> = [
  {
    path: '/redacao',
    name: NAME_ROUTES_REDACTION.REDACTION,
    component: () => import(/* webpackChunkName: "redaction" */ '@/pages/Redaction/Redaction.vue'),
    meta: { title: 'Redação' },
    beforeEnter: multiGuard([
      // permissionFeature,
      permissionRedactionFeature,
      onboardingRedaction,
    ]),
  },
  {
    path: '/redacao/tutorial',
    name: NAME_ROUTES_REDACTION.TUTORIAL,
    component: () => import(/* webpackChunkName: "redaction-tutorial" */ '@/pages/Redaction/pages/TutorialRedaction/TutorialRedaction.vue'),
    meta: { title: 'Tutorial Redação' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionRedaction,
    ]),
  },
  {
    path: '/redacao/:themeId/instrucoes',
    name: NAME_ROUTES_REDACTION.INSTRUCTION,
    component: () => import(/* webpackChunkName: "redaction-instruction" */ '@/pages/Redaction/pages/InstructionRedaction/InstructionRedaction.vue'),
    meta: { title: 'Redação Instruções' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionRedactionCredits,
    ]),
  },
  {
    path: '/redacao/minhas/redacoes',
    name: NAME_ROUTES_REDACTION.MY_REDACTION,
    component: () => import(/* webpackChunkName: "redaction-me" */ '@/pages/Redaction/pages/MyRedactions/MyRedactions.vue'),
    meta: { title: 'Minhas Redações' },
    beforeEnter: multiGuard([
      permissionFeature,
    ]),
  },
  {
    path: '/redacao/:redactionId/correcao',
    name: NAME_ROUTES_REDACTION.CORRECTION,
    component: () => import(/* webpackChunkName: "redaction-correction" */ '@/pages/Redaction/pages/CorrectionRedaction/CorrectionRedaction.vue'),
    meta: { title: 'Correção Redação' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionRedactionCredits,
    ]),
  },
  {
    path: '/redacao/:themeId/enviar',
    name: NAME_ROUTES_REDACTION.SEND,
    component: () => import(/* webpackChunkName: "redaction-send" */ '@/pages/Redaction/pages/SendRedaction/SendRedaction.vue'),
    meta: { title: 'Redação Envio' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionRedactionCredits,
      verifyOptionsSendRedaction,
    ]),
  },
  {
    path: '/redacao/:redactionId/nota_mil',
    name: NAME_ROUTES_REDACTION.THOUSAND,
    component: () => import(/* webpackChunkName: "redaction-note" */ '@/pages/Redaction/pages/NoteAThousandRedaction/NoteAThousandRedaction.vue'),
    meta: { title: 'Redação Nota Mil' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionRedactionCredits,
    ]),
  },
];
