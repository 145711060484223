import { Component, Vue, Watch } from 'vue-property-decorator';

import { isAfter, parseISO } from 'date-fns';

import { NotificationData } from '@/globalInterfaces/Notifications';

import ActiveModal from '@/share/Util/ActiveModal';

const TYPE_LEVEL = 'level';

@Component
export default class ActiveModalRankingPass extends Vue {
  public showModalRankingPass = false;

  private modalInstance = new ActiveModal();

  get notifications(): NotificationData[] {
    return this.$store.getters.notifications;
  }

  @Watch('notifications', {
    immediate: true,
    deep: true,
  })
  setShowModal() {
    if (!this.notifications.length) {
      this.showModalRankingPass = false;
      return;
    }

    const notificationsTypeLevelFound = this.filterLevelTypeNotification(this.notifications);

    if (!notificationsTypeLevelFound.length) {
      this.showModalRankingPass = false;
      return;
    }

    if (notificationsTypeLevelFound.length === 1) {
      this.$store.commit('setNotificationDataModalRankingPass', notificationsTypeLevelFound[0]);

      this.showModalRankingPass = true;

      return;
    }

    const lastLevelNotification = this.getLatestLevelNotification(notificationsTypeLevelFound);

    this.$store.commit('setNotificationDataModalRankingPass', lastLevelNotification);

    this.showModalRankingPass = true;
  }

  @Watch('showModalRankingPass')
  setShowModalInstance() {
    if (this.showModalRankingPass) {
      this.modalInstance.activeModal('modalRankingPass');
      return;
    }

    this.modalInstance.disableModal('modalRankingPass');
  }

  filterLevelTypeNotification(notifications: NotificationData[]) {
    if (!notifications.length) return [];

    return notifications.filter((notification: NotificationData) => notification.type === TYPE_LEVEL);
  }

  getLatestLevelNotification(notifications: NotificationData[]) {
    if (!notifications.length) return {};

    return notifications.reduce((latest: NotificationData, current: NotificationData) => {
      const latestDate = parseISO(latest.createdDate);
      const currentDate = parseISO(current.createdDate);

      return isAfter(currentDate, latestDate) ? current : latest;
    });
  }
}
