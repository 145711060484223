


































































import {
  Component,
  Vue,
  Watch,
} from 'vue-property-decorator';

import { format, addMonths } from 'date-fns';
import { pt } from 'date-fns/locale';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import AvatarRanking from '@/components/AvatarRanking/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import RankingUser from '@/pages/Ranking/components/RankingUser/RankingUser.vue';

import RankingLoading from './RankingLoading.vue';
import Podium from './Podium/index.vue';
import Actions from './Actions/index.vue';
import UserPodium from './UserPodium/index.vue';
import ModalSharePodium from './ModalSharePodium/index.vue';

import Joystick from '@/assets/icons/ranking/Joystick.vue';

import { RankingMonth as IRankingMonth, RankingMonthLocal } from '@/globalInterfaces/Gamification';

import GamificationService from '@/services/Gamification/gamificationService';

@Component({
  components: {
    BoxContainer,
    AvatarRanking,
    RankingLoading,
    RankingUser,
    Joystick,
    FeedbackUser,
    Podium,
    Actions,
    UserPodium,
    ModalSharePodium,
  },
})
export default class RankingMonth extends Vue {
  private isLoadingRankingMonth = true;
  private showUserPodium = false;

  private rankingMonth: RankingMonthLocal[] = [];

  private GamificationService = new GamificationService();

  created() {
    this.getRankingMonth();
  }

  get previousMonth() {
    const month = addMonths(new Date(), -1);

    return format(month, 'MMMM', {
      locale: pt,
    });
  }

  get idStudent() {
    return this.$store.getters.profile.idstudent;
  }

  get userOnPodium() {
    return this.rankingMonth?.length ? this.rankingMonth.find((user) => user.user.ID === this.idStudent) : undefined;
  }

  @Watch('userOnPodium')
  setShowUserPodium() {
    this.showUserPodium = !!this.userOnPodium;
  }

  async getRankingMonth() {
    try {
      this.isLoadingRankingMonth = true;

      const ranking = await this.GamificationService.getUserRankingMonth();

      if (ranking?.length) {
        this.rankingMonth = this.setPositions(ranking);
      }

    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o ranking',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingRankingMonth = false;
    }
  }

  setPositions(ranking: IRankingMonth[]) {
    return [
      {
        ...ranking[1],
        position: 2,
      },
      {
        ...ranking[0],
        position: 1,
      },
      {
        ...ranking[2],
        position: 3,
      },
    ];
  }

  changeComponentRanking(showUserPodium: boolean) {
    this.showUserPodium = showUserPodium;
  }

  redirectPageRanking() {
    this.$router.push({
      name: 'Ranking',
    });
  }
}
