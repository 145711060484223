import { NavigationGuardNext, Route } from 'vue-router';
import Permission from './index';
import { LIST_ID_BOOK_QUESTION_FOR_PERMISSION } from '@/constant/ListIdsBookQuestion';

function hasPermission(idQuestion: number) {
  const PermissionClass = new Permission();

  return PermissionClass.can(LIST_ID_BOOK_QUESTION_FOR_PERMISSION[idQuestion]);
}

export function permissionPageExam(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const idExam = to?.params?.idExam;

  if (!hasPermission(Number(idExam))) {
    if (to.query && to.query.isFree) {
      next({
        name: 'Exams',
        query: {
          isFree: 'true',
        },
      });

      return;
    }

    next({
      name: 'Exams',
    });
  }

  next();
}
