import { foundPermission, validatePermissionDate } from './Permission';
import { ID_MATERIAL_CATEGORY_FOR_PERMISSION } from '@/constant/Exercises';

function hasPermissionExercises(exercise: Record<string, any>) {
  const listIdPermission = ID_MATERIAL_CATEGORY_FOR_PERMISSION[exercise.IDMaterialCategory];

  const hasPermissionIdExercise = listIdPermission?.some((permissionName: string) => {
    const permission = foundPermission(permissionName);

    if (!permission) return false;

    return validatePermissionDate(permission);
  });

  return hasPermissionIdExercise;
}

export {
  hasPermissionExercises,
};
