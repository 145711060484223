import { NavigationGuardNext, Route } from 'vue-router';

import Permission from './index';
import { LIST_PERMISSION } from '@/constant/ListPermission';

import store from '@/store';

export async function permissionRedactionCredits(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const PermissionClass = new Permission();

  const hasPermission = await PermissionClass.can_credits(LIST_PERMISSION.REDACTION_CREDITS || LIST_PERMISSION.REDACTION_FEATURE);

  if (!hasPermission) {
    if (to.query && to.query.isFree) {
      next({
        name: 'Redaction',
        query: {
          isFree: 'true',
        },
      });

      return;
    }

    next({
      name: 'Redaction',
    });
  }

  next();
}

export function permissionRedaction(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const PermissionClass = new Permission();

  const hasPermission = PermissionClass.can(LIST_PERMISSION.REDACTION_CREDITS || LIST_PERMISSION.REDACTION_FEATURE);

  if (!hasPermission) {
    next({
      name: 'Redaction',
    });
  }

  next();
}

export function verifyOptionsSendRedaction(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const component = store.getters.redactionSubmissionSettings.component || sessionStorage.getItem('componentSendRedaction');

  if (!component) {
    next({
      name: 'RedactionInstruction',
      params: {
        themeId: to.params.themeId,
      },
    });
  }

  next();
}

export function permissionRedactionFeature(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const PermissionClass = new Permission();

  const hasPermission = PermissionClass.can(LIST_PERMISSION.REDACTION_FEATURE);

  if (!hasPermission) {
    next({
      name: 'Dashboard',
    });
  }

  next();
}
