import { RouteConfig } from 'vue-router';

import { multiGuard } from '../Middleware/MultiGuard';

import { permissionBookFeature } from '../Middleware/permission/Book';
import { permissionCourse } from '../Middleware/permission/Course';
import { permissionPlannerContext } from '../Middleware/permission/Planner';
import { permissionVideo } from '../Middleware/permission/Videos';

import { onboardingVideos } from '../Middleware/onboarding';

import { redirectVideos } from '../Middleware/redirect/videos';

export const NAME_ROUTES_VIDEO = {
  VIDEO: 'videos',
  TUTORIAL: 'TutorialVideos',
  REDIRECT: 'VideoRedirect',
};

export const Video: Array<RouteConfig> = [
  {
    path: '/videos/:context/:path/:topicID/:fileID/:slugFile',
    name: NAME_ROUTES_VIDEO.VIDEO,
    component: () => import(/* webpackChunkName: "video" */ '@/pages/Video/Video.vue'),
    meta: { title: 'Vídeos' },
    beforeEnter: multiGuard([
      permissionBookFeature,
      permissionCourse,
      permissionPlannerContext,
      onboardingVideos,
      permissionVideo,
    ]),
  },
  {
    path: '/videos/:context/:path/:topicID/:fileID/:slugFile/tutorial',
    name: NAME_ROUTES_VIDEO.TUTORIAL,
    component: () => import(/* webpackChunkName: "videos-tutorial" */ '@/pages/Video/pages/TutorialVideos/index.vue'),
    meta: { title: 'Tutorial Vídeos' },
  },
  {
    path: '/videos/redirect',
    name: NAME_ROUTES_VIDEO.REDIRECT,
    beforeEnter: redirectVideos,
  },
];
