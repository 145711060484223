import { LIST_PERMISSION } from './ListPermission';

const ID_BOOK_GUIDE_ENEM = 1239;
const ID_BOOK_GUIDE_FUVEST = 1240;

const ID_BOOK_TEST_ENEM = 328;
const ID_BOOK_TEST_MILITARY = 1404;

const ID_BOOK_TEST_REGIONAL_NORTE = 349;
const ID_BOOK_TEST_REGIONAL_NORDESTE = 348;
const ID_BOOK_TEST_REGIONAL_SUL = 351;
const ID_BOOK_TEST_REGIONAL_SULDESTE = 350;
const ID_BOOK_TEST_REGIONAL_CENTRO = 352;

export const LIST_ID_BOOK_QUESTION_FOR_PERMISSION: Record<number, string> = {
  [ID_BOOK_GUIDE_ENEM]: LIST_PERMISSION.GUIDE_ENEM,
  [ID_BOOK_GUIDE_FUVEST]: LIST_PERMISSION.GUIDE_FUVEST,
  [ID_BOOK_TEST_ENEM]: LIST_PERMISSION.TESTS_ENEM,
  [ID_BOOK_TEST_MILITARY]: LIST_PERMISSION.TESTS_MILITARY,
  [ID_BOOK_TEST_REGIONAL_NORTE]: LIST_PERMISSION.TESTS_REGIONAL,
  [ID_BOOK_TEST_REGIONAL_NORDESTE]: LIST_PERMISSION.TESTS_REGIONAL,
  [ID_BOOK_TEST_REGIONAL_SUL]: LIST_PERMISSION.TESTS_REGIONAL,
  [ID_BOOK_TEST_REGIONAL_SULDESTE]: LIST_PERMISSION.TESTS_REGIONAL,
  [ID_BOOK_TEST_REGIONAL_CENTRO]: LIST_PERMISSION.TESTS_REGIONAL,
};
