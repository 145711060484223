import { NavigationGuardNext, Route } from 'vue-router';

import { hasPermissionExercises } from '@/share/Util/Permissions/exercises';

import { Exercise } from '@/globalInterfaces/Exercise';

import ExerciseService from '@/services/Exercise/ExerciseService';

const exerciseService = new ExerciseService();

async function getExercise(exerciseId: number) {
  let exercise: Exercise | null = null;

  try {
    exercise = await exerciseService.getExercise(exerciseId);
  } catch (error) {
    console.error(error);
  }

  return exercise;
}

export async function permissionExercise(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const { fileID } = to.params;

  const exercise = await getExercise(Number(fileID));

  const hasPermissionExercise = exercise ? hasPermissionExercises(exercise) : false;

  if (hasPermissionExercise) {
    next();
    return;
  }

  next({
    name: 'Dashboard',
  });

}
