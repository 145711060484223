





























import { Component, Vue, Prop } from 'vue-property-decorator';

import DiamondIcon from '@/assets/icons/Diamond.vue';
import CreditsIcon from '@/assets/icons/Credits.vue';
import InformationIcon from '@/assets/icons/Information.vue';

import ActiveModal from '@/share/Util/ActiveModal';

const NAME_CREDIT = 'crédito';
const NAME_CREDITS = 'créditos';

@Component({
  components: {
    DiamondIcon,
    CreditsIcon,
    InformationIcon,
  },
})
export default class Credits extends Vue {
  @Prop({ default: 0 }) credits!: number;

  private ActiveModal = new ActiveModal();

  get nameCredits() {
    return this.credits === 1 ? NAME_CREDIT : NAME_CREDITS;
  }

  handleRedirectStore() {
    this.$router.push({ name: 'Store' });
  }

  handleShowInformation() {
    this.ActiveModal.activeModal('ModalConsumerCredits');
  }
}

