import AppClient from '../AppClient';

export default class NotificationsService {
  private basePath = process.env.VUE_APP_NOTIFICATION;
  private Client = new AppClient({ url: this.basePath });

  async getNotificationsUnread() {
    const URI = '/notifications';
    const response = await this.Client.get(URI);
    return response?.data;
  }

  async getNotificationsReaded() {
    const URI = '/notifications/readed';
    const response = await this.Client.get(URI);

    return response?.data;
  }

  async updateNotifications() {
    const URI = '/notifications/read';
    const response = await this.Client.post(URI);

    return response?.data;
  }
}
