













import { Component, Prop, Vue } from 'vue-property-decorator';

const COLOR_DEFAULT = '#C4C4C4';

@Component
export default class Lock extends Vue {
  @Prop({ default: COLOR_DEFAULT }) fill!: string;

}
