import { RouteConfig } from 'vue-router';

import { multiGuard } from '../Middleware/MultiGuard';

import { permissionQuestion } from '../Middleware/permission/Question';

import {
  onboardingQuestions,
} from '../Middleware/onboarding/index';

export const NAME_ROUTES_QUESTIONS = {
  LIST: 'ListQuestions',
  TUTORIAL: 'TutorialQuestions',
  ANSWER: 'AnswerQuestion',
};

export const Questions: Array<RouteConfig> = [
  {
    path: '/questoes',
    name: NAME_ROUTES_QUESTIONS.LIST,
    component: () => import(/* webpackChunkName: "questions" */ '@/pages/Question/Question.vue'),
    meta: { title: 'Lista de Questões' },
    beforeEnter: multiGuard([
      onboardingQuestions,
    ]),
  },
  {
    path: '/questoes/tutorial',
    name: NAME_ROUTES_QUESTIONS.TUTORIAL,
    component: () => import(/* webpackChunkName: "questions" */ '@/pages/Question/pages/TutorialQuestions/TutorialQuestions.vue'),
    meta: { title: 'Tutorial Questões' },
  },
  {
    path: '/questao/:id',
    name: NAME_ROUTES_QUESTIONS.ANSWER,
    component: () => import(/* webpackChunkName: "questions" */ '@/pages/Question/pages/AnswerQuestion/AnswerQuestion.vue'),
    meta: { title: 'Responder Questão' },
    beforeEnter: permissionQuestion,
  },
];
