import AppClient from '../AppClient';

import { LoginInterface } from './AccountInterface';

export default class AuthService {
  private basePath = process.env.VUE_APP_ACCOUNT_API;

  private Client = new AppClient({ url: this.basePath });

  async validate() {
    const URI = '/validate';

    const response = await this.Client.post(URI);

    return response?.data;
  }

  async login(loginData: LoginInterface) {
    const URI = '/login';

    const response = await this.Client.post(URI, loginData);

    return response;
  }
}
