























































import {
  Component,
  Mixins,
  Watch,
  Prop,
} from 'vue-property-decorator';

import Books from '@/components/Book/index.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import { TabsMenuInterface } from '@/components/Tabs/Tabs.vue';
import ContainerVideos from '@/components/ContainerVideos/ContainerVideos.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import Ranking from '@/components/Ranking/index.vue';
import RankingMonth from '@/components/RankingMonth/index.vue';

import ContainerRedaction from '../ContainerRedaction/ContainerRedaction.vue';
import ListBooks from '../ListBooks/index.vue';

import Paragraph from '@/widgets/Util/Paragraph/Paragraph.vue';
import Search from '@/widgets/Util/Search/index.vue';
import SaleOfProducts from '@/widgets/Products/SaleOfProducts/SaleOfProducts.vue';
import ListNews from '@/widgets/News/ListNews/ListNews.vue';
import RealtimeUsers from '@/widgets/Users/RealtimeUsers/RealtimeUsers.vue';

import SimulationService from '@/services/Simulation/SimulationService';

import generalBooks from '@/mixins/generalBooks';
import searchBook from '@/mixins/searchBook';
import SimulatedFeedbackStatus from '@/mixins/SimulatedFeedbackStatus';
import SetSaleOfProducts from '@/mixins/SetSaleOfProduts';

import { TAB_MENU_SALES, SLUGS } from '../../Constants';
import { LIST_PERMISSION } from '@/constant/ListPermission';
import { SIMULATED_COVERS } from '@/constant/SimulatedCovers';
import { LIST_PLAN_ID } from '@/constant/PlansIDs';

import { hasPermissionSimulatedByPermissionId } from '@/share/Util/Permissions/simulated';
import { orderList } from '@/share/Util/UsefulFunctions/UserfulFunctions';

import { NewCourse } from '@/globalInterfaces/Course';
import { ProductData } from '@/globalInterfaces/ProductCard';
import { Book as IBook, Degree } from '@/globalInterfaces/Book';

const ORDER_SIMULATED = {
  available: 1,
  notAvailable: 2,
};

interface ListBook {
  id: string;
  books: Array<Record<string, any>>;
}

@Component({
  components: {
    ListBooks,
    ContainerFluid,
    Search,
    ContainerRedaction,
    ContainerVideos,
    SaleOfProducts,
    Paragraph,
    RealtimeUsers,
    ListNews,
    BoxContainer,
    Books,
    Ranking,
    RankingMonth,
  },
  mixins: [SimulatedFeedbackStatus],
})
export default class Dashboard extends Mixins(searchBook, generalBooks, SetSaleOfProducts) {
  @Prop() listOfAllowedCourses!: Array<NewCourse>;
  @Prop() hasPermissionCourses!: boolean;
  @Prop() isHighSchool!: boolean;

  private showComponentRedaction = true;

  private tabMenuBooks: TabsMenuInterface[] | Record<string, any>[] = [];
  private tabMenuSales: Array<TabsMenuInterface> = [];

  private listAllTabBooks: Array<ListBook> = [];

  private permissionsTab: Record<string, any> = {};
  private listCloseTab: Record<string, any> = {};

  private currentTabBookList: ListBook | null = null;

  private selectItem = SLUGS.BOOKS_MEDIUM;

  private isLoadingBooks = true;
  private isLoadingCourses = true;
  private isLoadingSimulated = true;
  private isLoadingTabs = true;

  private SimulationService = new SimulationService();

  async created() {
    await this.getSimulated();
    await this.setBookTabs();
    this.getBooksCurrentTab();
  }

  get listBooksTab() {
    return this.currentTabBookList ? this.currentTabBookList.books : [];
  }

  get isShowBooks() {
    return this.selectItem === SLUGS.BOOKS_MEDIUM && !this.isLoadingBooks;
  }

  get isShowCourses() {
    return this.selectItem === SLUGS.COURSES;
  }

  get isShowSimulated() {
    return this.selectItem === SLUGS.SIMULATIONS && !this.isLoadingSimulated;
  }

  get showSaleProducts() {
    return this.can(LIST_PERMISSION.PHYSICAL_PRODUCT) && (this.currentTabProductList && this.tabMenuSales.length);
  }

  get plan() {
    return this.$store.getters.profile?.plan;
  }

  get isPlanFree() {
    return this.plan?.planID === LIST_PLAN_ID.FREE;
  }

  async getDegreeBooks(degreeID: number, slug: string) {
    try {
      this.isLoadingBooks = true;

      const books = await this.BookService.books(
        [degreeID],
        this.$store.getters.profile?.institution?.id,
      );

      if (books && books.length) {
        const newBooks = this.setBooks(books);

        const objectDegreeBooks = {
          id: slug,
          books: newBooks,
        };

        this.listAllTabBooks = [
          ...this.listAllTabBooks,
          objectDegreeBooks,
        ];
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os cadernos.',
        status: 'error',
      });
      console.error(error);

    } finally {
      this.isLoadingBooks = false;
      this.sliderReload();
    }
  }

  async getTabsDegree() {
    let tabsDegree: TabsMenuInterface[] = [];

    try {
      this.isLoadingTabs = true;

      const tabs = await this.BookService.getDegrees();

      tabsDegree = this.setTabsDegree(tabs);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingTabs = false;
    }

    return tabsDegree;
  }

  setTabsDegree(tabs: Degree[]) {
    return tabs.map((tab: Degree) => (
      {
        title: tab?.name,
        slug: tab?.name,
        active: false,
        order: tab.order,
        degreeID: tab.ID,
      }));
  }

  @Watch('isPlanFree', {
    immediate: true,
  })
  setListCloseTabs() {
    if (!this.isPlanFree) return;

    this.listCloseTab = {
      [SLUGS.BOOKS_MEDIUM]: false,
      [SLUGS.BOOKS_FUNDAMENTAL_II]: false,
      [SLUGS.BOOKS_FUNDAMENTAL_I]: false,
      [SLUGS.SIMULATIONS]: false,
    };
  }

  setBooks(books: Array<IBook>) {
    return books.map((data: IBook) => ({
      ...data,
      id: data.idBooks,
      colorBook: data.color,
      cover: data.thumb,
      slug: data.slug,
      link: `/caderno/${data.slug}`,
      type: 'books',
      title: data.title,
      disable: false,
    }));
  }

  async getBooksCurrentTab() {
    try {
      const tabActive = this.findActiveTab();

      if (tabActive && tabActive?.slug) {
        this.currentTabBookList = await this.getBooksFromTab(tabActive.slug, tabActive?.degreeID);
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os cadernos.',
        status: 'error',
      });

      console.error(error);
    }
  }

  findActiveTab() {
    return this.tabMenuBooks.find((tab: TabsMenuInterface | Record<string, any>) => tab.active);
  }

  @Watch('listOfAllowedCourses')
  setBooksCourse() {
    const objectBook = {
      id: SLUGS.COURSES,
      books: this.listOfAllowedCourses,
    };

    this.listAllTabBooks.push(objectBook);
  }

  async getSimulated() {
    if (!this.isHighSchool) return;

    try {
      this.isLoadingSimulated = true;
      const simulation = await this.SimulationService.getListSimulations();

      if (simulation?.data) {
        const newListSimulation = this.setSimulated(simulation.data);

        const objectBook = {
          id: SLUGS.SIMULATIONS,
          books: orderList(newListSimulation, 'order'),
        };

        this.listAllTabBooks.push(objectBook);
      }

    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os simulados.',
        status: 'error',
      });
      console.error(error);
    } finally {
      this.isLoadingSimulated = false;
      this.sliderReload();
    }
  }

  setSimulated(simulated: Array<Record<string, any>>) {
    return simulated.map((simulation: Record<string, any>) => ({
      id: simulation.id,
      colorBook: simulation.color,
      cover: SIMULATED_COVERS[Number(simulation.id)],
      slug: simulation.url,
      link: `/simulado/${simulation.id}`,
      type: 'simulation',
      title: simulation.title,
      disable: false,
      order: ORDER_SIMULATED.available,
      // disable: !hasPermissionSimulatedByPermissionId(simulation.id),
      // order: !hasPermissionSimulated(simulation.id) ? ORDER_SIMULATED.notAvailable : ORDER_SIMULATED.available,
    }));
  }

  @Watch('hasPermissionCourses')
  async setBookTabs() {
    const tabsDegree = await this.getTabsDegree();

    let tabs: TabsMenuInterface[] | Record<string, any>[] = [...tabsDegree];

    tabs = this.removeElementInBookTabs(tabs, SLUGS.REDACTION);

    let sortedTabs: TabsMenuInterface[] | Record<string, any>[] = orderList(tabs, 'order');

    if (!this.hasPermissionCourses || !this.isHighSchool) {
      sortedTabs = this.removeElementInBookTabs(sortedTabs, SLUGS.COURSES);
    }

    if (!this.can(LIST_PERMISSION.BOOKS_DEGREE_ELEMENTARY_SCHOOL_II)) {
      sortedTabs = this.removeElementInBookTabs(sortedTabs, SLUGS.BOOKS_FUNDAMENTAL_II);
    }

    if (!this.can(LIST_PERMISSION.BOOKS_DEGREE_HIGH_SCHOOL)) {
      sortedTabs = this.removeElementInBookTabs(sortedTabs, SLUGS.BOOKS_MEDIUM);
    }

    if (!this.can(LIST_PERMISSION.BOOKS_DEGREE_SAEB)) {
      sortedTabs = this.removeElementInBookTabs(sortedTabs, SLUGS.BOOKS_SAEB);
    }

    if (!this.isHighSchool) {
      sortedTabs = this.removeElementInBookTabs(sortedTabs, SLUGS.SIMULATIONS);
    }

    this.tabMenuBooks = this.setTabActive(sortedTabs);
  }

  setTabActive(tabs: Record<string, any>[]) {
    return tabs.map((tab: Record<string, any>, index: number) => ({ ...tab, active: (index === 0) }));
  }

  sliderReload() {
    if (this.isLoadingSimulated || this.isLoadingCourses || this.isLoadingBooks) return;
    this.$nextTick(() => {
      const slider: any = this.$refs[this.selectItem];
      if (slider) slider.reInit();
    });
  }

  @Watch('listOfAllowedCourses')
  setCoursesForSale() {
    this.filterCoursesForSaleWithoutAccess(this.listOfAllowedCourses);
    this.setTabSales();

    if (!this.listOfAllowedCourses.length) {
      const SLUG_COURSE = 'courses';
      this.tabMenuBooks = this.removeElementInBookTabs(this.tabMenuBooks, SLUG_COURSE);
    }
  }

  @Watch('listAllProductsForSale', {
    immediate: true,
    deep: true,
  })
  setTabSales() {
    if (this.listAllProductsForSale.length) {
      this.tabMenuSales = TAB_MENU_SALES.filter((tab: TabsMenuInterface) => this.listAllProductsForSale.findIndex((product: ProductData) => product.id === tab.slug) !== -1);

      this.tabMenuSales[0].active = true;
    }
  }

  removeElementInBookTabs(tabs: TabsMenuInterface[] | Record<string, any>[], elementSlug: string) {
    return tabs.filter((tab: TabsMenuInterface | Record<string, any>) => tab.slug !== elementSlug);
  }

  async changeCurrentListBook(slugTabSelected: string, degreeID: number) {
    if (slugTabSelected === this.currentTabBookList?.id) return;

    const selectedTabBook = await this.getBooksFromTab(slugTabSelected, degreeID);

    this.currentTabBookList = selectedTabBook;
  }

  async getBooksFromTab(slug: string, degreeID?: number) {
    const booksFoundInListing = this.findBooksInList(slug);

    if (booksFoundInListing) return booksFoundInListing;

    if (degreeID !== undefined) {
      await this.getDegreeBooks(degreeID, slug);

      const degreeBooks = this.findBooksInList(slug);

      return degreeBooks || null;
    }

    return null;
  }

  findBooksInList(slug: string) {
    return this.listAllTabBooks.length
      ? this.listAllTabBooks.find((book: ListBook) => book.id === slug)
      : null;
  }

  setShowComponentRedaction(value: boolean) {
    this.showComponentRedaction = value;
  }
}
