import { RouteConfig } from 'vue-router';

export const NAME_ROUTES_RANKING = {
  RANKING: 'Ranking',
};

export const Ranking: Array<RouteConfig> = [
  {
    path: '/ranking',
    name: NAME_ROUTES_RANKING.RANKING,
    component: () => import(/* webpackChunkName: "Ranking" */ '@/pages/Ranking/Ranking.vue'),
    meta: { title: 'Ranking' },
  },
];
