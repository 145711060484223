






































import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';

import Tabs, { TabsMenuInterface } from '@/components/Tabs/Tabs.vue';
import ExSlick from '@/components/Slick/index.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import CardProduct from '@/components/CardProduct/CardProduct.vue';
import Loading from '@/components/Loading/Loading.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';

import { ProductCard } from '@/globalInterfaces/ProductCard';

const TITLE_DEFAULT = 'Outros produtos que possam lhe interessar';

@Component({
  components: {
    Tabs,
    ExSlick,
    BoxContainer,
    CardProduct,
    ContainerFluid,
    Loading,
    FeedbackUser,
  },
})
export default class SaleOfProducts extends Vue {
  @Prop({ default: TITLE_DEFAULT }) titleBox!: string;
  @Prop() cardData!: Array<ProductCard>;
  @Prop() tabMenu!: Array<TabsMenuInterface>;

  private updatedTabMenu: Array<TabsMenuInterface> = this.tabMenu;

  get tabNameCurrent() {
    return this.updatedTabMenu.find((tab: TabsMenuInterface) => tab.active)?.slug || '';
  }

  tabsClick(indexTabSelected: number, tabMenu: Array<TabsMenuInterface>) {
    if (tabMenu.length) {
      this.$emit('change-tab', tabMenu[indexTabSelected].slug);
      this.updatedTabMenu = tabMenu;
    }
  }

  handleClickBuyProduct(cardProductData: ProductCard) {
    this.$emit('click-buy-product', cardProductData);
  }

}
