export const LIST_PERMISSION = {
  PLANNER: 'planner',
  DOUBTS: 'doubts',
  DOUBTS_CREDITS: 'doubts_',
  REDACTION_CREDITS: 'redaction_',
  COURSE_ENEM_EXTENSIVE: 'course_enem_extensive',
  COURSE_SP_EXTENSIVE: 'course_sp_extensive',
  COURSE_MED_EXTENSIVE: 'course_med_extensive',
  COURSE_ENEM_INTENSIVE: 'course_enem_intensive',
  COURSE_SP_INTENSIVE: 'course_sp_intensive',
  COURSE_MED_INTENSIVE: 'course_med_intensive',
  VIDEAULAS_THEORETICAL: 'videaulas_theoretical',
  VIDEAULAS_GUIDEDQUESTIONS: 'videaulas_guidedquestions',
  VIDEAULAS_REVIEWS: 'videaulas_reviews',
  VIDEAULAS_SOLVE_ENEM: 'videaulas_solve_enem',
  VIDEAULAS_SOLVE_VESTIBULAR: 'videaulas_solve_vestibular',
  VIDEAULAS_SOLVE_MEDICINE: 'videaulas_solve_medicine',
  VIDEAULAS_SOLVE_FUVEST: 'videaulas_solve_fuvest',
  HANDOUT_THEORETICAL: 'handout_theoretical',
  HANDOUT_ENEM: 'handout_enem',
  HANDOUT_ENCCEJA: 'handout_encceja',
  HANDOUT_REVIEWS: 'handout_reviews',
  HANDOUT_FUVEST: 'handout_fuvest',
  HANDOUT_VESTIBULAR: 'handout_vestibular',
  HANDOUT_MEDICINE: 'handout_medicine',
  HANDOUT_SUMMARY: 'handout_summary',
  HANDOUT_TEST: 'handout_test',
  HANDOUT_ANSWER: 'handout_answers',
  PROJECT_BASICMATH: 'project_basicmath',
  PROJECT_PHYSICALEDUCATION: 'project_physicaleducation',
  PROJECT_NEWSREEL: 'project_newsreel',
  PROJECT_GUIDEVESTIBULAR: 'project_guidevestibular',
  PROJECT_EMOTIONALDEVELOPMENT: 'project_emotionaldevelopment',
  EXERCISES_GUIDEDQUESTIONS: 'exercises_guidedquestions',
  EXERCISES_ENCCEJA: 'exercises_encceja',
  EXERCISES_ENEM: 'exercises_enem',
  EXERCISES_REVIEWS: 'exercises_reviews',
  EXERCISES_FUVEST: 'exercises_fuvest',
  EXERCISES_VESTIBULAR: 'exercises_vestibular',
  EXERCISES_MEDICINE: 'exercises_medicine',
  SIMULATIONS_FREE: 'simulations_free',
  SIMULATIONS_PREVIOUS: 'simulations_previous',
  SIMULATIONS_EXCLUSIVEENEM: 'simulations_exclusiveenem',
  SIMULATIONS_COURSES: 'simulations_courses',
  GUIDE_ENEM: 'guide_enem',
  GUIDE_FUVEST: 'guide_fuvest',
  TESTS_ENEM: 'tests_enem',
  TESTS_REGIONAL: 'tests_regional',
  TESTS_MILITARY: 'tests_military',
  COURSE_ENCCEJA_EXTENSIVE: 'course_encceja_extensive',
  COURSE_ENCCEJA_INTENSIVE: 'course_encceja_intensive',
  COURSE_REINFORCEMENT_EXTENSIVE: 'course_reinforcement_extensive',
  COURSE_REINFORCEMENT_INTENSIVE: 'course_reinforcement_intensive',
  COURSE_HALF_YEAR: 'course_half_year',
  COURSE_END_YEAR: 'course_end_year',
  COURSE_60_DAYS_FOR_THE_ENEM: 'course_60_days_for_the_enem',
  COURSE_FINAL_REVIEW: 'final_review_course',
  COURSE_VEST_MAPA: 'course_vestmapa',
  MATERIALS_DOWNLOAD: 'download_handout',
  ALL_MATERIALS_REDACTION: 'handout_redaction',
  PHYSICAL_PRODUCT: 'physical_products',
  DOUBTS_FEATURE: 'show_doubts',
  REDACTION_FEATURE: 'show_redaction',
  BOOKS_DEGREE_ELEMENTARY_SCHOOL_II: 'elementary_school_degree_1',
  BOOKS_DEGREE_HIGH_SCHOOL: 'high_school_degree_2',
  BOOKS_DEGREE_SAEB: 'saeb',
  ADS: 'show_ads',
  CREDITS_VIDEO: 'videoaulas',
  CREDITS_QUESTION: 'questions',
};
