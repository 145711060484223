
















import {
  Component, Vue, Prop,
} from 'vue-property-decorator';

@Component({})
export default class ProgressBar extends Vue {
@Prop() setProgressStyle!: Record<string, any>;
@Prop() title!: string;
}
