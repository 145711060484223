const RenewPlan = {
  state: {
    payment: null,
  },
  mutations: {
    setRenewPlanPayment(state: Record<string, any>, payload: Record<string, any>) {
      state.payment = payload;
    },
  },
  getters: {
    renewPayment: (state: Record<string, any>) => state.payment,
  },
};

export default RenewPlan;
