export const ALL_OPTIONS_ID = -1;

export const LIST_OPTIONS = [
  {
    value: 42,
    title: 'Espanhol',
  },
  {
    value: 41,
    title: 'Inglês',
  },
  {
    value: ALL_OPTIONS_ID,
    title: 'Espanhol e Inglês',
  },
];
