










import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';

const DEFAULT_TEXT = 'Conteúdo exclusivo para alunos premium';

@Component
export default class Tooltip extends Vue {
  @Prop({ default: DEFAULT_TEXT }) text!: string;
  @Prop({ required: true }) width!: string;
  @Prop({ required: true }) height!: string;
  @Prop({ required: true }) left!: string;
  @Prop({ required: true }) top!: string;
  @Prop({ default: false }) upArrow!: boolean;

  private styleTooltip = {
    width: this.width,
    left: this.left,
    top: this.top,
    height: this.height,
  }
}
