import { LIST_PERMISSION } from './ListPermission';

export const ID_SIMULATED_PREVIOUS = 9;
export const ID_SIMULATED_REVISION = 294;
export const ID_SIMULATED_COURSE = 293;
export const ID_SIMULATED_EXP_FREE = 192;

export const IDS_FOR_PERMISSION_SIMULATED: Record<number, Array<string>> = {
  [ID_SIMULATED_PREVIOUS]: [LIST_PERMISSION.SIMULATIONS_PREVIOUS],
  [ID_SIMULATED_REVISION]: [LIST_PERMISSION.SIMULATIONS_EXCLUSIVEENEM],
  [ID_SIMULATED_COURSE]: [LIST_PERMISSION.SIMULATIONS_COURSES],
  [ID_SIMULATED_EXP_FREE]: [LIST_PERMISSION.SIMULATIONS_FREE],
};

export const LIST_PERMISSION_SIMULATED = [
  LIST_PERMISSION.SIMULATIONS_PREVIOUS,
  LIST_PERMISSION.SIMULATIONS_EXCLUSIVEENEM,
  LIST_PERMISSION.SIMULATIONS_COURSES,
  LIST_PERMISSION.SIMULATIONS_FREE,

];

export const SIMULADO_REVISAO = 'simulado-revisao';
export const SIMULADO_VESTIBULARES_ANTERIORES = 'vestibulares-anteriores';
export const SIMULADO_CURSOS = 'simulados-dos-cursos';
export const SIMULADO_EXP_FREE = 'exp-free';
