import { Component, Vue } from 'vue-property-decorator';

import router from '@/router';
import { NewCourse, CoursePreference } from '@/globalInterfaces/Course';

@Component
export default class AccessCourses extends Vue {
  checkCourseAccess(idCourse: number) {
    let firstAccess = true;

    const course = this.findIdClassInStore(idCourse);

    if (course && course.length) firstAccess = false;

    return firstAccess;
  }

  verifyFirstAccessCourse(courseData: NewCourse) {
    if (courseData) {
      if (courseData.firstAccess) {
        this.openModal(courseData.id);
        return;
      }

      this.goToCourse(courseData);
    }
  }

  openModal(idCourse: number) {
    this.$Bubble.emit('modalCourse', { idCourse });
  }

  goToCourse(courseData: NewCourse) {
    const idCourse = courseData.id || courseData.idCourses;
    const { classId } = this.findIdClassInStore(idCourse)[0];

    router.push({
      name: 'Courses',
      params: {
        slugCourse: courseData.slug,
        idCourses: `${idCourse}`,
        idClass: `${classId}`,
      },
    });
  }

  findIdClassInStore(idCourse: number) {
    const { profile } = this.$store.getters;
    const preferenceCourse = profile?.preferences?.courses;
    let course = null;

    if (preferenceCourse && preferenceCourse.length) {
      course = preferenceCourse.filter((courseClass: CoursePreference) => courseClass.courseId === idCourse && courseClass?.classId);
    }

    return course;
  }
}
