export const LIST_ONBOARDING_ID = {
  REDACTION: 3,
  COURSE: 4,
  FIRST_ACCESS_PLAN_TRIAL: 5,
  EXP_FREE: 6,
  FIRST_ACCESS: 7,
  PERMISSION_TIMEOUT: 8,
  EXERCISES: 9,
  DOUBTS: 10,
  BOOKS: 11,
  BOOKS_VIDEOS: 12,
  BOOKS_EXERCISES: 13,
  SIMULATED: 14,
  EXAM: 15,
  PLANNER: 16,
};
