import store from '@/store';

import CookieUtil from '@/services/Util/cookieUtil';

interface Logout {
  isAnonymousPage?: boolean;
}

export const logout = ({ isAnonymousPage }: Logout) => {
  store.commit('setToken', '');
  const cameFromStore = window.location.pathname.includes('loja');

  const DEFAULT_URL = `https://acesso.explicae.com.br/${cameFromStore ? 'cadastro' : 'login'}`;
  const institutionURL = store.getters.profile.institution?.url;

  let url = window.location.href;
  url = url.replace(window.location.pathname, '');

  localStorage.removeItem('ex-simulated');

  CookieUtil.removeAccess();
  if (isAnonymousPage) return;

  if (!institutionURL) {
    window.location.href = `${DEFAULT_URL}/?url=${encodeURI(
      // eslint-disable-next-line comma-dangle
      url
    )}`;
  } else {
    window.location.href = `${institutionURL}/?url=${encodeURI(
      // eslint-disable-next-line comma-dangle
      url
    )}`;
  }
};
