































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import VueCropper from 'vue-cropperjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';

import Modal from '@/globalComponents/Modal/Modal.vue';
import Loading from '@/components/Loading/Loading.vue';

import ActiveModal from '@/share/Util/ActiveModal';

const ROTATE_DEGREE = 90;

@Component({
  components: {
    Modal,
    VueCropper,
    Loading,
  },
})
export default class CropImage extends Vue {
  @Prop({ required: true }) image!: string;
  @Prop({ default: false }) isShowCrop!: boolean;
  @Prop({ default: 'move' }) dragMode!: string;
  @Prop({ default: 0 }) viewMode!: number;
  @Prop({ default: true }) guides!: boolean;
  @Prop({ default: 1 }) autoCropArea!: number;
  @Prop({ default: true }) background!: boolean;
  @Prop({ default: true }) movable!: boolean;
  @Prop({ default: false }) modal!: boolean;
  @Prop({ required: false }) aspectRatio!: number;
  @Prop({ required: false }) minCropBoxHeight!: number;
  @Prop({ required: false }) minCropBoxWidth!: number;

  private isLoading = true;

  private cropperRef: any = null;

  private ActiveModal = new ActiveModal();

  mounted() {
    this.setRefDropzone();
  }

  get styleImage() {
    return {
      width: '100%',
      height: '100%',
      'object-fit': 'cover',
      display: this.isLoading ? 'none' : 'initial',
    };
  }

  get styleContainerImage() {
    return {
      display: 'flex',
      'justify-content': 'center',
      'align-items': 'center',
    };
  }

  onReady() {
    this.isLoading = false;
  }

  setRefDropzone() {
    const { cropper } = this.$refs;
    this.cropperRef = cropper;
  }

  async getCroppedImage() {
    return new Promise((resolve) => {
      this.cropperRef.getCroppedCanvas().toBlob((blob: any) => {
        resolve(blob);
      }, 'image/jpg');
    });
  }

  async handleCropSaveClick() {
    try {
      this.disableModalCropImage();

      const croppedImage = await this.getCroppedImage();

      this.$emit('image-crop', croppedImage);
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar a imagem.',
        status: 'error',
      });
      console.error({ error });
    }
  }

  handleCanceled() {
    this.$emit('cancel');
  }

  @Watch('isShowCrop', {
    immediate: true,
  })
  setModal() {
    if (this.isShowCrop) {
      this.$nextTick(() => {
        this.ActiveModal.activeModal('modalCropImage');
      });
    }
  }

  disableModalCropImage() {
    this.ActiveModal.disableModal('modalCropImage');
  }

  handleRotate() {
    this.cropperRef.rotate(ROTATE_DEGREE);
  }
}
