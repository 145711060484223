import { NavigationGuardNext, Route } from 'vue-router';

import { hasPermissionVideos } from '@/share/Util/Permissions/videos';

import { Video } from '@/globalInterfaces/Video';

import VideoService from '@/services/Video/VideoService';

const videoService = new VideoService();

async function getVideo(videoId: number) {
  let video: Video | null = null;

  try {
    video = await videoService.getVideo(videoId);
  } catch (error) {
    console.error(error);
  }

  return video;
}

export async function permissionVideo(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const { fileID } = to.params;

  const video = await getVideo(Number(fileID));

  const hasPermissionVideo = video ? hasPermissionVideos(video) : false;

  if (hasPermissionVideo) {
    next();
    return;
  }

  next({
    name: 'Dashboard',
  });

}
