import AppClient from '../AppClient';

export default class UtilService {
  private basePath = process.env.VUE_APP_BOOK_API;

  private Client = new AppClient({ url: this.basePath });

  async news() {
    const URI = '/news';

    const response = await this.Client.get(URI);

    return response;
  }
}
