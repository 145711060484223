





























































import { Component, Prop, Vue } from 'vue-property-decorator';

import Facebook from '@/assets/icons/social/Facebook.vue';
import Email from '@/assets/icons/social/Email.vue';
import WhatsApp from '@/assets/icons/social/Whatsapp.vue';
import Instagram from '@/assets/icons/social/Instagram.vue';
import Linkedin from '@/assets/icons/social/Linkedin.vue';
import Telegram from '@/assets/icons/social/Telegram.vue';
import X from '@/assets/icons/social/Twitter.vue';
import DownloadFile from '@/assets/icons/DownloadFile.vue';

const LIST_SOCIAL_MEDIA = [
  {
    title: 'Facebook',
    icon: 'Facebook',
    url: 'https://www.facebook.com/sharer/sharer.php?u=',
  },
  {
    title: 'WhatsApp',
    icon: 'WhatsApp',
    url: 'https://api.whatsapp.com/send?text=',
  },
  {
    title: 'Telegram',
    icon: 'Telegram',
    url: 'https://telegram.me/share/url?url=',
  },
  {
    title: 'X',
    icon: 'X',
    url: 'https://twitter.com/intent/tweet?url=',
  },
  {
    title: 'LinkedIn',
    icon: 'Linkedin',
    url: 'https://www.linkedin.com/shareArticle?mini=true&url=',
  },
  {
    title: 'Email',
    icon: 'Email',
    url: 'mailto:?body=',
  },
];

@Component({
  components: {
    Facebook,
    Email,
    WhatsApp,
    Instagram,
    Linkedin,
    Telegram,
    X,
    DownloadFile,
  },
})
export default class ListButtonShare extends Vue {
  @Prop() url!: string;
  @Prop({ default: true }) showUrlCopy!: boolean;
  @Prop({ default: true }) showOptions!: boolean;
  @Prop({ default: false }) showDownload!: boolean;

  private socialMedia = LIST_SOCIAL_MEDIA;

  copyText(): void {
    const input = this.$refs.inputURL as HTMLInputElement;
    input.select();
    document.execCommand('copy');

    this.$store.dispatch('Toast/setToast', {
      status: 'info',
      text: 'Link copiado para a área de transferência!',
    });
  }

  handleClickDownload() {
    this.$emit('dowload');
  }

  encodeURL(): string {
    return encodeURIComponent(this.url);
  }
}
