const Doubts = {
  state: {
    totalDoubtsMonthly: null,
  },
  mutations: {
    setTotalDoubtsMonthly(state: Record<string, any>, payload: number) {
      state.totalDoubtsMonthly = payload;
    },
  },
  getters: {
    totalDoubtsMonthly: (state: Record<string, any>) => state.totalDoubtsMonthly,
  },
};

export default Doubts;
