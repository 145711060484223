














































































































































































import {
  Component,
  Watch,
  Prop,
  Mixins,
} from 'vue-property-decorator';
import { gsap, Expo } from 'gsap';

import Thumb from '@/components/Thumb/index.vue';
import ToggleSwitch from '@/components/ToggleSwitch/index.vue';

import Dashboard from '@/assets/icons/menu/Dashboard.vue';
import Cadernos from '@/assets/icons/menu/Cadernos.vue';
import Redacao from '@/assets/icons/menu/Redacao.vue';
import Cursos from '@/assets/icons/menu/Cursos.vue';
import Planner from '@/assets/icons/menu/Planner.vue';
import Duvidas from '@/assets/icons/menu/Duvidas.vue';
import Exercicios from '@/assets/icons/menu/Exercicios.vue';
import Simulados from '@/assets/icons/menu/Simulados.vue';
import Vestibulares from '@/assets/icons/menu/Vestibulares.vue';
import Shopping from '@/assets/icons/menu/Shopping.vue';
import Lock from '@/assets/icons/menu/Lock.vue';
import B2b from '@/assets/icons/menu/B2b.vue';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';
import { logout } from '@/share/Util/Logout/index';
import { hasPermissionSimulated } from '@/share/Util/Permissions/simulated';

import { MENU_GROUP, MenuInterface, MENU_NAME } from './constants';
import { IDS_FOR_PERMISSION_COURSE } from '@/constant/CoursesId';
import { LIST_PERMISSION } from '@/constant/ListPermission';
import { LIST_PLAN_ID } from '@/constant/PlansIDs';
// import { LIST_ID_BOOK_QUESTION_FOR_PERMISSION } from '@/constant/ListIdsBookQuestion';
// import { IDS_FOR_PERMISSION_SIMULATED } from '@/constant/Simulated';

import permission from '@/mixins/permission';

const FIRST_INDEX_TAB_MENU_PROFILE = 0;
const TAB_NAME_PROFILE_NOTIFICATION = 'Notificações';
const ROUTE_TUTORIAL_COURSE = 'TutorialCourse';
const ROUTE_NAV_COURSE = 'Cursos';
const DEFINITION_NAME_TEACHER_B2B = 'Professor B2B';

@Component({
  components: {
    Thumb,
    ToggleSwitch,
    Dashboard,
    Cadernos,
    Redacao,
    Cursos,
    Planner,
    Duvidas,
    Exercicios,
    Simulados,
    Vestibulares,
    Shopping,
    B2b,
    Lock,

  },
})
export default class NavMenu extends Mixins(permission) {
  @Prop() private visivel!: number;
  @Prop() private isMobile!: boolean;
  @Prop() private startAnimate!: boolean;

  private isRotateArrow = false;
  private isActive = false;
  private thumbVisible = false;
  private timer: any;
  private menuGroup: Array<MenuInterface> = MENU_GROUP;

  mounted() {
    this.startAnimateNav();
  }

  get avatar() {
    return this.$store.getters.avatar;
  }

  get isB2b() {
    return !!this.$store.getters.profile.institution?.name;
  }

  get sidebarExistence() {
    return this.$store.getters.sidebarExistence;
  }

  get plan() {
    return this.$store.getters.profile?.plan;
  }

  get isPlanFree() {
    return this.plan?.planID === LIST_PLAN_ID.FREE;
  }

  get quantityNotifications() {
    return this.$store.getters.quantityNotifications;
  }

  get isTeacher() {
    return this.$store.getters.profile?.definitioName === DEFINITION_NAME_TEACHER_B2B;
  }

  get showAds() {
    return this.can(LIST_PERMISSION.ADS);
  }

  get isHighSchool() {
    return this.can(LIST_PERMISSION.BOOKS_DEGREE_HIGH_SCHOOL);
  }

  get isElementaryTwoSchool() {
    return this.can(LIST_PERMISSION.BOOKS_DEGREE_ELEMENTARY_SCHOOL_II);
  }

  userLogout() {
    logout({});
  }

  verifyPermissionFeature(featureName: string) {
    if (!featureName) return true;

    switch (featureName) {
      case MENU_NAME.COURSE:
        const permissionSomeCourse = Object.values(IDS_FOR_PERMISSION_COURSE).some((permissionCourse: string) => this.can(permissionCourse));
        return (permissionSomeCourse || this.showAds) && this.isHighSchool;

      case MENU_NAME.REDACTION:
        return this.can(LIST_PERMISSION.REDACTION_FEATURE) && this.isHighSchool;

      case MENU_NAME.DOUBTS:
        return this.can(LIST_PERMISSION.DOUBTS_FEATURE) && this.isHighSchool;

      case MENU_NAME.PLANNER:
        return this.isHighSchool;

      case MENU_NAME.SIMULATED:
        return this.isHighSchool;

      case MENU_NAME.EXERCISE:
        return this.isHighSchool || this.isElementaryTwoSchool;

      case MENU_NAME.EXAM:
        return this.isHighSchool;

      case MENU_NAME.SHOPPING:
        return this.isPlanFree;

      default:
        return true;
    }
  }

  checkRoute(routeNav: string) {
    if (this.$route.name === ROUTE_TUTORIAL_COURSE && routeNav === ROUTE_NAV_COURSE) return true;

    return false;
  }

  setIsMovie() {
    if (this.$store.getters.isMovie) {
      this.$store.commit('setIsMovie', !this.$store.getters.isMovie);
    }
  }

  setProfile() {
    this.$store.commit('setTabProfile', FIRST_INDEX_TAB_MENU_PROFILE);
  }

  setNotification() {
    this.setTrackAmplitude('Botão - Notificações');

    const { profileMenu } = this.$store.getters;
    const indexProfileMenuNotification = profileMenu.findIndex((option: string) => option === TAB_NAME_PROFILE_NOTIFICATION);

    this.$store.commit('setTabProfile', indexProfileMenuNotification);
  }

  showSubMenu() {
    clearInterval(this.timer);
    this.isActive = true;
    this.thumbVisible = true;
  }

  hideSubMenu(item: any) {
    item.stopPropagation();
    this.timer = setTimeout(() => {
      this.isActive = false;
      this.thumbVisible = false;
      clearInterval(this.timer);
    }, 200);
  }

  @Watch('startAnimate')
  startAnimateNav() {
    const object: gsap.TweenVars = { duration: 2, ease: Expo.easeInOut };
    if (this.isMobile) {
      object.height = 90;
      delete object.width;
    } else {
      object.width = 90;
    }

    gsap.to('.app-menu-sidebar', object).then(() => {
      document?.querySelector('.user-profile-card')?.classList?.add('active'); // eslint-disable-line
    });
  }

  showSidebar(): void {
    const { sidebar } = this.$store.state;
    this.$store.commit('setSidebar', !sidebar);

    this.isRotateArrow = !this.isRotateArrow;
  }

  setLinkPlanner() {
    // Realizar a verificação de primeiro acesso
    return '/planner/configuracao';
  }

  setTrackAmplitude(titleMenu: string) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'NavMenu',
          local: titleMenu,
        },
      },
    });
  }

  // showLockMenu(menuName: string) {
  //   if (!this.isPlanFree) return false;

  //   switch (menuName) {
  //     case MENU_NAME.COURSE:
  //       const listPermissionCourse = Object.values(IDS_FOR_PERMISSION_COURSE);

  //       return !listPermissionCourse.some((namePermission: any) => this.can(namePermission));
  //     case MENU_NAME.BOOK:
  //       return this.isPlanFree;
  //     case MENU_NAME.DOUBTS:
  //       return this.isPlanFree;
  //     case MENU_NAME.EXERCISE:
  //       return this.isPlanFree;
  //     case MENU_NAME.PLANNER:
  //       return !this.can(LIST_PERMISSION.PLANNER);
  //     case MENU_NAME.REDACTION:
  //       return !this.can(LIST_PERMISSION.REDACTION) || this.isPlanFree;
  //     case MENU_NAME.EXAM:
  //       const listPermissionExam = Object.values(LIST_ID_BOOK_QUESTION_FOR_PERMISSION);

  //       return !listPermissionExam.some((namePermission: any) => this.can(namePermission));
  //     case MENU_NAME.SIMULATED:
  //       return !hasPermissionSimulated();
  //     default:
  //       return false;
  //   }
  // }

  redirectAdminB2B() {
    const url = this.$store.getters.profile?.institution?.url;
    const uri = url.replace('https://', 'https://admin-');

    const token = this.$store.getters.token || localStorage.getItem('token');

    window.location.href = `${uri}?token=${token}`;
  }
}
