






























import {
  Component, Mixins,
} from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

import { LIST_ONBOARDING_ID } from '@/constant/OnboardingIDS';

import { OnboardingClass } from '@/mixins/Onboarding';

@Component({
  components: {
    Modal,
  },
})
export default class ModalWelcomePlanTrial extends Mixins(OnboardingClass) {

  onboardingWelcomeUpdate() {
    try {
      this.updateOnboarding(LIST_ONBOARDING_ID.FIRST_ACCESS_PLAN_TRIAL);
    } catch (error) {
      console.error(error);
    }
  }
}
