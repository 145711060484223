import store from '@/store';

import {
  ID_FOCA_ENEM_INTENSIVO,
  ID_OPERACAO_MED_INTENSIVO,
  ID_DNA_SP_INTENSIVO,
  ID_REVISION_HALF_YEAR,
  ID_COURSE_60_DAYS_FOR_THE_ENEM,
  ID_COURSE_FINAL_REVIEW,
} from '@/constant/CoursesId';

const PLAN_ID_ENEM_INTENSIVO = 337;
const PLAN_ID_DNA_SP_INTENSIVO = 338;
const PLAN_ID_OPERACAO_MED_INTENSIVO = 339;
const PLAN_ID_REVISION_HALF_YEAR = 372;

const PLAN_ID_60_DAYS_FOR_THE_ENEM = 461;
const PLAN_ID_60_DAYS_FOR_THE_ENEM_MANUAL_ENEM = 462;
const PLAN_ID_60_DAYS_FOR_THE_ENEM_MANUAL_FUVEST = 463;
const PLAN_ID_60_DAYS_FOR_THE_ENEM_MANUAL_FUVEST_MANUAL_ENEM = 464;
const PLAN_ID_60_DAYS_FOR_THE_ENEM_PLANNER = 465;
const PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE = 466;
const PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE_PLANNER = 467;
const PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE_PLANNER_MANUAL_FUVEST_MANUAL_ENEM = 468;
const PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE_PLANNER_MANUAL_ENEM = 469;
const PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE_PLANNER_MANUAL_FUVEST = 470;
const PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE_MANUAL_FUVEST = 471;
const PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE_MANUAL_ENEM = 472;
const PLAN_ID_60_DAYS_FOR_THE_ENEM_PLANNER_MANUAL_FUVEST = 473;
const PLAN_ID_60_DAYS_FOR_THE_ENEM_PLANNER_MANUAL_ENEM = 474;
const PLAN_ID_60_DAYS_FOR_THE_ENEM_MANUAL_ENEM_MANUAL_FUVEST_SEE = 475;
const PLAN_ID_60_DAYS_FOR_THE_ENEM_MANUAL_ENEM_MANUAL_FUVEST_PLANNER = 476;

const PLAN_ID_REVISION_FINAL_ENEM_2022 = 479;
const PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM = 480;
const PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_FUVEST = 481;
const PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_FUVEST_MANUAL_ENEM = 482;
const PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_PLANNER = 483;
const PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_SEE = 484;
const PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_SEE_PLANNER = 485;
const PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_SEE_PLANNER_MANUAL_FUVEST_MANUAL_ENEM = 486;
const PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_SEE_PLANNER_MANUAL_ENEM = 487;
const PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_SEE_PLANNER_MANUAL_FUVEST = 488;
const PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_MANUAL_FUVEST = 489;
const PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_MANUAL_ENEM = 490;
const PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_PLANNER_MANUAL_FUVEST = 491;
const PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_PLANNER_MANUAL_ENEM = 492;
const PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_SEE_MANUAL_FUVEST_MANUAL_ENEM = 493;
const PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_PLANNER_MANUAL_FUVEST_MANUAL_ENEM = 494;

const listIdPlanCourse = [
  PLAN_ID_ENEM_INTENSIVO,
  PLAN_ID_DNA_SP_INTENSIVO,
  PLAN_ID_OPERACAO_MED_INTENSIVO,
  PLAN_ID_REVISION_HALF_YEAR,
  PLAN_ID_60_DAYS_FOR_THE_ENEM,
  PLAN_ID_60_DAYS_FOR_THE_ENEM_MANUAL_ENEM,
  PLAN_ID_60_DAYS_FOR_THE_ENEM_MANUAL_FUVEST,
  PLAN_ID_60_DAYS_FOR_THE_ENEM_MANUAL_FUVEST_MANUAL_ENEM,
  PLAN_ID_60_DAYS_FOR_THE_ENEM_PLANNER,
  PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE,
  PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE_PLANNER,
  PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE_PLANNER_MANUAL_FUVEST_MANUAL_ENEM,
  PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE_PLANNER_MANUAL_ENEM,
  PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE_PLANNER_MANUAL_FUVEST,
  PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE_MANUAL_FUVEST,
  PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE_MANUAL_ENEM,
  PLAN_ID_60_DAYS_FOR_THE_ENEM_PLANNER_MANUAL_FUVEST,
  PLAN_ID_60_DAYS_FOR_THE_ENEM_PLANNER_MANUAL_ENEM,
  PLAN_ID_60_DAYS_FOR_THE_ENEM_MANUAL_ENEM_MANUAL_FUVEST_SEE,
  PLAN_ID_60_DAYS_FOR_THE_ENEM_MANUAL_ENEM_MANUAL_FUVEST_PLANNER,
  PLAN_ID_REVISION_FINAL_ENEM_2022,
  PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM,
  PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_FUVEST,
  PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_FUVEST_MANUAL_ENEM,
  PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_PLANNER,
  PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_SEE,
  PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_SEE_PLANNER,
  PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_SEE_PLANNER_MANUAL_FUVEST_MANUAL_ENEM,
  PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_SEE_PLANNER_MANUAL_ENEM,
  PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_SEE_PLANNER_MANUAL_FUVEST,
  PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_MANUAL_FUVEST,
  PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_MANUAL_ENEM,
  PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_PLANNER_MANUAL_FUVEST,
  PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_PLANNER_MANUAL_ENEM,
  PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_SEE_MANUAL_FUVEST_MANUAL_ENEM,
  PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_PLANNER_MANUAL_FUVEST_MANUAL_ENEM,
];

export const listIdPlanCourseForIdCourse: Record<number, number> = {
  [PLAN_ID_ENEM_INTENSIVO]: ID_FOCA_ENEM_INTENSIVO,
  [PLAN_ID_DNA_SP_INTENSIVO]: ID_DNA_SP_INTENSIVO,
  [PLAN_ID_OPERACAO_MED_INTENSIVO]: ID_OPERACAO_MED_INTENSIVO,
  [PLAN_ID_REVISION_HALF_YEAR]: ID_REVISION_HALF_YEAR,
  [PLAN_ID_60_DAYS_FOR_THE_ENEM]: ID_COURSE_60_DAYS_FOR_THE_ENEM,
  [PLAN_ID_60_DAYS_FOR_THE_ENEM_MANUAL_ENEM]: ID_COURSE_60_DAYS_FOR_THE_ENEM,
  [PLAN_ID_60_DAYS_FOR_THE_ENEM_MANUAL_FUVEST]: ID_COURSE_60_DAYS_FOR_THE_ENEM,
  [PLAN_ID_60_DAYS_FOR_THE_ENEM_MANUAL_FUVEST_MANUAL_ENEM]: ID_COURSE_60_DAYS_FOR_THE_ENEM,
  [PLAN_ID_60_DAYS_FOR_THE_ENEM_PLANNER]: ID_COURSE_60_DAYS_FOR_THE_ENEM,
  [PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE]: ID_COURSE_60_DAYS_FOR_THE_ENEM,
  [PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE_PLANNER]: ID_COURSE_60_DAYS_FOR_THE_ENEM,
  [PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE_PLANNER_MANUAL_FUVEST_MANUAL_ENEM]: ID_COURSE_60_DAYS_FOR_THE_ENEM,
  [PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE_PLANNER_MANUAL_ENEM]: ID_COURSE_60_DAYS_FOR_THE_ENEM,
  [PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE_PLANNER_MANUAL_FUVEST]: ID_COURSE_60_DAYS_FOR_THE_ENEM,
  [PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE_MANUAL_FUVEST]: ID_COURSE_60_DAYS_FOR_THE_ENEM,
  [PLAN_ID_60_DAYS_FOR_THE_ENEM_SEE_MANUAL_ENEM]: ID_COURSE_60_DAYS_FOR_THE_ENEM,
  [PLAN_ID_60_DAYS_FOR_THE_ENEM_PLANNER_MANUAL_FUVEST]: ID_COURSE_60_DAYS_FOR_THE_ENEM,
  [PLAN_ID_60_DAYS_FOR_THE_ENEM_PLANNER_MANUAL_ENEM]: ID_COURSE_60_DAYS_FOR_THE_ENEM,
  [PLAN_ID_60_DAYS_FOR_THE_ENEM_MANUAL_ENEM_MANUAL_FUVEST_SEE]: ID_COURSE_60_DAYS_FOR_THE_ENEM,
  [PLAN_ID_60_DAYS_FOR_THE_ENEM_MANUAL_ENEM_MANUAL_FUVEST_PLANNER]: ID_COURSE_60_DAYS_FOR_THE_ENEM,
  [PLAN_ID_REVISION_FINAL_ENEM_2022]: ID_COURSE_FINAL_REVIEW,
  [PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM]: ID_COURSE_FINAL_REVIEW,
  [PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_FUVEST]: ID_COURSE_FINAL_REVIEW,
  [PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_FUVEST_MANUAL_ENEM]: ID_COURSE_FINAL_REVIEW,
  [PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_PLANNER]: ID_COURSE_FINAL_REVIEW,
  [PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_SEE]: ID_COURSE_FINAL_REVIEW,
  [PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_SEE_PLANNER]: ID_COURSE_FINAL_REVIEW,
  [PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_SEE_PLANNER_MANUAL_FUVEST_MANUAL_ENEM]: ID_COURSE_FINAL_REVIEW,
  [PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_SEE_PLANNER_MANUAL_ENEM]: ID_COURSE_FINAL_REVIEW,
  [PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_SEE_PLANNER_MANUAL_FUVEST]: ID_COURSE_FINAL_REVIEW,
  [PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_MANUAL_FUVEST]: ID_COURSE_FINAL_REVIEW,
  [PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_MANUAL_ENEM]: ID_COURSE_FINAL_REVIEW,
  [PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_PLANNER_MANUAL_FUVEST]: ID_COURSE_FINAL_REVIEW,
  [PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_PLANNER_MANUAL_ENEM]: ID_COURSE_FINAL_REVIEW,
  [PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_SEE_MANUAL_FUVEST_MANUAL_ENEM]: ID_COURSE_FINAL_REVIEW,
  [PLAN_ID_REVISION_FINAL_ENEM_2022_MANUAL_ENEM_PLANNER_MANUAL_FUVEST_MANUAL_ENEM]: ID_COURSE_FINAL_REVIEW,
};

export default function isPlanCoursePermission() {
  const planID = store.getters.profile?.plan?.planID;

  return planID ? listIdPlanCourse.includes(planID) : false;
}
