






















import { Component, Prop, Vue } from 'vue-property-decorator';

const BACKGROUND_LIGHT = '#f6f7f8';
const BACKGROUND_DARK = '#1f2225';
const COLOR_STOP_1_LIGHT = '#eee';
const COLOR_STOP_1_DARK = '#282c30';
const COLOR_STOP_2_LIGHT = '#ddd';
const COLOR_STOP_2_DARK = '#34383b';

@Component({})
export default class Modal extends Vue {
  @Prop({ default: '7rem' }) maxWidth!: string;
  @Prop({ default: 80 }) minWidth!: number;
  @Prop({ default: '1.6s' }) animationDuration!: string;
  @Prop({ default: '1rem' }) height!: string;
  @Prop({ default: 16 }) width!: number;

  private BACKGROUND_LIGHT = BACKGROUND_LIGHT;
  private BACKGROUND_DARK = BACKGROUND_DARK;
  private COLOR_STOP_1_LIGHT = COLOR_STOP_1_LIGHT;
  private COLOR_STOP_1_DARK = COLOR_STOP_1_DARK;
  private COLOR_STOP_2_LIGHT = COLOR_STOP_2_LIGHT;
  private COLOR_STOP_2_DARK = COLOR_STOP_2_DARK;

  get isDarkMode() {
    return this.$store?.getters?.Theme?.isDarkMode;
  }

  get colorStopOne() {
    return this.isDarkMode ? COLOR_STOP_1_DARK : COLOR_STOP_1_LIGHT;
  }

  get colorStopTwo() {
    return this.isDarkMode ? COLOR_STOP_2_DARK : COLOR_STOP_2_LIGHT;
  }

  get computedWidth() {
    const value = Math.random() * (this.width - this.minWidth);

    return this.width ?? `${Math.floor(value + this.minWidth)}%`;
  }
}
