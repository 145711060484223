
































































































import {
  Component,
  Mixins,
  Prop,
  Watch,
} from 'vue-property-decorator';

import Book from '@/components/Book/index.vue';
import Tooltip from '@/components/Tooltip/index.vue';
import SubjectInfo from '@/components/Subject/SubjectInfo/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import ProgressBar from '@/components/ProgressBar/ProgressBar.vue';
import SubjectBox from '@/components/Subject/SubjectBox/index.vue';
import ContentLoader from '@/components/ContentLoader/index.vue';
import ListAccordionLoading from '@/components/ListAccordionLoading/index.vue';

import ProgressBarCourse from '@/mixins/ProgressBarCourse';
import AccessCourses from '@/mixins/AccessCourses';

import {
  NewCourse,
  CurrentCourse,
  LastAccessedContentCourse,
} from '@/globalInterfaces/Course';

const TOPIC_COURSE_TIME_TABLE = 'BAIXAR CRONOGRAMA';

@Component({
  components: {
    Book,
    Tooltip,
    SubjectInfo,
    FeedbackUser,
    ProgressBar,
    SubjectBox,
    ListAccordionLoading,
    ContentLoader,
  },
})
export default class CourseInProgress extends Mixins(ProgressBarCourse, AccessCourses) {
  @Prop() course!: NewCourse;
  @Prop() currentWeekCourse!: CurrentCourse;
  @Prop() lastWeekCourseAccessed!: LastAccessedContentCourse;
  @Prop() loading!: boolean;

  private newCurrentWeekCourse = {};

  get hasCourseInProgress() {
    return this.course && Object.entries(this.course).length && !this.course.firstAccess;
  }

  get hasCurrentWeekCourse() {
    return this.newCurrentWeekCourse && Object.entries(this.newCurrentWeekCourse).length;
  }

  get additionalClass() {
    if (!this.hasCurrentWeekCourse && !this.hasCourseInProgress) return '-without-week-course';
    if (!this.hasCurrentWeekCourse) return '-without-week';

    return '';
  }

  @Watch('currentWeekCourse')
  setProgressBar() {
    if (this.course && this.currentWeekCourse) {
      const idCourse = this.course.idCourses.toString();
      this.getWatchedWeek(idCourse);
    }
  }

  @Watch('currentWeekCourse')
  setNewCurrentWeekCourse() {
    this.newCurrentWeekCourse = {
      ...this.currentWeekCourse,
      topics: this.findTimeTableAndSeparateFromTheList(),
    };
  }

  findTimeTableAndSeparateFromTheList() {
    return this.currentWeekCourse.topics.reduce((acc: Array<Record<string, any>>, topic: Record<string, any>) => {
      if (topic.title !== TOPIC_COURSE_TIME_TABLE) acc.push(topic);
      return acc;
    }, []);
  }

  setListInfoWeek() {
    if (this.newCurrentWeekCourse && Object.entries(this.newCurrentWeekCourse).length) {
      let quantityVideos = 0;
      let quantityArchives = 0;

      quantityVideos = this.setQuantityTopics(this.newCurrentWeekCourse, 'videos', quantityVideos);
      quantityArchives = this.setQuantityTopics(this.newCurrentWeekCourse, 'archives', quantityArchives);

      return [
        {
          quantity: quantityVideos,
          title: 'Aulas',
        },
        {
          quantity: quantityArchives,
          title: 'Materiais',
        },
      ];
    }
    return [];
  }

  setQuantityTopics(data: Record<string, any>, type: string, initialValue: number) {
    let quantityFiles: number = initialValue;

    if (data.topics && data.topics.length) {
      const total = quantityFiles;
      data.topics.forEach((topic: Record<string, any>) => {
        quantityFiles += this.setQuantityTopics(topic, type, total);
      });
    } else if (data[type]) {
      quantityFiles = data[type]?.length;
      return quantityFiles;
    }

    return quantityFiles;
  }

  openCourse() {
    if (this.lastWeekCourseAccessed) {
      const { idCourses, idClasses } = this.lastWeekCourseAccessed;
      return this.$router.push({
        name: 'Courses',
        params: {
          slugCourse: this.course.slug,
          idCourses: `${idCourses}`,
          idClass: `${idClasses}`,
        },
      });

    }

    return this.course?.redirect;
  }
}
