const Exams = {
  state: {
    examsBook: [],
  },
  mutations: {
    setExamsBook(state: Record<string, any>, payload: Array<Record<string, any>>) {
      state.examsBook = payload;
    },
  },
  getters: {
    examsBook: (state: Record<string, any>) => state.examsBook,
  },
};

export default Exams;
