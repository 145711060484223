const AnnotationsOrder = {
  state: {
    order: {
      pdf: 0,
      video: 0,
      time: 0,
    },
  },
  mutations: {
    setOrder(state: Record<string, any>, payload: Array<Record<string, any>>) {
      state.order = { ...state.order, ...payload };
    },
  },
  getters: {
    order: (state: Record<string, any>) => state.order,
  },
};

export default AnnotationsOrder;
