






























import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

import Arrow from '@/assets/icons/Arrow.vue';

const ROTATE_ARROW_UP = '180';
const ROTATE_ARROW_DOWN = '0';

@Component({
  components: {
    Arrow,
  },
})
export default class BoxWithArrow extends Vue {
  @Prop() title!: string;
  @Prop() fluid!: boolean;
  @Prop() open!: boolean;
  @Prop({ default: true }) showArrow!: boolean;
  @Prop() setOpen!: Function;

  private appClass = 'app-box';

  created() {
    if (this.fluid !== undefined) {
      this.appClass = 'app-box-fluid';
    }
  }

  get rotateArrow() {
    return this.open ? ROTATE_ARROW_UP : ROTATE_ARROW_DOWN;
  }
}
