import { RouteConfig } from 'vue-router';

import { multiGuard } from '../Middleware/MultiGuard';
import { permissionDoubts, permissionDoubtsCredits } from '../Middleware/permission/Doubts';
import { permissionFeature } from '../Middleware/permission/Feature';

import {
  onboardingDoubts,
} from '../Middleware/onboarding/index';

export const NAME_ROUTES_DOUBTS = {
  LIST: 'Doubts',
  TUTORIAL: 'TutorialDoubts',
  SHOW: 'ShowDoubts',
  NEW: 'NewDoubt',
};

export const Doubts: Array<RouteConfig> = [
  {
    path: '/duvidas',
    name: NAME_ROUTES_DOUBTS.LIST,
    component: () => import(/* webpackChunkName: "doubts" */ '@/pages/Doubts/Doubts.vue'),
    meta: { title: 'Dúvidas' },
    beforeEnter: multiGuard([
      // permissionFeature,
      // permissionDoubtsFeature,
      onboardingDoubts,
    ]),
  },
  {
    path: '/duvidas/tutorial',
    name: NAME_ROUTES_DOUBTS.TUTORIAL,
    component: () => import(/* webpackChunkName: "doubts-tutorial" */ '@/pages/Doubts/pages/TutorialDoubts/TutorialDoubts.vue'),
    meta: { title: 'Tutorial Dúvidas' },
    // beforeEnter: multiGuard([
    //   permissionFeature,
    //   permissionDoubts,
    // ]),
  },
  {
    path: '/duvida/:id',
    name: NAME_ROUTES_DOUBTS.SHOW,
    component: () => import(/* webpackChunkName: "doubts-show" */ '@/pages/Doubts/pages/ShowDoubts/ShowDoubts.vue'),
    meta: { title: 'Visualização da Dúvida' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionDoubts,
      onboardingDoubts,
    ]),
  },
  {
    path: '/enviar_duvida',
    name: NAME_ROUTES_DOUBTS.NEW,
    component: () => import(/* webpackChunkName: "doubts-send" */ '@/pages/Doubts/pages/NewDoubt/NewDoubt.vue'),
    meta: { title: 'Enviar Dúvida' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionDoubtsCredits,
      onboardingDoubts,
    ]),
  },
];
