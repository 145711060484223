
































import {
  Vue, Component,
} from 'vue-property-decorator';
import {
  endOfToday,
  intervalToDuration,
} from 'date-fns';

import Modal from '@/globalComponents/Modal/Modal.vue';

import { setNumber } from '@/share/Util/UsefulFunctions/UserfulFunctions';

@Component({
  components: {
    Modal,
  },
})
export default class ModalTrialPlan extends Vue {
  private idInterval: number | undefined = undefined;
  private time = '';

  mounted() {
    this.timeExpiredPromotion();
  }

  destroyed() {
    clearInterval(this.idInterval);
  }

  timeExpiredPromotion() {
    clearInterval(this.idInterval);
    this.idInterval = setInterval(() => {
      const result = intervalToDuration({
        start: new Date(),
        end: endOfToday(),
      });

      this.time = `${setNumber(result.hours || 0)}:${setNumber(result.minutes || 0)}:${setNumber(result.seconds || 0)}`;
    }, 1000);
  }
}
