






























import {
  Component, Mixins,
} from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

import { LIST_ONBOARDING_ID } from '@/constant/OnboardingIDS';

import { OnboardingClass } from '@/mixins/Onboarding';

@Component({
  components: {
    Modal,
  },
})
export default class ModalEndOfPlanTrial extends Mixins(OnboardingClass) {
  handleCloseModal() {
    this.updateOnboardingInProfile();
  }

  handleClickButton() {
    this.updateOnboardingInProfile();
    this.openPlansTab();
  }

  updateOnboardingInProfile() {
    try {
      this.updateOnboarding(LIST_ONBOARDING_ID.EXP_FREE);
      this.timerUpdateProfile();
    } catch (error) {
      console.error(error);
    }
  }

  openPlansTab() {
    window.open('https://explicae.com.br/#plans', '_blank');
  }
}
