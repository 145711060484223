import { Vue, Component } from 'vue-property-decorator';
import SimulatedFeedbackStore from '@/share/Util/SimulatedFeedbackContent/SimulatedFeedbackContent';

@Component
export default class SimulatedFeedbackStaus extends Vue {
  private SimulatedFeedbackStore = new SimulatedFeedbackStore();

  async created() {
    await this.SimulatedFeedbackStore.setSimulatedContent();
  }
}
