import { FEATURES } from './Constants';

export const featureToggle = (name) => {
  const nameFeature = FEATURES[name];
  const featureEnv = JSON.parse(nameFeature);

  const urlSearch = window.location.search;
  const hasUrlFeatureToggle = urlSearch.includes('feature=');

  if (!hasUrlFeatureToggle || featureEnv) return featureEnv;

  const featuresUrlSplited = urlSearch.split('=')[1].split('&&');

  return featuresUrlSplited.indexOf(name) > -1;
};
