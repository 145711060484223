const Permission = {
  state: {
    redaction: false,
    listCourses: false,
  },
  mutations: {
    setPermissionRedaction(state: Record<string, any>, payload: boolean) {
      state.redaction = payload;
    },
    setPermissionListCourses(state: Record<string, any>, payload: boolean) {
      state.listCourses = payload;
    },
  },
  getters: {
    permissionRedaction: (state: Record<string, any>) => state.redaction,
    permissionListCourses: (state: Record<string, any>) => state.listCourses,
  },
};

export default Permission;
