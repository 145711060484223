import Amplitude from '@/share/Util/Amplitude/Amplitude';
import Sentry from '@/share/Util/Sentry/Sentry';

// Inicializando a instancia do Amplitude
export const InstanceAmplitude = new Amplitude();
InstanceAmplitude.initialize({
  apiKey: process.env.VUE_APP_API_KEY_AMPLITUDE,
});

export const InstanceSentry = new Sentry();
InstanceSentry.initialization();
