const date = new Date();
const monthValue = date.getMonth() + 1;

const currentMonth = monthValue < 10 ? `0${monthValue}` : `${monthValue}`;
const currentYear = String(date.getFullYear());

function getDaysArray(year: number, month: number) {
  const monthNames = [
    'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
    'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez',
  ];

  const numDays = new Date(year, month, 0).getDate();
  const daysArray = new Array(numDays);

  for (let i = 0; i < numDays; i += 1) {
    const day = i + 1;
    const monthName = monthNames[month - 1];
    daysArray[i] = `${day} ${monthName}`;
  }

  return daysArray;
}

const daysArray = getDaysArray(Number(currentYear), Number(currentMonth));

function getDataSeparatedFromDateTime(dateValue: string) {
  let dateTimeWithoutZ = dateValue;

  if (dateValue.includes('Z')) {
    dateTimeWithoutZ = dateValue.replace('Z', '');
  }

  const dateTime = new Date(dateTimeWithoutZ);

  const day = String(dateTime.getDate()).padStart(2, '0');
  const month = String(dateTime.getMonth() + 1).padStart(2, '0');
  const year = dateTime.getFullYear();
  const hours = String(dateTime.getHours()).padStart(2, '0');
  const minutes = String(dateTime.getMinutes()).padStart(2, '0');

  return {
    day,
    month,
    year,
    hours,
    minutes,
  };
}

export {
  currentMonth,
  currentYear,
  daysArray,
  getDaysArray,
  getDataSeparatedFromDateTime,
};
