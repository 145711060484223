const INITIAL_STATE = {
  text: null,
};

const Toast = {
  namespaced: true,
  state: {
    data: {
      ...INITIAL_STATE,
    },
  },
  mutations: {
    SET_TOAST(state: any, data: Record<string, any>) {
      state.data = data;
    },
  },
  actions: {
    setToast(context: Record<string, any>, data: Record<string, any>) {
      context.commit('SET_TOAST', data);
    },
  },
};

export default Toast;
