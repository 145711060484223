import { Component, Vue } from 'vue-property-decorator';

import AuthService from '@/services/Auth/AuthService';

import Onboarding from '@/share/Util/Onboarding/Onboarding';
import { updateProfile } from '@/share/Util/Profile/Profile';

@Component({})
export class OnboardingClass extends Vue {
  private auth = new AuthService();
  private onboarding = new Onboarding();

  onboardViewed(idOnboarding: number) {
    return this.onboarding.alreadyViewedOnboarding(idOnboarding);
  }

  async updateOnboarding(idOnboarding: number) {
    if (!this.onboardViewed(idOnboarding)) {
      await this.auth.updateOnboarding(idOnboarding);
    }
  }

  async timerUpdateProfile(nextFunction?: Function) {
    const timer = setTimeout(async () => {
      await updateProfile();
      if (nextFunction) nextFunction();
      clearInterval(timer);
    }, 1000);
  }
}
