const Exercise = {
  state: {
    exerciseResult: [],
    exerciseOptions: {},
    exercise: {},
    exerciseGraphics: {},
    answeredExercises: {},
  },
  mutations: {
    setExerciseResult(state: Record<string, any>, payload: Array<Record<string, string | number>>) {
      state.exerciseResult = payload;
    },
    setExerciseOptions(state: Record<string, any>, payload: Record<string, any>) {
      state.exerciseOptions = payload;
    },
    setExercise(state: Record<string, any>, payload: Record<string, any>) {
      state.exercise = payload;
    },
    setExerciseGraphics(state: Record<string, any>, payload: Record<string, any>) {
      state.exerciseGraphics = payload;
    },
    setAnsweredExercises(state: Record<string, any>, payload: Record<string, any>) {
      state.answeredExercises = payload;
    },
  },
  getters: {
    exerciseResult: (state: Record<string, any>) => state.exerciseResult,
    exerciseOptions: (state: Record<string, any>) => state.exerciseOptions,
    exercise: (state: Record<string, any>) => state.exercise,
    exerciseGraphics: (state: Record<string, any>) => state.exerciseGraphics,
    answeredExercises: (state: Record<string, any>) => state.answeredExercises,
  },
};

export default Exercise;
