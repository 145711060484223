import { render, staticRenderFns } from "./Banner.vue?vue&type=template&id=2dc59459&scoped=true&"
import script from "./Banner.vue?vue&type=script&lang=ts&"
export * from "./Banner.vue?vue&type=script&lang=ts&"
import style0 from "./Banner.scss?vue&type=style&index=0&id=2dc59459&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dc59459",
  null
  
)

export default component.exports