import { RouteConfig } from 'vue-router';

import { permissionShopping } from '../Middleware/permission/Shopping';

export const NAME_ROUTES_STORE = {
  STORE: 'Store',
  PENDENCIES: 'Pendencies',
  DESCRIPTIVE: 'Descriptive',
  PAYMENT: 'Payment',
  PAYMENT_BILLET: 'PaymentBillet',
  PAYMENT_PIX: 'PaymentPix',
  PAYMENT_THANKS: 'PaymentThankYou',
};

export const Store: Array<RouteConfig> = [
  {
    path: '/loja',
    name: NAME_ROUTES_STORE.STORE,
    component: () => import(/* webpackChunkName: "store" */ '@/pages/Shopping/Shopping.vue'),
    meta: { title: 'Loja', isAnonymous: true },
    beforeEnter: permissionShopping,
  },
  {
    path: '/loja/pendencias',
    name: NAME_ROUTES_STORE.PENDENCIES,
    component: () => import(/* webpackChunkName: "pendencies" */ '@/pages/Shopping/pages/PendencyDetail/PendencyDetail.vue'),
    meta: { title: 'Pendências' },
    beforeEnter: permissionShopping,
  },
  {
    path: '/loja/:feature/:productId/descritivo',
    name: NAME_ROUTES_STORE.DESCRIPTIVE,
    component: () => import(/* webpackChunkName: "descriptive" */ '@/pages/Shopping/pages/Descriptive/index.vue'),
    meta: { title: 'Descritivo', isAnonymous: true },
    beforeEnter: permissionShopping,
  },
  {
    path: '/loja/:feature/:productId/comprar/:discount?',
    name: NAME_ROUTES_STORE.PAYMENT,
    component: () => import(/* webpackChunkName: "payment" */ '@/pages/Shopping/pages/Payment/Payment.vue'),
    meta: { title: 'Pagamento', isAnonymous: true },
    beforeEnter: permissionShopping,
  },
  {
    path: '/loja/:feature/:productId/comprar/:idPurchase/boleto',
    name: NAME_ROUTES_STORE.PAYMENT_BILLET,
    component: () => import(/* webpackChunkName: "payment-billet" */ '@/pages/Shopping/pages/PaymentBillet/PaymentBillet.vue'),
    meta: { title: 'Pagamento com boleto' },
    beforeEnter: permissionShopping,
  },
  {
    path: '/loja/:feature/:productId/comprar/:idPurchase/pix',
    name: NAME_ROUTES_STORE.PAYMENT_PIX,
    component: () => import(/* webpackChunkName: "payment-pix" */ '@/pages/Shopping/pages/PaymentPix/PaymentPix.vue'),
    meta: { title: 'Pagamento com pix' },
    beforeEnter: permissionShopping,
  },
  {
    path: '/loja/:feature/:productId/comprar/:idPurchase/obrigado',
    name: NAME_ROUTES_STORE.PAYMENT_THANKS,
    component: () => import(/* webpackChunkName: "payment-thank-you" */ '@/pages/Shopping/pages/ThankYouPayment/index.vue'),
    meta: { title: 'Obrigado pelo pagamento' },
    beforeEnter: permissionShopping,
  },
];
