import { Component, Prop, Vue } from 'vue-property-decorator';
import { deviceType } from 'mobile-device-detect';

type ResponsiveGridOptions = {
  [key: string]: number;
}

export interface ResponsiveGrid{
  sm: number;
  md: number;
  lg: number;
  xl: number;
  fluid?: number;
}

@Component({})
export class Responsive extends Vue {
  @Prop() protected mySuperProp!: string;

  private static instance: Responsive;
  public isMobile = false;
  public gridNumber = 0;
  public gridLabel = 'xl';
  public contentwidth = 0;

  created() {
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  resize() {
    this.contentwidth = window.innerWidth;
    if (this.contentwidth > 1200) {
      this.gridNumber = 0;
      this.gridLabel = 'xl';
    } else if (this.contentwidth > 992 && this.contentwidth < 1199.98) {
      this.gridNumber = 1;
      this.gridLabel = 'lg';
    } else if (this.contentwidth > 768 && this.contentwidth < 991.98) {
      this.gridNumber = 2;
      this.gridLabel = 'md';
    } else {
      this.gridNumber = 3;
      this.gridLabel = 'sm';
    }
    this.isMobile = deviceType === 'mobile' || this.gridNumber === 3;
  }

  getNewWidth(width: number, list: ResponsiveGrid) {
    let newWidth = width;
    if (list) {
      const responsiveWidth: Array<number> = [list.xl, list.lg, list.md, list.sm];
      for (let i = this.gridNumber; i < responsiveWidth.length; i += 1) {
        if (responsiveWidth[i] !== undefined) {
          newWidth = responsiveWidth[i];
          break;
        }
      }
    }
    return newWidth;
  }

  public static getInstance(): Responsive {
    if (!Responsive.instance) {
      Responsive.instance = new Responsive();
    }
    return Responsive.instance;
  }
}

export default Responsive;
