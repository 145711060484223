import { RouteConfig } from 'vue-router';

export const NAME_ROUTES_NOT_FOUND = {
  NOT_FOUND: '404',
};

export const NotFound: Array<RouteConfig> = [
  {
    path: '/404',
    name: NAME_ROUTES_NOT_FOUND.NOT_FOUND,
    component: () => import(/* webpackChunkName: "404" */ '@/pages/NotFound/NotFound.vue'),
  },
];
