import { NavigationGuardNext, Route } from 'vue-router';

import store from '@/store';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';

export function permissionShopping(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const hasProfile = store.getters.profile.idstudent;
  const isPlanFree = store.getters.profile.plan?.planID === LIST_PLAN_ID.FREE;

  if (!isPlanFree && hasProfile) {
    next({
      name: 'Dashboard',
    });
  }

  next();
}
