






















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Credits extends Vue {
  @Prop({ default: '16' }) width!: string;
  @Prop({ default: '23' }) height!: string;
}
