const PREVIOUS_VESTIBULAR_TITLE = 'VESTIBULARES ANTERIORES';

const Simulated = {
  state: {
    listSimulations: {},
    infoSimulatedLog: {},
    infoSimulatedQuestion: null,
    infoSimulatedResult: {},
    simulatedQuestionsResult: {},
    indexSimulatedDay: null,
    idLanguageSelected: null,
    simulatedTitleCover: {
      title: '',
      coverImage: '',
      url: '',
    },
    formatShowingQuestions: 'one',
    previousPageRoute: '',
    permanenceSimulatedData: false,
    isLoadingQuestionsSimulated: false,
    rightAndWrongQuestions: {},
    listSimulatedStatusFeedback: [],
    clickedCloseModalExitSimulated: false,
    simulatedSent: false,
    simulatedResultPercentageProgress: 0,
  },
  mutations: {
    saveListSimulations(state: Record<string, any>, payload: Record<string, any>) {
      const payloadReceived = payload;
      const indexPreviousVestibular = payload.data.findIndex((simulated: Record<string, any>) => simulated.title === PREVIOUS_VESTIBULAR_TITLE);
      const newOrderListSimulated = [...payload.data.splice(indexPreviousVestibular, 1), ...payload.data];
      payloadReceived.data = newOrderListSimulated;
      state.listSimulations = payloadReceived;
    },
    saveInfoSimulatedQuestion(state: Record<string, any>, payload: Record<string, any>) {
      state.infoSimulatedQuestion = payload;
    },
    saveInfoSimulationLog(state: Record<string, any>, payload: Record<string, any>) {
      state.infoSimulatedLog = payload;
    },
    saveInfoSimulatedResult(state: Record<string, any>, payload: Record<string, any>) {
      state.infoSimulatedResult = payload;
    },
    saveSimulatedQuestionsResult(state: Record<string, any>, payload: Record<string, any>) {
      state.simulatedQuestionsResult = payload;
    },
    saveIndexSimulatedDay(state: Record<string, any>, payload: number | string) {
      state.indexSimulatedDay = payload;
    },
    saveIdLanguageSelected(state: Record<string, any>, payload: number) {
      state.idLanguageSelected = payload;
    },
    saveSimulatedTitleCover(state: Record<string, any>, payload: Record<string, any>) {
      state.simulatedTitleCover = payload;
    },
    saveFormatShowingQuestions(state: Record<string, any>, payload: string) {
      state.formatShowingQuestions = payload;
    },
    savePreviousPageRoute(state: Record<string, any>, payload: string) {
      state.previousPageRoute = payload;
    },
    setPermanenceSimulatedData(state: Record<string, any>, payload: boolean) {
      state.permanenceSimulatedData = payload;
    },
    setIsLoadingQuestionsSimulated(state: Record<string, any>, payload: boolean) {
      state.isLoadingQuestionsSimulated = payload;
    },
    saveRightAndWrongQuestions(state: Record<string, any>, payload: Record<string, any>) {
      state.rightAndWrongQuestions = payload;
    },
    setListSimulatedStatusFeedback(state: Record<string, any>, payload: Array<Record<string, any>>) {
      state.listSimulatedStatusFeedback = [...state.listSimulatedStatusFeedback, payload];
    },
    setClickedCloseModalExitSimulated(state: Record<string, any>, payload: boolean) {
      state.clickedCloseModalExitSimulated = payload;
    },
    setSimulatedSent(state: Record<string, any>, payload: boolean) {
      state.simulatedSent = payload;
    },
    setSimulatedResultPercentageProgress(state: Record<string, any>, payload: number) {
      state.simulatedResultPercentageProgress = payload;
    },
  },
  actions: {
    saveListSimulatedStatusFeedback(context: Record<string, any>, payload: Array<Record<string, any>>) {
      context.commit('setListSimulatedStatusFeedback', payload);
    },
  },
  getters: {
    listSimulations: (state: Record<string, any>) => state.listSimulations,
    infoSimulatedQuestion: (state: Record<string, any>) => state.infoSimulatedQuestion,
    infoSimulatedLog: (state: Record<string, any>) => state.infoSimulatedLog,
    infoSimulatedResult: (state: Record<string, any>) => state.infoSimulatedResult,
    simulatedQuestionsResult: (state: Record<string, any>) => state.simulatedQuestionsResult,
    indexSimulatedDay: (state: Record<string, any>) => state.indexSimulatedDay,
    idLanguageSelected: (state: Record<string, any>) => state.idLanguageSelected,
    simulatedTitleCover: (state: Record<string, any>) => state.simulatedTitleCover,
    formatShowingQuestions: (state: Record<string, any>) => state.formatShowingQuestions,
    previousPageRoute: (state: Record<string, any>) => state.previousPageRoute,
    permanenceSimulatedData: (state: Record<string, any>) => state.permanenceSimulatedData,
    isLoadingQuestionsSimulated: (state: Record<string, any>) => state.isLoadingQuestionsSimulated,
    rightAndWrongQuestions: (state: Record<string, any>) => state.rightAndWrongQuestions,
    listSimulatedStatusFeedback: (state: Record<string, any>) => state.listSimulatedStatusFeedback,
    clickedCloseModalExitSimulated: (state: Record<string, any>) => state.clickedCloseModalExitSimulated,
    simulatedSent: (state: Record<string, any>) => state.simulatedSent,
    simulatedResultPercentageProgress: (state: Record<string, any>) => state.simulatedResultPercentageProgress,
  },
};

export default Simulated;
