import { RouteConfig } from 'vue-router';

export const NAME_ROUTES_DEFAULT = {
  START: 'begin',
};

export const Default: Array<RouteConfig> = [
  {
    path: '/',
    name: NAME_ROUTES_DEFAULT.START,
    redirect: '/dashboard',
  },
];
