






























import {
  Vue,
  Component,
} from 'vue-property-decorator';

import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Compressor from 'compressorjs';

import CropImage from '@/components/CropImage/CropImage.vue';

interface DataImage {
  data: {
    url: string;
  };
}

@Component({
  components: {
    vueDropzone,
    CropImage,
  },
})
export default class UploadImageSection extends Vue {
  private dropzoneOptions = {
    url: `${process.env.VUE_APP_API_STATIC_CDN}/photos`,
    thumbnailWidth: 200,
    addRemoveLinks: true,
    uploadMultiple: false,
    maxFiles: 1,
    acceptedFiles: 'image/jpeg,image/jpg,image/png',
    dictRemoveFile: 'Remover',
    dictCancelUpload: 'Cancelar',
    dictInvalidFileType: 'Extensão de arquivo não suportado.',
    dictMaxFilesExceeded: 'Permitido apenas um arquivo.',
    dictResponseError: 'Erro interno. Tente mais tarde.',
    dictCancelUploadConfirmation: 'Tem certeza que deseja cancelar o upload?',
    transformFile: this.handleTransformFile,
  }

  private afterCropImage = false;
  private isShowCrop = false;

  private urlImagemSupport = '';
  private imageOriginal = '';

  private dropzoneRef: any = null;

  mounted() {
    this.setRefDropzone();
  }

  setRefDropzone() {
    const { dropzone } = this.$refs;
    this.dropzoneRef = dropzone;
  }

  handleTransformFile(file: any, done: any) {
    // eslint-disable-next-line no-new
    new Compressor(file, {
      quality: 0.6,
      convertTypes: ['image/jpg,image/png'],
      convertSize: 1000000,
      maxWidth: 2000,
      maxHeight: 2000,
      checkOrientation: true,

      success(result) {
        done(result);
      },
      error(err) {
        console.error(err.message);
      },
    });
  }

  async sendImage(image: Blob) {
    if (this.dropzoneRef) {
      this.dropzoneRef.removeAllFiles();

      if (!this.afterCropImage) {
        this.afterCropImage = true;
      }

      const myFile = new File([image], 'support.png', {
        type: image.type,
      });

      this.dropzoneRef.addFile(myFile);
    }
  }

  uploadImage(file: object, response: DataImage) {
    const { data } = response;

    if (data.url && !this.afterCropImage) {
      this.imageOriginal = data.url;
      this.urlImagemSupport = data.url;
      this.isShowCrop = true;
    } else {
      this.urlImagemSupport = data.url;
      this.isShowCrop = false;
    }

    this.$emit('image-support', this.urlImagemSupport);
  }

  onRemovedFile() {
    this.urlImagemSupport = '';
    this.afterCropImage = false;

    this.$emit('image-support', this.urlImagemSupport);
  }

  canceledImage() {
    this.isShowCrop = false;
    this.afterCropImage = true;
  }
}
