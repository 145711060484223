





























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class B2b extends Vue {}
