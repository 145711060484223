import { NavigationGuardNext, Route } from 'vue-router';
import store from '@/store';

import {
  SIMULADO_REVISAO,
  SIMULADO_VESTIBULARES_ANTERIORES,
  SIMULADO_CURSOS,
  SIMULADO_EXP_FREE,
} from '@/constant/Simulated';

import SimulationService from '@/services/Simulation/SimulationService';

import { LIST_PERMISSION } from '@/constant/ListPermission';

import Permission from './index';
import { hasPermissionSimulated } from '@/share/Util/Permissions/simulated';

const simulationService = new SimulationService();

const PAGE_SIMULATED_RESULT = 'SimulatedResult';

async function getSimulationCategory(idCategory: number) {
  try {
    const response = await simulationService.checkSimulated(idCategory);
  } catch (error) {
    console.error(error);
  }
}

export async function permissionSimulated(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  await getSimulationCategory(Number(to.params?.id));

  if (to.name === PAGE_SIMULATED_RESULT) next();

  const PermissionClass = new Permission();
  const simulationUrl = store.getters.simulatedTitleCover.url || '';

  if (simulationUrl.includes(SIMULADO_EXP_FREE)) {
    next();

    return;
  }

  if (simulationUrl.includes(SIMULADO_VESTIBULARES_ANTERIORES)) {
    const hasPermission = PermissionClass.can(LIST_PERMISSION.SIMULATIONS_PREVIOUS);

    if (hasPermission) next();
    return;
  }

  if (simulationUrl.includes(SIMULADO_REVISAO)) {
    const hasPermission = PermissionClass.can(LIST_PERMISSION.SIMULATIONS_EXCLUSIVEENEM);

    if (hasPermission) next();
    return;
  }

  if (simulationUrl.includes(SIMULADO_CURSOS)) {
    const hasPermission = PermissionClass.can(LIST_PERMISSION.SIMULATIONS_COURSES);

    if (hasPermission) next();
    return;
  }

  next({
    name: 'SimulationsList',
  });
}

export function permissionSimulator(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const hasPermission = hasPermissionSimulated();

  if (!hasPermission) {
    next({
      name: 'SimulationsList',
    });
    return;
  }

  next();
}
