export const LIST_TUTORIALS_ROUTE = {
  Books: 'TutorialBooks',
  BooksView: 'TutorialBooks',
  CoursesList: 'TutorialCourse',
  Redaction: 'TutorialRedaction',
  ListQuestions: 'TutorialQuestions',
  Doubts: 'TutorialDoubts',
  videos: 'TutorialVideos',
  exercicio: 'TutorialExercise',
  SimulationsList: 'TutorialSimulation',
  Exams: 'TutorialExam',
  Planner: 'TutorialPlanner',
};

export const ROUTE_BOOK = 'BooksView';
export const ROUTE_REDACTION = 'Redaction';
export const ROUTE_LIST_COURSE = 'CoursesList';
