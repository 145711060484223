































import { Component, Prop, Vue } from 'vue-property-decorator';

const DEFAULT_FILL = 'var(--neutralcolor-high-pure)';

const DEFAULT_SIZE = '1.875rem';

@Component
export default class Whatsapp extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;
  @Prop({ default: DEFAULT_SIZE }) width!: string;
  @Prop({ default: DEFAULT_SIZE }) height!: string;
}
