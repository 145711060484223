/* eslint-disable import/no-cycle */
import MaterialCategoryService from '@/services/MaterialCategory/MaterialCategoryService';

let materialCategoryList: Array<Category> = [];

const materialCategoryService = new MaterialCategoryService();

interface Category {
  ID: number;
  category: string;
  priority: number;
}

function findMaterialCategory(group: number) {
  const materialCategory: Category | undefined = materialCategoryList.find((category: Category) => group === category.ID);

  return materialCategory;
}

function sortTitle(listFiles: Array<Record<string, any>>) {
  const sortFiles = listFiles.sort((a: Record<string, any>, b: Record<string, any>) => {
    if (a.title < b.title) return -1;
    if (a.title > b.title) return 1;
    return 0;
  });

  return sortFiles;
}

function sortVideos(listVideos: Array<Record<string, any>>) {
  const sortedVideos = listVideos.sort((a: Record<string, any>, b: Record<string, any>) => {
    if (a.order) {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    }
    if (a.title < b.title) return -1;
    if (a.title > b.title) return 1;
    return 0;

  });

  return sortedVideos;
}

function sortArchives(listFiles: Array<Record<string, any>>) {
  let orderList: Array<Record<string, any>> = [];

  const orderDate = sortTitle(listFiles);

  orderList = orderDate.sort((a: Record<string, any>, b: Record<string, any>) => {
    const orderItemA = findMaterialCategory(a.IDMaterialCategory)?.priority || 0;
    const orderItemB = findMaterialCategory(b.IDMaterialCategory)?.priority || 0;

    if (orderItemA < orderItemB) return -1;
    if (orderItemA > orderItemB) return 1;
    return 0;
  });

  return orderList;
}

function sortExercises(listFiles: Array<Record<string, any>>) {
  let orderList: Array<Record<string, any>> = [];
  const orderDate = sortTitle(listFiles);

  orderList = orderDate.sort((a: Record<string, any>, b: Record<string, any>) => {
    const orderItemA = findMaterialCategory(a.IDMaterialCategory)?.priority || 0;
    const orderItemB = findMaterialCategory(b.IDMaterialCategory)?.priority || 0;

    if (orderItemA < orderItemB) return -1;
    if (orderItemA > orderItemB) return 1;
    return 0;
  });

  return orderList;
}

function sortContentBook(book: Record<string, any>) {
  const clearBook = book;

  if (!clearBook.topics || clearBook.topics.length <= 0) {
    if (clearBook.archives && clearBook.archives.length > 0) {
      clearBook.archives = sortArchives(clearBook.archives);
    }

    if (clearBook.exercices && clearBook.exercices.length > 0) {
      clearBook.exercices = sortExercises(clearBook.exercices);
    }

    if (clearBook.videos && clearBook.videos.length > 0) {
      const clearVideos = clearBook.videos.filter((video: Record<string, any>) => video?.detail);
      clearBook.videos = sortVideos(clearVideos);
    }

  } else {
    clearBook.topics.forEach((topic: Record<string, any>) => {
      sortContentBook(topic);
      return clearBook;
    });
  }

  return clearBook;
}

async function getMaterialCategories() {
  materialCategoryList = await materialCategoryService.getMaterialCategory();
}

export {
  getMaterialCategories,
  sortContentBook,
};
