const RouterHistory = {
  namespaced: true,
  state: {
    routesHistory: {},
  },
  mutations: {
    getRoutesFromTo(state: any, payload: any) {
      state.routesHistory = payload;
    },
  },
  getters: {
    routesFromTo: (state: any) => state.routesHistory,
  },
};

export default RouterHistory;
