








import { Component, Vue, Prop } from 'vue-property-decorator';

const TEXT = 'Não foi possível carregar o conteúdo.';

@Component({})
export default class FeedbackUser extends Vue {
  @Prop({ default: TEXT }) text!: string
}

