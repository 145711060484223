import AppClient from '../AppClient';

import { PaymentPost, GetPayment } from './paymentInterface';

export default class PaymentService {
  private basePath = process.env.VUE_APP_PAYMENT_API;
  private isSandbox = process.env.VUE_APP_SANDBOX_PAYMENT_API;

  private Client = new AppClient({ url: this.basePath });

  async getDescriptions(planId: number) {
    const URI = `/plans/${planId}/description`;

    const response = await this.Client.get(URI);

    return response?.data;
  }

  async getPayment(data: GetPayment) {
    const URI = '/payments';

    const baseConfig = this.Client.getBaseConfig();

    const hasNotToken = !baseConfig.headers?.Authorization || baseConfig.headers?.Authorization === 'Bearer null';

    if (hasNotToken) {
      return null;
    }

    const response = await this.Client.get(URI, {
      isSandbox: this.isSandbox,
      statusExternal: data?.statusExternal,
      paymentID: data?.paymentID,
    });

    return response?.data;
  }

  async postPaymentPlan(data: PaymentPost) {
    const URI = '/payment';

    const response = await this.Client.post(URI, {
      isSandbox: this.isSandbox,
      ...data,
    });

    return response?.data;
  }

  async getMarketing(planId: number) {
    const URI = '/plans/marketing';

    const response = await this.Client.get(URI, { planId });

    return response?.data;
  }

  async canceledPayment(paymentID: number) {
    const URI = '/payment';

    const response = await this.Client.delete(URI, {
      paymentID,
      isSandbox: this.isSandbox,
    });

    return response?.data;
  }

  async getDiscountByCode(code: string) {
    const URI = `/discounts/${code}`;

    const response = await this.Client.get(URI, {
      isSandbox: this.isSandbox,
    });

    return response?.data;
  }
}
