





















































import { Component, Vue, Prop } from 'vue-property-decorator';

const DEFAULT_FILL = 'var(--neutralcolor-high-pure)';

@Component
export default class Cart extends Vue {
  @Prop({ default: DEFAULT_FILL }) color!: string;
}
