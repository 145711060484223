import {
  isWithinInterval,
} from 'date-fns';

import store from '@/store';

interface Permission {
  ID: number;
  name: string;
  end_date: Date | string;
  start_date: Date | string;
}

function foundPermission(permissionName: string) {
  const permissions = store.getters.profile?.permissions;

  return permissions.find((permission: Permission) => permission.name.trim() === permissionName);
}

function validatePermissionDate(permission: Permission) {
  return isWithinInterval(new Date(), {
    start: new Date(permission.start_date),
    end: new Date(permission.end_date),
  });
}

export {
  foundPermission,
  validatePermissionDate,
};
