import { RouteConfig } from 'vue-router';

import DashboardPage from '@/pages/Dashboard/Dashboard.vue';

export const NAME_ROUTES_DASHBOARD = {
  DASHBOARD: 'Dashboard',
};

export const Dashboard: Array<RouteConfig> = [
  {
    path: '/dashboard',
    name: NAME_ROUTES_DASHBOARD.DASHBOARD,
    component: DashboardPage,
    meta: { title: 'Dashboard' },
  },
];
