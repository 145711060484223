
























import { Component, Prop, Vue } from 'vue-property-decorator';

const DEFAULT_SIZE = '0.875rem';
const DEFAULT_FILL = 'var(--neutralcolor-low-pure)';
const DISABLED_FILL = 'var(--neutralcolor-low-light)';

@Component
export default class Arrow extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;
  @Prop() valueRotate!: string;
  @Prop({ default: DEFAULT_SIZE }) width!: string;
  @Prop({ default: DEFAULT_SIZE }) height!: string;
  @Prop({ default: false }) isDisabled!: boolean;

  private DISABLED_FILL = DISABLED_FILL;

  get rotateArrow() {
    return this.valueRotate ? `rotate(${this.valueRotate}deg)` : 'none';
  }
}
