export default class ActiveModal {
  activeModal(id: string) {
    const a = document.createElement('a');
    a.setAttribute('data-bs-toggle', 'modal');
    a.setAttribute('data-bs-target', `#${id}`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  disableModal(id: string) {
    const modalIdButtonClose: any = document.querySelector(`#${id} .btn-close`);
    const modal: any = document.querySelector(`#${id}`);

    if (modalIdButtonClose) {
      modal.click();
      return;
    }

    if (modal) modal.style.display = 'none';
  }
}
