







































import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

@Component
export default class Banner extends Vue {
  @Prop() text!: string;
  @Prop({ default: true }) showImage!: boolean;

  handleRedirectPlan() {
    this.$router.push({
      name: 'Store',
    });
  }

}
