import AppClient from '../AppClient';

import { Ticket } from '@/globalInterfaces/Ticket';

export default class ProblemService {
  private basePath = process.env.VUE_APP_SUPPORT_API;

  private Client = new AppClient({ url: this.basePath });

  async sendTicket(ticket: Ticket) {
    const URI = '/tickets';

    const response = await this.Client.post(URI, ticket);

    return response;
  }

  async getFeatures() {
    const URI = '/tickets/features';

    const response = await this.Client.get(URI);

    return response?.data;
  }
}
