function sortTitle(listFiles: Array<Record<string, any>>) {
  const sortFiles = listFiles.sort((a: Record<string, any>, b: Record<string, any>) => {
    if (a.topicTitle < b.topicTitle) return -1;
    if (a.topicTitle > b.topicTitle) return 1;
    return 0;
  });

  return sortFiles;
}

export function dataProcessingCourseTopic(weekTopic: Record<string, any>) {
  const newTopic = weekTopic;

  if (newTopic?.disciplineID) {
    const idTopic = newTopic.disciplineID;
    newTopic.idTopic = idTopic;

    delete newTopic.topicId;
  }

  if (newTopic?.topicID) {
    const idTopic = newTopic.topicID;
    newTopic.idTopic = idTopic;

    delete newTopic.topicID;
  }

  if (newTopic?.topicTitle) {
    const title = newTopic.topicTitle;
    newTopic.title = title;

    delete newTopic.topicTitle;
  }

  if (!newTopic?.data) {
    newTopic.videos = [];
    newTopic.exercices = [];
    newTopic.archives = [];

    delete newTopic.data;
  }

  if (newTopic?.data) {
    const videos = newTopic.data.filter((subject: Record<string, any>) => subject.type === 'videos');
    const exercices = newTopic.data.filter((subject: Record<string, any>) => subject.type === 'exercices');
    const archives = newTopic.data.filter((subject: Record<string, any>) => subject.type === 'archives');

    newTopic.videos = videos;
    newTopic.exercices = exercices;
    newTopic.archives = archives;

    delete newTopic.data;
  }

  if (!newTopic?.topics) {
    newTopic.topics = null;
  }

  if (newTopic?.topics && newTopic?.topics.length > 0) {
    newTopic.topics = sortTitle(newTopic.topics);
    newTopic.topics.forEach((topicItem: Record<string, any>) => {
      dataProcessingCourseTopic(topicItem);
    });
  }

  return newTopic;
}

export function dataProcessingCourse(weekTopic: Record<string, any>) {
  const newTopic = weekTopic;

  if (newTopic?.weekId) {
    const id = newTopic.weekId;
    const topics = newTopic.disciplines;

    newTopic.id = id;
    newTopic.topics = topics;

    delete newTopic.weekId;
    delete newTopic.disciplines;
  }

  if (newTopic?.topics && newTopic?.topics.length) {
    newTopic.topics.forEach((discipline: Record<string, any>, indexDiscipline: number) => {
      newTopic.topics[indexDiscipline] = dataProcessingCourseTopic(newTopic.topics[indexDiscipline]);
    });
  }

  return newTopic;
}
