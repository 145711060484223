















import {
  Component,
  Vue,
  Watch,
} from 'vue-property-decorator';

@Component
export default class BreadCrumbs extends Vue {
  private list: Array<object> = [];

  mounted() {
    this.setList();
  }

  beforeDestroy() {
    this.$store.commit('setClickedBreadcrumb', false);
  }

  @Watch('$breadcrumb.listPaths')
  setList() {
    this.list = this.$breadcrumb.listPaths;
  }

  goTo(index: string) {
    if (index && index !== '') this.$router.push(index);
    this.$store.commit('setClickedBreadcrumb', true);
  }
}
