const DIVISION_LIMIT = 7;
const TEXT_PREVIOUS_SIMULATED = 'VESTIBULARES ANTERIORES';

export default class ActionDataSimulated {
  cutCoverImage(url: string) {
    const urlSplit = url.split('/', DIVISION_LIMIT);

    const newUrl = urlSplit.map((value, index) => {
      if (index + 1 === urlSplit.length && value.indexOf('png') === -1) return `${value}.png`;
      return value.indexOf('png') === -1 ? `${value}/` : value;
    });

    return newUrl.join('');
  }

  formattedNameSimulatedForRoute(url: string, titleResponse: string) {
    if (titleResponse.toUpperCase().indexOf('DIA') !== -1) {
      const hasTextExam = url.indexOf('vestibulares');
      const hasTextPrevious = url.indexOf('anteriores');

      if (hasTextExam !== -1 && hasTextPrevious !== -1) return TEXT_PREVIOUS_SIMULATED;
      return '';
    }
    return titleResponse;
  }
}
